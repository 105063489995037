import React from "react";
import { Tabs, Grid, Row, Select } from "antd";
import Loadable from "react-loadable";

import { Loader, postItemConstants } from "../../common";
import { useState } from "react";
const { TabPane } = Tabs;

const MyConsumedOrders = Loadable({
  loader: () => import("./MyConsumedOrders"),
  render(loaded, props) {
    let MyConsumedOrders = loaded.MyConsumedOrders;
    return <MyConsumedOrders {...props} />;
  },
  loading() {
    return <Loader spinning />;
  },
});

const MyPublishedOrders = Loadable({
  loader: () => import("./MyPublishedOrders"),
  render(loaded, props) {
    let MyPublishedOrders = loaded.MyPublishedOrders;
    return <MyPublishedOrders {...props} />;
  },
  loading() {
    return <Loader spinning />;
  },
});

const { useBreakpoint } = Grid;
const { Option } = Select;

export function Orders() {
  const screens = useBreakpoint();
  const [ordersItemType, setOrdersItemType] = useState(postItemConstants.ALL);
  return (
    <div
      className="orders-page"
      style={{
        margin: screens.xs ? "0" : null,
        width: screens.xs ? "100%" : null,
      }}
    >
     <Row justify={screens.xs ? 'start'  : 'end'} style={{ margin: 10 }}>
       <div className="orders-item-type-text">Select type of items</div>
        <Select
          defaultValue={ordersItemType}
          placeholder="Select Order Item Type"
          value={ordersItemType}
          style={screens.xs ? { width: "100%", marginTop: 10 } : { width: 150 }}
          dropdownClassName="order-item-type-dropdown"
          onChange={(value) => {
            setOrdersItemType(value);
          }}
          size="large"
          // size={screens.xs ? "large" : "small"}
        >
          <Option value={postItemConstants.RENT}>{postItemConstants.RENT}</Option>
          <Option value={postItemConstants.SELL}>{postItemConstants.SELL}</Option>
          <Option value={postItemConstants.ALL}>{postItemConstants.ALL}</Option>
        </Select>
      </Row>
      <Tabs
        size="large"
        tabBarStyle={{
          margin: "0% 1% 1% 5%",
        }}
        centered
        destroyInactiveTabPane
      >
        <TabPane tab={<strong>Published Orders</strong>} key="published_orders">
          <MyPublishedOrders type={ordersItemType} />
        </TabPane>

        <TabPane tab={<strong>Consumed Orders</strong>} key="consumed_orders">
          <MyConsumedOrders type={ordersItemType} />
        </TabPane>
      </Tabs>
    </div>
  );
}
