import React, { Fragment } from "react";
import { Button, Divider, Drawer, Space } from "antd";
import { Grid } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import LocationDropdown from "./LocationDropdown";
import { useState } from "react";
import { useSelector } from "react-redux";
import LocationPickerNew from "./LocationPickerNew";
const { useBreakpoint } = Grid;

const LocationContainer = () => {
  const screens = useBreakpoint();
  const defaultCity = useSelector((state) =>
    window.getValue(state, "recentpublisheditems.latlng.city")
  );
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const isLongTag = defaultCity.length > 16;
  return (
    <Fragment>
      <Space direction="horizontal" size="middle">
        {/* <Tooltip title={defaultCity && !screens.xs ? `${defaultCity} is enabled` : ''} color={"blue"}> */}
        <Button
          type="text"
          size="medium"
          style={
            screens.xs
              ? {
                  width: 165,
                  overflow: "hidden",
                  color: "black",
                }
              : { width: 200 }
          }
          icon={<EnvironmentOutlined style={{ fontSize: 20 }} />}
          onClick={() => {
            showDrawer();
          }}
        >
          {defaultCity
            ? isLongTag
              ? `${defaultCity.slice(0, 12)}...`
              : defaultCity
            : "Search location"}
        </Button>
        {/* </Tooltip> */}
      </Space>
      <Drawer
        title="Location Settings"
        width={"100%"}
        // style={{ width: '100%' }}
        onClose={onClose}
        visible={visible}
        forceRender={true}
      >
        <Space direction="vertical" size="small">
          <Space direction="horizontal">
            <EnvironmentOutlined style={{ color: "#1f9d1f", fontSize: 25 }} />
            <LocationDropdown onClose={onClose} />
          </Space>
          {screens.xs ? <Divider /> : <Fragment />}
          {screens.xs ? <LocationPickerNew /> : <Fragment />}
          {screens.xs ? <Divider /> : <Fragment />}
        </Space>
      </Drawer>
      {/* </Modal> */}
    </Fragment>
  );
};
export default LocationContainer;
