import { store } from "../../../store";
import { globalActions } from "../../landing/actions";
import { submitContactActions } from "./actions";

export function submitContactInfo(contactInfo) {
  try {
    store.dispatch({
      type: submitContactActions.SUBMIT_CONTACT_REQUEST,
    });
    return fetch(`${window["apiURL"]}/contact/add`, {
      method: "POST",
      body: JSON.stringify(contactInfo),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response)
      .catch((error) => {
        store.dispatch({
          type: submitContactActions.SUBMIT_CONTACT_ERROR,
          payload: error.toString(),
        });
        store.dispatch({
          type: globalActions.SET_ERROR,
          payload: error.toString(),
        });
      });
  } catch (error) {
    store.dispatch({
      type: globalActions.SET_ERROR,
      payload: error.toString(),
    });
  }
}
