import React from "react";
import { Row, Col, Form, Input, Button, Alert, Grid } from "antd";
import { useSelector } from "react-redux";
import { verifyKYC, fetchVerifyKYC } from "../service";
import { Loader } from "../../common";
import { useEffect } from "react";
const validator = require("aadhaar-validator");

const { useBreakpoint } = Grid;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 12,
  },
};

export function VerifyAadhaarCard() {
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const loading = useSelector(
    (state) =>
      window.getValue(state, "kyc.loading") ||
      window.getValue(state, "fetchKyc.loading")
  );
  const response = useSelector((state) => window.getValue(state, "kyc.data"));
  const kycVerified = useSelector((state) =>
    window.getValue(state, "fetchKyc.addharId")
  );
  const onFinish = (values) => {
    //console.log("Received values of form: ", values.aadhar_card_no);
    verifyKYC(values.aadhar_card_no);
  };

  useEffect(() => {
    fetchVerifyKYC();
  }, []);
  return (
    <Loader spinning={loading} tip="Fetching KYC Details...">
      <div style={{ marginBottom: "5%" }}>
        <Row gutter={[16, 16]}>
          <Col push={1} span={22}>
            <Alert
              message="Note: Verify your aadhar card can give more trust on your profile."
              type="info"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={{ push: 0 }} lg={{ span: 8, push: 1 }}>
            <img
              className="lazyload"
              data-src={require("../../../assets/images/a.png")}
              style={
                screens.xs
                  ? {
                      borderRadius: 20,
                      width: "100%",
                      padding: 10,
                      objectFit: "contain",
                    }
                  : { borderRadius: 20, objectFit: "contain" }
              }
              alt="aadhar card"
            />
          </Col>
          {(response && window.getValue(response, "data.success")) ||
          kycVerified ? (
            <Col
              xs={{ span: 22, push: 2 }}
              lg={{ span: 12, push: 2 }}
              style={
                screens.xs
                  ? { marginTop: 10, marginBottom: 10 }
                  : { marginTop: 70 }
              }
            >
              <h3 style={{ fontWeight: 550 }}>
                Your KYC has been Verified !{" "}
                {
                  <div style={{ marginTop: 10 }}>
                    Aadhar Card :{" "}
                    <h2 style={{ color: "#33691e", fontWeight: 400 }}>
                      XXXX-XXXX-XXXX-{kycVerified.slice(-4)}
                    </h2>
                  </div>
                }{" "}
              </h3>
            </Col>
          ) : (
            <Col
              xs={{ span: 20, push: 2 }}
              lg={{ span: 15, push: 2 }}
              style={screens.xs ? { marginTop: 0 } : { marginTop: 50 }}
            >
              <Form
                {...formItemLayout}
                form={form}
                name="aadhar_card"
                onFinish={onFinish}
                scrollToFirstError
              >
                <Form.Item
                  name="aadhar_card_no"
                  label="Enter Aadhaar Card No"
                  rules={[
                    {
                      required: true,
                      message: "Please input aadhar card no.",
                    },
                    {
                      len: 12,
                      message: "The number of characters in Aadhaar card is 12",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value) {
                          return Promise.reject(
                            "Wrong Aadhar card, please enter valid aadhar card only."
                          );
                        }
                        const valid = validator.isValidNumber(
                          getFieldValue("aadhar_card_no")
                        );
                        if (!value || valid) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "Wrong Aadhar card, please enter valid aadhar card only."
                        );
                      },
                    }),
                  ]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...{
                    wrapperCol: screens.xs
                      ? {
                          push: 9,
                        }
                      : {
                          push: 4,
                        },
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={screens.xs ? { marginTop: 0 } : { marginTop: 15 }}
                  >
                    Verify
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          )}
        </Row>
      </div>
    </Loader>
  );
}
