import { store } from "../../store";
import { bookActions, commentActions } from "./actions";
import { globalActions } from "../landing";
import { message } from "antd";

function extractAddress(source) {
  source["title"] = source.type || "Other";
  return source;
  // return {
  //   id: update ? source.id : null,
  //   title: source.type || "Other",
  //   map_location: source.location,
  //   // flat: source.formattedAddress,
  //   // building_name: source.formattedAddress,
  //   // street: source.street || source.area || source.formattedAddress,
  //   // area: source.area || source.formattedAddress,
  //   // city: source.city,
  //   // state: source.state,
  //   // country: source.country,
  //   // pincode: source.postalCode,
  //   formatted_address: source.formattedAddress,
  // };
}

export function saveAddress(address, update) {
  const url = update
    ? `${window["apiURL"]}/address/update`
    : `${window["apiURL"]}/address/create`;
  return fetch(url, {
    method: update ? "PUT" : "POST",
    body: JSON.stringify(extractAddress(address, update)),
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function postItemBody(form1, form2, address, id, type) {
  try {
    const age_of_item =
      parseInt(form2.years.replace(/[^0-9]/g, "")) === 0
        ? 1 * parseInt(form2.months.replace(/[^0-9]/g, ""))
        : parseInt(form2.years.replace(/[^0-9]/g, "")) *
          parseInt(form2.months.replace(/[^0-9]/g, ""));
    let body = {
      type,
      name: form1.product_name,
      description: form1.description,
      category: {
        id: "C001",
        name: form1.category,
      },
      sub_category: {
        id: "SC001",
        name: form1["sub-category"],
      },
      age_of_item,
      tags: form1.tags,
      service_to: form2.use_by,
      pickAddress: address.pickUpAddressId,
      mobile_no: address.mobile_no || "",
      images: form1.fileList.map(
        (file) => window.getValue(file, "response.data.url") || file.url
      ),
    };
    if(type === 'Rent') {
      body = {
        ...body,
        market_price: form2.market_price,
        deposit: form2.deposit,
        rent: {
          day: form2.rent_per_day,
          week: form2.rent_per_week,
          month: form2.rent_per_month,
        },
        dropAddress: address.dropAddressId,
      }
    } else {
      body = {
        ...body,
        selling_price: form2.selling_price
      }
    }
    if (id) {
      body["id"] = id;
    }
    return JSON.stringify(body);
  } catch (error) {
    store.dispatch({
      type: globalActions.SET_ERROR,
      payload: error.toString(),
    });
  }
}

export async function postItem(form1, form2, form3, id, type) {
  try {
    let pickUpAddressId = null,
      dropAddressId = null;

    if (
      form3.pick_up_address.resultAddresstoSave &&
      form3.pick_up_address.resultAddresstoSave.address_id
    ) {
      pickUpAddressId = form3.pick_up_address.resultAddresstoSave;
    } else {
      await saveAddress(form3.pick_up_address.resultAddresstoSave);
      pickUpAddressId = form3.pick_up_address.resultAddresstoSave;
    }
    if(type === 'Rent') {
      if (
        form3.drop_address.resultAddresstoSave &&
        form3.drop_address.resultAddresstoSave.address_id
      ) {
        dropAddressId = form3.drop_address.resultAddresstoSave;
      } else {
        if (form3.drop_address.sameAsPickUp) {
          dropAddressId = pickUpAddressId;
        } else {
          await saveAddress(form3.drop_address.resultAddresstoSave);
          dropAddressId = form3.drop_address.resultAddresstoSave;
        }
      }
    }

    let url = id
      ? `${window["apiURL"]}/item/update`
      : `${window["apiURL"]}/item/create`;

    return fetch(url, {
      method: id ? "PUT" : "POST",
      body: postItemBody(
        form1,
        form2,
        { pickUpAddressId, dropAddressId, mobile_no: form3.mobile_no },
        id,
        type
      ),
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    store.dispatch({
      type: globalActions.SET_ERROR,
      payload: error.toString(),
    });
  }
}

export function bookItem(requestPayload) {
  try {
    store.dispatch({
      type: bookActions.BOOK_ITEM_REQUEST,
    });
    return fetch(`${window["apiURL"]}/order/book`, {
      method: "POST",
      body: JSON.stringify(requestPayload),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response && window.getValue(response, "success")) {
          store.dispatch({
            type: bookActions.BOOK_ITEM_SUCCESS,
            payload: window.getValue(response, "data"),
          });
          message.success('Booking request sent!!!');
          return {
            id: window.getValue(response, "data.order.order_sequence_no"),
          };
        } else {
          store.dispatch({
            type: bookActions.BOOK_ITEM_ERROR,
            payload: window.getValue(response, "data.message"),
          });
          store.dispatch({
            type: globalActions.SET_ERROR,
            payload: window.getValue(response, "data.message"),
          });
        }
      })
      .catch((error) => {
        store.dispatch({
          type: bookActions.BOOK_ITEM_ERROR,
          payload: error.toString(),
        });
        store.dispatch({
          type: globalActions.SET_ERROR,
          payload: error.toString(),
        });
      });
  } catch (error) {
    store.dispatch({
      type: globalActions.SET_ERROR,
      payload: error.toString(),
    });
  }
}

export const fetchPostedComments = (pointerToLastComment) => {
  try {
    let url = "";
    if (!pointerToLastComment.loadMore) {
      store.dispatch({
        type: commentActions.LOADING,
        payload: true,
      });
      url = `${window["apiURL"]}/item/comment/${pointerToLastComment.itemId}`;
    } else {
      store.dispatch({
        type: commentActions.LOAD_MORE_COMMENTS_LOADING,
        payload: true,
      });
      url = `${window["apiURL"]}/item/comment/${pointerToLastComment.itemId}?nextToken=${pointerToLastComment.lastCommentId}`;
    }

    fetch(url)
      .then((response) => {
        if (response && window.getValue(response, "success")) {
          store.dispatch({
            type: pointerToLastComment.loadMore
              ? commentActions.LOAD_MORE_ITEMS
              : commentActions.COMMENT_SUCCESS,
            payload: window.getValue(response, "data.comments"),
          });
        } else {
          store.dispatch({
            type: commentActions.LOAD_MORE_COMMENTS_LOADING,
            payload: false,
          });
          store.dispatch({
            type: globalActions.SET_ERROR,
            payload: "Failed to load comments",
          });
        }
      })
      .catch((error) => {
        if (pointerToLastComment.loadMore) {
          store.dispatch({
            type: commentActions.LOAD_MORE_ITEMS_LOADING,
            payload: false,
          });
        } else {
          store.dispatch({
            type: commentActions.LOADING,
            payload: false,
          });
        }
        store.dispatch({
          type: globalActions.SET_ERROR,
          payload: error.toString(),
        });
      });
  } catch (error) {
    store.dispatch({
      type: globalActions.SET_ERROR,
      payload: error.toString(),
    });
  }
};

export function postComment(commentPayload) {
  store.dispatch({
    type: commentActions.ADD_COMMENT_REQUEST,
  });
  return fetch(`${window["apiURL"]}/item/comment/add`, {
    method: "POST",
    body: JSON.stringify(commentPayload),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response && window.getValue(response, "success")) {
        store.dispatch({
          type: commentActions.ADD_COMMENT_SUCCESS,
          payload: window.getValue(response, "data"),
        });
        store.dispatch({
          type: commentActions.ADD_SINGLE_COMMENT_SUCCESS,
          payload: window.getValue(response, "data.comment"),
        });
        return window.getValue(response, "data.comment");
      } else {
        store.dispatch({
          type: commentActions.ADD_COMMENT_ERROR,
          payload: window.getValue(response, "data.message"),
        });
        store.dispatch({
          type: globalActions.SET_ERROR,
          payload: window.getValue(response, "data.message"),
        });
        return false;
      }
    })
    .catch((error) => {
      store.dispatch({
        type: commentActions.ADD_COMMENT_ERROR,
        payload: error.toString(),
      });
      store.dispatch({
        type: globalActions.SET_ERROR,
        payload: error.toString(),
      });
      return false;
    });
}