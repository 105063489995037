import React, { useState } from "react";
import { Upload, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import imageCompression from "browser-image-compression";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const uploadButton = (
  <React.Fragment>
    <PlusOutlined style={{ color: "blue" }} />
    <div className="ant-upload-text">Upload</div>
  </React.Fragment>
);
function UploadFiles(props) {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState(props.fileList || []);

  const handleCancel = () => setPreviewVisible(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };
  const handleChange = ({ fileList }) => {
    //console.log('fileList', fileList);
    setFileList(fileList);
    if (props.onChange) {
      props.onChange(fileList);
    }
  };

  return (
    <React.Fragment>
      <Upload
        action={`${window["apiURL"]}/item/image/upload`}
        headers={{
          Authorization: "Bearer " + Cookies.get("__r__c__t"),
        }}
        accept=".jpg,.png,.jpeg"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        multiple
        showUploadList={props.showProps}
        disabled={props.isDisabled}
        transformFile={(file) => {
          return new Promise((resolve, rejected) => {
            try {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                const img = document.createElement("img");
                img.src = reader.result;
                const canvas = document.createElement("canvas");
                img.onload = async () => {
                  canvas.width = img.width;
                  canvas.height = img.height;
                  const ctx = canvas.getContext("2d");
                  ctx.drawImage(img, 0, 0);
                  ctx.fillStyle = "#FFF864"; //"#403C9C"; //"#2E7D59";
                  ctx.textBaseline = "middle";
                  ctx.font = "bold 25px Arial";
                  ctx.fillText("RC", img.width - 50, img.height - 30);
                  const canvasFile = await imageCompression.canvasToFile(
                    canvas,
                    file.type,
                    file.name,
                    file.lastModified
                  );
                  const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true,
                  };
                  try {
                    const compressedFile = await imageCompression(
                      canvasFile,
                      options
                    );
                    resolve(compressedFile);
                  } catch (error) {
                    console.error(error);
                    rejected();
                  }
                  // return file;
                };
              };
            } catch (e) {
              rejected();
            }
          });
        }}
      >
        {fileList.length >= 5 || props.hideUploadBtn ? null : uploadButton}
      </Upload>
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </React.Fragment>
  );
}
export default UploadFiles;
