import { store } from "../../store";
import { history } from "../../utility";
import { recentlyPublishedItemsActions, globalActions } from "./";
export const fetchRecentItems = (pointerToLastItem) => {
  let url = "";
  if (!pointerToLastItem) {
    store.dispatch({
      type: recentlyPublishedItemsActions.LOADING,
      payload: true,
    });
    url = `${window["apiURL"]}/item/recent`;
  } else {
    store.dispatch({
      type: recentlyPublishedItemsActions.LOAD_MORE_ITEMS_LOADING,
      payload: true,
    });
    url = `${window["apiURL"]}/item/recent?nextToken=${pointerToLastItem.id}`;
  }

  fetch(url)
    .then((response) => {
      if (response && window.getValue(response, "success")) {
        store.dispatch({
          type: pointerToLastItem
            ? recentlyPublishedItemsActions.LOAD_MORE_ITEMS
            : recentlyPublishedItemsActions.SUCCESS,
          payload: window.getValue(response, "data.items"),
        });
      }
    })
    .catch((error) => {
      if (pointerToLastItem) {
        store.dispatch({
          type: recentlyPublishedItemsActions.LOAD_MORE_ITEMS_LOADING,
          payload: false,
        });
      } else {
        store.dispatch({
          type: recentlyPublishedItemsActions.LOADING,
          payload: false,
        });
      }
      store.dispatch({
        type: globalActions.SET_ERROR,
        payload: error.toString(),
      });
    });
};

export const fetchSingleItem = (itemId) => {
  store.dispatch({
    type: recentlyPublishedItemsActions.SINGLE_ITEM_LOADING,
    payload: true,
  });

  fetch(`${window["apiURL"]}/item/${itemId}`)
    .then((response) => {
      if (response && window.getValue(response, "success")) {
        store.dispatch({
          type: recentlyPublishedItemsActions.SINGLE_ITEM_SUCCESS,
          payload: window.getValue(response, "data.item"),
        });
      } else {
        history.push("/");
      }
    })
    .catch((error) => {
      store.dispatch({
        type: recentlyPublishedItemsActions.SINGLE_ITEM_LOADING,
        payload: false,
      });
      store.dispatch({
        type: globalActions.SET_ERROR,
        payload: error.toString(),
      });
      history.push("/");
    });
};

export const bookmarkItem = (itemId, uuid) => {
  return fetch(`${window["apiURL"]}/item/bookmark/add`, {
    method: "POST",
    body: JSON.stringify({ item_id: itemId }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response && window.getValue(response, "success")) {
        store.dispatch({
          type:
            recentlyPublishedItemsActions.UPDATE_INDIVIDUAL_ITEM_FOR_BOOKMARK,
          payload: { itemId, uuid, type: "bookmark" },
        });
      }
    })
    .catch((e) => {
      store.dispatch({
        type: globalActions.SET_ERROR,
        payload: "Unable to bookmark current item",
      });
    });
};

export const unBookmarkItem = (itemId, uuid) => {
  return fetch(`${window["apiURL"]}/item/bookmark/${itemId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response && response.success) {
        store.dispatch({
          type:
            recentlyPublishedItemsActions.UPDATE_INDIVIDUAL_ITEM_FOR_BOOKMARK,
          payload: { itemId, uuid, type: "unBookmark" },
        });
      } else {
        store.dispatch({
          type: globalActions.SET_ERROR,
          payload: "Unable to UnBookmark current item",
        });
      }
    })
    .catch((e) => {
      store.dispatch({
        type: globalActions.SET_ERROR,
        payload: "Unable to UnBookmark current item" + e.toString(),
      });
    });
};
