import React from "react";
import { message } from "antd";
import { Modal } from "antd";
import { Form, Input, Button } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import firebase from "../../../firebase";

import { useState } from "react";
import { sendFeedback } from "./service";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const validateMessages = {
  required: "Name is required!",
  types: {
    email: "Please add a valid email!",
  },
};

export function Feedback() {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const onFinish = (values) => {
    setConfirmLoading(true);
    sendFeedback(values.user).finally(() => {
      firebase.analytics().logEvent("feedback_sent", {
        user: values.user,
      });
      setConfirmLoading(false);
      setVisible(false);
      message.success("Feedback sent successfully!!!");
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  return (
    <React.Fragment>
      <Button onClick={showModal}>Feedback</Button>
      <Modal
        title="Feedback"
        visible={visible}
        footer={null}
        style={{ width: "auto", height: "auto" }}
        maskClosable={false}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <Form
          {...layout}
          name="nest-messages"
          size="large"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <Form.Item
            name={["user", "name"]}
            rules={[
              { required: true },
              {
                min: 3,
                message: "Please enter minimum 3 characters",
              },
            ]}
          >
            <Input allowClear placeholder="Name" />
          </Form.Item>
          <Form.Item
            name={["user", "email"]}
            rules={[
              { type: "email" },
              { required: true, message: "Please enter your email." },
            ]}
          >
            <Input allowClear placeholder="Email" />
          </Form.Item>
          <Form.Item
            name={["user", "description"]}
            rules={[
              { required: true, message: "Please enter your feedback." },
              { type: "string" },
              {
                min: 10,
                message: "Please enter feedback of minimum 10 characters",
              },
            ]}
          >
            <Input.TextArea
              allowClear
              placeholder="Write your Feedback here"
              autoSize={{ minRows: 4 }}
            />
          </Form.Item>
          <Form.Item
            required
            {...{
              wrapperCol: {
                span: 24
              },
              labelCol: {
                span: 24
              }
            }}
            hasFeedback
            name="captcha"
            rules={[
              {
                required: true,
                message: "Please verify Captcha",
              },
            ]}
          >
            <ReCAPTCHA sitekey="6LdEV-wUAAAAAMmMrziXprUarEsoSor9YORKfAZW" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: 15 }}
              loading={confirmLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
}
