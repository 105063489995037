import { profileActions } from "../actions";

export const kyc = (state = {loading: false}, action) => {
  switch (action.type) {
    case profileActions.KYC_VERIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case profileActions.KYC_VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case profileActions.KYC_VERIFIED_AADHAR_ID:
      return {
        ...state,
        loading: false,
        addharId: action.payload,
      }
    case profileActions.KYC_VERIFY_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const fetchKyc = (state = {loading: false}, action) => {
  switch (action.type) {
    case profileActions.FETCH_KYC_VERIFY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case profileActions.FETCH_KYC_VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        addharId: action.payload
      };
    case profileActions.FETCH_KYC_VERIFY_FAILURE:
      return {
        ...state,
        loading: false,
        data: '',
      };
    default:
      return state;
  }
};
