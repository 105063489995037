import React from "react";
import { Input, AutoComplete, Space, message, Grid } from "antd";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useDispatch } from "react-redux";
import { EnvironmentOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useSelector } from "react-redux";
import { recentlyPublishedItemsActions } from "../actions";
import LocationPickerNew from "./LocationPickerNew";
import { useEffect } from "react";

const defaultLocation = {
  lat: 1,
  lng: 1,
  city: "Search location",
};
const { useBreakpoint } = Grid;
export default function LocationDropdown() {
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  let reduxLocationInfo = useSelector((state) =>
    window.getValue(state, "recentpublisheditems.latlng")
  );
  const [city, setCity] = useState(reduxLocationInfo.city || "");
  const [selectedLocationInfo, setSelectedLocationInfo] = useState(
    reduxLocationInfo
  );
  const setCurrentLocation = (locationInfo) => {
    setCity(locationInfo.city);
    dispatch({
      type: recentlyPublishedItemsActions.SET_SEARCH_LAT_LNG,
      payload: locationInfo,
    });
    localStorage.setItem("search-city", JSON.stringify(locationInfo));
  };
  const handleChange = (city) => {
    // clear city and lalang for empty value
    if (city.length === 0) {
      localStorage.setItem("search-city", JSON.stringify(defaultLocation));
      dispatch({
        type: recentlyPublishedItemsActions.SET_SEARCH_LAT_LNG,
        payload: defaultLocation,
      });
    }
    setCity(city);
  };
  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        // setCity(results[0].address_components[0].long_name);
        setCity(address);
        reduxLocationInfo = {
          city: address,
        };
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        localStorage.setItem(
          "search-city",
          JSON.stringify({
            lat: latLng.lat,
            lng: latLng.lng,
            city: reduxLocationInfo.city,
          })
        );
        dispatch({
          type: recentlyPublishedItemsActions.SET_SEARCH_LAT_LNG,
          payload: {
            lat: latLng.lat,
            lng: latLng.lng,
            city: reduxLocationInfo.city,
          },
        });
      })
      .catch((error) => console.error("Error", error));
  };

  useEffect(() => {
    setCity(reduxLocationInfo.city || "");
  }, [reduxLocationInfo]);

  const setPickerLocation = (pickerLocation) => {
    //console.log("setPickerLocation pickerLocation = ", pickerLocation);
    setSelectedLocationInfo(pickerLocation);
  };

  return (
    <Space direction="horizontal">
      <PlacesAutocomplete
        value={city && city === "Search location" ? "" : city}
        onChange={handleChange}
        onSelect={handleSelect}
        highlightFirstSuggestion={false}
        searchOptions={{
          componentRestrictions: {
            country: "IN",
          },
          types: ["(cities)"],
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          let options = [];
          options = loading
            ? [
                {
                  value: "",
                  label: <div style={{ padding: 10 }}>Loading...</div>,
                },
              ]
            : suggestions.map((suggestion) => {
                return {
                  value: suggestion.description,
                  label: (
                    <div
                      {...getSuggestionItemProps(suggestion)}
                      style={{ margin: 5, padding: 5 }}
                    >
                      <EnvironmentOutlined
                        style={{ marginLeft: 5, marginRight: 5, fontSize: 20 }}
                      />
                      <span>{suggestion.description}</span>
                    </div>
                  ),
                };
              });
          if (!screens.xs) {
            options.unshift({
              value: "Current location",
              label: (
                <div style={{ margin: 5, padding: 5 }}>
                  <LocationPickerNew setPickerLocation={setPickerLocation} />
                </div>
              ),
            });
          }
          return (
            <AutoComplete
              defaultValue={city && city === "Search location" ? "" : city}
              value={city && city === "Search location" ? "" : city}
              defaultActiveFirstOption={false}
              autoFocus={false}
              dropdownMatchSelectWidth={screens.xs ? 300 : 400}
              options={options}
              onSelect={(value) => {
                //console.log("selectde valeu = ", value);
                if (selectedLocationInfo.city === "Location not set") {
                  setCurrentLocation({
                    lat: 1,
                    lng: 1,
                    city: "",
                  });
                  message.info("First enable your location");
                  return;
                }
                // check if user's location is already set then on click reset it to default location
                if (reduxLocationInfo.city === "Current location") {
                  setCurrentLocation(defaultLocation);
                } else {
                  setCurrentLocation(selectedLocationInfo);
                }
              }}
            >
              <Input
                allowClear
                style={screens.xs ? { width: 300 } : { width: 200 }}
                size="large"
                {...getInputProps({
                  placeholder: "Search location",
                })}
              />
            </AutoComplete>
          );
        }}
      </PlacesAutocomplete>
    </Space>
  );
}
