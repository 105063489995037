import React from "react";
import { Rate, Spin } from "antd";

// import { getItemRatings } from "../service";
// import { useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";

export function RateDisplay(props) {
  const { loading, ratings } = useSelector(
    (state) => ({
      loading: window.getValue(state, "takenOnRentOrdersItem.ratingLoading"),
      ratings: window.getValue(state, "takenOnRentOrdersItem.ratings") || [],
    }),
    shallowEqual
  );
  // useEffect(() => {
  //   console.log("RateDisplay useEffect called");
  //   getItemRatings(window.getValue(props.row, "detailId"));
  // }, [props.row]);
  return (
    <div key={props.rateKey}>
      {loading ? (
        <Spin />
      ) : (
        <Rate
          key={props.rateKey}
          defaultValue={
            ratings && ratings.length > 0
              ? ratings[0].star
              : window.getValue(props.row, "ratings.star")
          }
          onChange={(value) => {
            // console.log('Rate display = ', value)
            props.updateStateForModal(value, props.row);
          }}
        />
      )}
    </div>
  );
}
