import React, { useState } from "react";
import { Table, Skeleton, Button } from "antd";
import firebase from "../../../firebase";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Loadable from "react-loadable";
import { myPublishedItems } from "../";
import { Loader } from "../../common";
import { useEffect } from "react";
import { DeletePublishedItem } from "./DeletePublishedItem";

const PostItem = Loadable({
  loader: () => import("../../publish/components/PostItem"),
  render(loaded, props) {
    let PostItem = loaded.PostItem;
    return <PostItem {...props} />;
  },
  loading() {
    return <Loader spinning />;
  },
});

const CustomSwitch = Loadable({
  loader: () => import("./CustomSwitch"),
  render(loaded, props) {
    let CustomSwitch = loaded.CustomSwitch;
    return <CustomSwitch {...props} />;
  },
  loading() {
    return <Loader spinning />;
  },
});

// start here
const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "id",
    align: "center",
  },
  {
    title: "Category",
    key: "id",
    align: "center",
    render: (row) => {
      return (
        <div>
          {window.getValue(row, "category.name")}-
          {window.getValue(row, "sub_category.name")}
        </div>
      );
    },
  },
  {
    title: "Publish",
    dataIndex: "status",
    key: "id",
    align: "center",
    render: (status, record) => (
      <CustomSwitch status={status} record={record} />
    ),
  },
  {
    title: "Delete",
    align: "center",
    render: (row) => {
      return <DeletePublishedItem row={row} />;
    },
  },
];

export function MyPublishedItems() {
  const dispatch = useDispatch();
  const { loading, items, loadingMore, anyMore } = useSelector(
    (state) => ({
      loading: window.getValue(state, "mypublisheditems.loading"),
      items: window.getValue(state, "mypublisheditems.items") || [],
      loadingMore: window.getValue(state, "mypublisheditems.loadingMore"),
      anyMore: window.getValue(state, "mypublisheditems.anyMore"),
    }),
    shallowEqual
  );
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    firebase.analytics().logEvent("my_published_clicked");
    myPublishedItems();
  }, []);

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 15, width: 50 }}>
      <Table
        title={() => "Published Items"}
        size="middle"
        bordered
        columns={columns}
        tableLayout={"auto"}
        expandable={{
          expandRowByClick: true,
          expandedRowKeys: expandedRowKeys,
          onExpand: (expanded, record) => {
            if (expanded) {
              const images = window.getValue(record, "images");
              const fileList = [];
              for (let i = 0; i < images.length; i++) {
                fileList.push({
                  uid: i,
                  name: "images" + i,
                  status: "done",
                  url: images[i],
                });
              }

              dispatch({
                type: "STEP_1_FORM_DATA",
                payload: {
                  product_name: window.getValue(record, "name"),
                  category: window.getValue(record, "category.name"),
                  "sub-category": window.getValue(record, "sub_category.name"),
                  description: window.getValue(record, "description"),
                  tags: window.getValue(record, "tags"),
                  fileList,
                },
              });
              dispatch({
                type: "STEP_2_FORM_DATA",
                payload:
                  record.type === "Sell"
                    ? {
                        selling_price: window.getValue(record, "selling_price"),
                        years:
                          parseInt(
                            window.getValue(record, "age_of_item") / 12
                          ) + " years",
                        months:
                          (window.getValue(record, "age_of_item") % 12) +
                          " months",
                        use_by: window.getValue(record, "service_to"),
                      }
                    : {
                        market_price: window.getValue(record, "market_price"),
                        deposit: window.getValue(record, "deposit"),
                        rent_per_day: window.getValue(record, "rent.day"),
                        rent_per_week: window.getValue(record, "rent.week"),
                        rent_per_month: window.getValue(record, "rent.month"),
                        years:
                          parseInt(
                            window.getValue(record, "age_of_item") / 12
                          ) + " years",
                        months:
                          (window.getValue(record, "age_of_item") % 12) +
                          " months",
                        use_by: window.getValue(record, "service_to"),
                      },
              });
              dispatch({
                type: "STEP_3_FORM_DATA",
                payload:
                  record.type === "Sell"
                    ? {
                        mobile_no: window.getValue(record, "mobile_no"),
                        pick_up_address: {
                          resultAddresstoSave: {
                            formattedAddress: window.getValue(
                              record,
                              "pickAddress.formatted_address"
                            ),
                            formatted_address: window.getValue(
                              record,
                              "pickAddress.formatted_address"
                            ),
                            lat: window.getValue(record, "pickAddress.lat"),
                            long: window.getValue(record, "pickAddress.long"),

                            address_id: window.getValue(
                              record,
                              "pickAddress.address_id"
                            ),
                          },
                        },
                      }
                    : {
                        mobile_no: window.getValue(record, "mobile_no"),
                        pick_up_address: {
                          resultAddresstoSave: {
                            formattedAddress: window.getValue(
                              record,
                              "pickAddress.formatted_address"
                            ),
                            formatted_address: window.getValue(
                              record,
                              "pickAddress.formatted_address"
                            ),
                            lat: window.getValue(record, "pickAddress.lat"),
                            long: window.getValue(record, "pickAddress.long"),

                            address_id: window.getValue(
                              record,
                              "pickAddress.address_id"
                            ),
                          },
                        },
                        drop_address: {
                          resultAddresstoSave: {
                            formattedAddress: window.getValue(
                              record,
                              "dropAddress.formatted_address"
                            ),
                            formatted_address: window.getValue(
                              record,
                              "dropAddress.formatted_address"
                            ),
                            lat: window.getValue(record, "dropAddress.lat"),
                            long: window.getValue(record, "dropAddress.long"),

                            address_id: window.getValue(
                              record,
                              "dropAddress.address_id"
                            ),
                          },
                        },
                      },
              });
              setExpandedRowKeys([window.getValue(record, "id")]);
            } else {
              setExpandedRowKeys([]);
            }
          },
          expandedRowRender: (record, index, indent, expanded) => {
            if (expanded) {
                return <PostItem
                  edit={true}
                  id={record.id}
                  type={record.type}
                  closeEditing={() => setExpandedRowKeys([])}
                />
            } else {
              return null;
            }
          },
        }}
        pagination={false}
        dataSource={items}
        rowKey={"id"}
        footer={() =>
          anyMore ? (
            loadingMore ? (
              <Loader
                spinning={loadingMore}
                tip=""
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              />
            ) : (
              <Button
                type="primary"
                onClick={() => {
                  myPublishedItems(items[items.length - 1].id);
                }}
                size="middle"
              >
                Load More
              </Button>
            )
          ) : (
            ""
          )
        }
      />
    </Skeleton>
  );
}
