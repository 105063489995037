import React, { useState } from "react";
import { Form, Input, Button, Divider } from "antd";
import { connect } from "react-redux";
import { Alert } from "antd";
import { Loader } from "../../common";
import { logout } from "../../../utility";

const formItemLayout = {
  labelCol: {
    span: 4
  },
  wrapperCol: {
    span: 8
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    push: 6
  }
};

export function ChangePasswordForm(props) {
  const [form] = Form.useForm();
  const [update, setUpdate] = useState(false);
  const [error, setError] = useState({ code: 0, message: "" });

  const onFinish = values => {
    //console.log('Received values of form: ', values);
    setUpdate(true);
    props.user
      .updatePassword(values.password)
      .then(() => {
        setUpdate(false);
        logout();
      })
      .catch(function(error) {
        if (error.message === "CREDENTIAL_TOO_OLD_LOGIN_AGAIN") {
        }
        setError(error);
        setUpdate(false);
        setTimeout(() => {
          logout();
        }, 3000);
      });
  };

  return (
    <Loader spinning={update}>
      <Alert
        style={{ width: "95%" }}
        message="Note: after new password is set, you will be logged out."
        type="info"
        showIcon
      />
      <Divider />
      {error.code !== 0 ? (
        <React.Fragment>
          <Alert
            message="Error"
            description={error.message}
            type="error"
            closable
            showIcon
            onClose={() => {
              setError({ code: 0, message: "" });
            }}
          />
          <Divider />
        </React.Fragment>
      ) : (
        ""
      )}
      <Form
        {...formItemLayout}
        form={form}
        name="change_password"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please input your password!"
            }
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!"
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  "The two passwords that you entered do not match!"
                );
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            Change Password
          </Button>
        </Form.Item>
      </Form>
    </Loader>
  );
}

const mapStateToProps = state => ({
  user: window.getValue(state, 'profile.user')
});

export default connect(mapStateToProps)(ChangePasswordForm);
