import React, { Fragment, useState } from "react";
import { Button, Divider, Steps, Result, Modal, Space, Grid } from "antd";
import { Prompt } from "react-router-dom";
import Loadable from "react-loadable";

import { postItem } from "..";

import { useDispatch, useSelector } from "react-redux";
import { Loader, postItemConstants } from "../../common";
import { history } from "../../../utility";
import { globalActions } from "../../landing";
import { profileActions } from "../../profile";
import { ConfirmSellOrderModal } from "./ConfirmSellOrderModal";
import { publishActions } from "../actions";
import { ChooseTypeToPostItem } from "./ChooseTypeToPostItem";
import PublishItemStep1 from "./PublishItemStep1";
import { useEffect } from "react";
import PublishItemStep3 from "./PublishItemStep3";
import PublishItemStep2 from "./PublishItemStep2";
import { ConfirmPublishOrderModal } from "./ConfirmPublishOrderModal";

const { useBreakpoint } = Grid;
const { Step } = Steps;
let forms = {
  step_1_form: null,
  step_2_form: null,
  step_3_form: null,
};

const SellItemStep1 = Loadable({
  loader: () => import("./SellItemStep1"),
  loading() {
    return <Loader spinning />;
  },
});

const SellItemStep3 = Loadable({
  loader: () => import("./SellItemStep3"),
  loading() {
    return <Loader spinning />;
  },
});

const SellItemStep2 = Loadable({
  loader: () => import("./SellItemStep2"),
  loading() {
    return <Loader spinning />;
  },
});

export function PostItem({ edit, id, closeEditing, type }) {
  const screens = useBreakpoint();
  const [current, setCurrent] = useState(edit ? 1 : 0);
  const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
  const dispatch = useDispatch();
  const form1Values = useSelector((state) =>
    window.getValue(state, "publish.STEP_1_FORM_DATA")
  );
  const form2Values = useSelector((state) =>
    window.getValue(state, "publish.STEP_2_FORM_DATA")
  );
  const form3Values = useSelector((state) =>
    window.getValue(state, "publish.STEP_3_FORM_DATA")
  );

  const [selling, setSelling] = useState(false);
  const [postedItem, setPostedItem] = useState(false);
  const [selectedType, setSelected] = useState(edit ? type : "");

  const [steps, setSteps] = useState([
    {
      title: "Choose To Sell/Rent",
      content: (
        <ChooseTypeToPostItem
          setSelected={(type) => {
            setSelected(type);
          }}
        />
      ),
    },
    {
      title: "Item Details",
    },
    {
      title: "Pricing",
    },
    {
      title: "Post",
    },
  ]);

  useEffect(() => {
    setSteps([
      {
        title: "Choose",
        content: (
          <ChooseTypeToPostItem
            setSelected={(type) => {
              next();
              setSelected(type);
            }}
          />
        ),
      },
      {
        title: "Item Details",
        content:
          selectedType === postItemConstants.RENT ? (
            <PublishItemStep1
              setFormReference={(form) => (forms.step_1_form = form)}
              edit={edit}
            />
          ) : (
            <SellItemStep1
              setFormReference={(form) => (forms.step_1_form = form)}
              edit={edit}
            />
          ),
      },
      {
        title: "Pricing",
        content:
          selectedType === postItemConstants.RENT ? (
            <PublishItemStep2
              setFormReference={(form) => (forms.step_2_form = form)}
              edit={edit}
            />
          ) : (
            <SellItemStep2
              setFormReference={(form) => (forms.step_2_form = form)}
              edit={edit}
            />
          ),
      },
      {
        title: "Post",
        content:
          selectedType === postItemConstants.RENT ? (
            <PublishItemStep3
              setFormReference={(form) => (forms.step_3_form = form)}
              edit={edit}
            />
          ) : (
            <SellItemStep3
              setFormReference={(form) => (forms.step_3_form = form)}
              edit={edit}
            />
          ),
      },
    ]);
  }, [selectedType, current]);

  const readyToPostOrder = () => {
    setDisplayConfirmDialog(false);
    readyToPostItem();
  };

  const hideConfirmDialog = () => {
    setDisplayConfirmDialog(false);
  };

  const dismissDialogToEditOrder = () => {
    setDisplayConfirmDialog(false);
  };
  const readyToPostItem = () => {
    setSelling(true);
    postItem(form1Values, form2Values, form3Values, id, selectedType)
      .then((response) => {
        if (response && response.success) {
          dispatch({
            type: publishActions.CLEAR_FORM_DATA,
          });
          dispatch({
            type: globalActions.CLEAR_ERROR,
          });
          if (edit) {
            closeEditing();
            dispatch({
              type: profileActions.MY_PUBLISHED_ITEM_UPDATE_DONE,
              payload: window.getValue(response, "data.item"),
            });
            Modal.success({
              content: "You Item is Updated.",
            });
          } else {
            window.scrollTo(0, 0);
            setPostedItem(true);
          }
          setCurrent(0);
        } else {
          dispatch({
            type: globalActions.SET_ERROR,
            payload:
              window.getValue(response, "data.message") || "Unable to Process",
          });
        }
        setSelling(false);
      })
      .catch((error) => {
        console.error(error);
        setSelling(false);
      });
  };
  const next = () => {
    if (current === 0) {
      setCurrent(current + 1);
      window.scrollTo(0, 0);
    } else {
      forms[`step_${current}_form`]
        .validateFields()
        .then((form3Values) => {
          dispatch({
            type: publishActions[`STEP_${current}_FORM_DATA`],
            payload: form3Values,
          });
          if (current === steps.length - 1) {
            setDisplayConfirmDialog(true);
          } else {
            setCurrent(current + 1);
            window.scrollTo(0, 0);
          }
        })
        .catch((errorInfo) => {
          console.error(errorInfo);
        });
    }
  };
  const prev = () => {
    /** clear captcha code on the step.3 */
    if (current - 1 === 1) {
      dispatch({
        type: publishActions.CLEAR_CAPTCHA,
      });
    }
    setCurrent(current - 1);
  };
  return (
    <Loader spinning={selling} tip="Uploading Your Item...">
      <Prompt
        message={() => {
          dispatch({
            type: publishActions.CLEAR_FORM_DATA,
          });
        }}
      />
      <div
        className="post-item"
        style={screens.xs ? { width: "90%", margin: "25px 2% 0px 2%" } : {}}
      >
        {!postedItem ? (
          <React.Fragment>
            <h3
              style={{
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {edit ? "EDIT " : "POST "}YOUR ITEM{" "}
              {selectedType ? `FOR ${selectedType.toUpperCase()}` : ""}
            </h3>

            <Divider />

            <Steps current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>
            <div className="steps-action">
              {current > 0 ? (
                current === 1 && edit ? (
                  <Fragment />
                ) : (
                  <Button
                    onClick={() => prev()}
                    size={"middle"}
                    style={{ width: 100 }}
                  >
                    Previous
                  </Button>
                )
              ) : (
                <Fragment />
              )}
              {current === steps.length - 1 && (
                <Button
                  type="primary"
                  onClick={() => next()}
                  size={"middle"}
                  style={{ width: 100, marginLeft: 10 }}
                >
                  {edit ? "Update" : selectedType}
                </Button>
              )}
              {current < steps.length - 1 && current !== 0 && (
                <Button
                  style={{ marginLeft: 10, width: 100 }}
                  type="primary"
                  onClick={() => next()}
                  size={"middle"}
                >
                  Next
                </Button>
              )}
            </div>
          </React.Fragment>
        ) : (
          <Result
            status="success"
            className="steps-content"
            title="Successfully Posted your Item"
            extra={[
              <Space
                align="center"
                direction={screens.xs ? "vertical" : "horizontal"}
              >
                <Button
                  type="primary"
                  key="another-sell"
                  onClick={() => setPostedItem(false)}
                >
                  Post Another Item
                </Button>

                <Button key="edit" onClick={() => history.push(`/profile/1`)}>
                  Edit your Items
                </Button>
              </Space>,
            ]}
          />
        )}
      </div>
      {displayConfirmDialog ? (
        selectedType === postItemConstants.RENT ? (
          <ConfirmPublishOrderModal
            readyToPostOrder={readyToPostOrder}
            editOrder={dismissDialogToEditOrder}
            hideConfirmDialog={hideConfirmDialog}
          />
        ) : (
          <ConfirmSellOrderModal
            readyToPostOrder={readyToPostOrder}
            editOrder={dismissDialogToEditOrder}
            hideConfirmDialog={hideConfirmDialog}
          />
        )
      ) : (
        ""
      )}
    </Loader>
  );
}
