export const recentlyPublishedItemsActions = {
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  REFRESH_RECENT_ITEMS: "REFRESH_RECENT_ITEMS",
  SINGLE_ITEM_LOADING: "SINGLE_ITEM_LOADING",
  SINGLE_ITEM_SUCCESS: "SINGLE_ITEM_SUCCESS",
  SINGLE_ITEM_CLICKED: "SINGLE_ITEM_CLICKED",
  LOAD_MORE_ITEMS: "LOAD_MORE_ITEMS",
  LOAD_MORE_ITEMS_LOADING: "LOAD_MORE_ITEMS_LOADING",
  SET_ITEMS_PER_PAGE: "SET_ITEMS_PER_PAGE",
  SET_SEARCH_LAT_LNG: "SET_SEARCH_LAT_LNG",
  UPDATE_INDIVIDUAL_ITEM_FOR_BOOKMARK: "UPDATE_INDIVIDUAL_ITEM_FOR_BOOKMARK",
  SET_REFRESH: "SET_REFRESH",
};
