import { combineReducers } from "redux";
import {
  profileReducer,
  kycReducer,
  fetchKycReducer,
  myPublishedItemsReducer,
  myBookmarkItemsReducer,
  myAddressesReducer,
  givenOnRentOrdersItemReducer,
  takenOnRentOrdersItemReducer,
} from "./profile";
import {
  publish,
  bookItemReducer,
  addItemComment,
  getItemComments,
} from "./publish";
import {
  globalReducer,
  recentlyPublishedItemsReducer,
} from "./landing/reducer";

const rootReducer = combineReducers({
  profile: profileReducer,
  publish,
  book: bookItemReducer,
  kyc: kycReducer,
  fetchKyc: fetchKycReducer,
  global: globalReducer,
  addItemComment,
  getItemComments,
  givenOnRentOrdersItem: givenOnRentOrdersItemReducer,
  takenOnRentOrdersItem: takenOnRentOrdersItemReducer,
  recentpublisheditems: recentlyPublishedItemsReducer,
  mypublisheditems: myPublishedItemsReducer,
  mybookmarkitems: myBookmarkItemsReducer,
  myaddresses: myAddressesReducer,
});

export default (state, action) => rootReducer(state, action);
