import React from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { recentlyPublishedItemsActions } from "../actions";

const { Option } = Select;
const HitsPerPageSelector = ({ isMobile }) => {
  const dispatch = useDispatch();
  const hitsPerPage = useSelector(
    (state) => window.getValue(state, "recentpublisheditems.hitsPerPage") || "8"
  );
  return (
    <div className="categories-filter">
      <Select
        defaultValue={hitsPerPage}
        placeholder="ITEMS PER PAGE"
        value={hitsPerPage}
        style={
          isMobile
            ? { width: "100%", marginTop: 10 }
            : { width: 150, fontWeight: "bolder" }
        }
        dropdownClassName="categories-filter-dropdown"
        onChange={(value) => {
          dispatch({
            type: recentlyPublishedItemsActions.SET_ITEMS_PER_PAGE,
            payload: value,
          });
        }}
        size={isMobile ? "large" : "small"}
      >
        <Option value="8" disabled>
          <div style={{ color: "#8080807d" }}>ITEMS PER PAGE</div>
        </Option>
        <Option value="16">16</Option>
        <Option value="32">32</Option>
        <Option value="64">64</Option>
        <Option value="128">128</Option>
      </Select>
    </div>
  );
};
export default HitsPerPageSelector;
