import { commentActions } from "../actions";

export const getItemComments = (
  state = { comments: [], anyMore: false },
  action
) => {
  switch (action.type) {
    case commentActions.LOADING:
      return {
        ...state,
        comments: [],
        loading: true,
      };
    case commentActions.LOAD_MORE_COMMENTS_LOADING:
      return {
        ...state,
        loadingMore: action.payload,
      };
    case commentActions.LOAD_MORE_ITEMS:
        return {
          ...state,
          loadingMore: false,
          loadMore: action.payload.length === 8,
          comments: state.comments.concat(action.payload)
        };
    case commentActions.ADD_SINGLE_COMMENT_SUCCESS:
        return {
            ...state,
            loadingMore: false,
            comments: state.comments ? [action.payload, ...state.comments] : [...state.comments],
        };
    case commentActions.COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        loadMore: Boolean(action.payload.length >= 8),
        comments: state.comments.concat(action.payload)
      };
    default:
      return state;
  }
};

export const addItemComment = (state = {}, action) => {
  switch (action.type) {
    case commentActions.ADD_COMMENT_REQUEST:
      return {
        ...state,
        submit: true,
      };
    case commentActions.ADD_COMMENT_SUCCESS:
      return {
        ...state,
        error: "",
        submit: false,
        comment: action.payload,
      };
    case commentActions.ADD_COMMENT_ERROR:
      return {
        ...state,
        submit: false,
        comment: null,
        error: action.payload,
      };
    default:
      return state;
  }
};
