import React, { Fragment, useMemo, useState } from "react";
import {
  Layout,
  BackTop,
  message,
  Affix,
  Button,
  Grid,
  Popover,
  List,
} from "antd";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import {
  ArrowUpOutlined,
  ShareAltOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Loadable from "react-loadable";
import { Profile } from "../../profile";
import Cookies from "js-cookie";
import { Loader, FAQ, Feedback, AboutUs } from "../../common";
import firebase from "../../../firebase";
import Footer from "../../common/Footer";
import { footerData } from "../../common/footer.data.source";
import { useEffect } from "react";
import { globalActions } from "../actions";

import { useHistory } from "react-router-dom";
import { ContactUs } from "../../common/contact/ContactUs";
import { PostItem } from "../../publish";

const { Content } = Layout;
const { useBreakpoint } = Grid;

message.config({
  duration: 3,
  maxCount: 1,
});

const Share = Loadable({
  loader: () => import("../../publish/components/Share"),
  render(loaded, props) {
    let Share = loaded.Share;
    return <Share {...props} />;
  },
  loading() {
    return <Loader spinning />;
  },
});

const MobileHeader = Loadable({
  loader: () => import("./MobileHeader"),
  loading() {
    return <Loader spinning />;
  },
});

const PublishedItem = Loadable({
  loader: () => import("../../publish/components/PublishedItem"),
  render(loaded, props) {
    let PublishedItem = loaded.PublishedItem;
    return <PublishedItem {...props} />;
  },
  loading() {
    return <Loader spinning />;
  },
});

const OrderPlaced = Loadable({
  loader: () => import("../../publish/components/OrderPlaced"),
  render(loaded, props) {
    let OrderPlaced = loaded.OrderPlaced;
    return <OrderPlaced {...props} />;
  },
  loading() {
    return <Loader spinning />;
  },
});

const HeaderPlaceHolder = Loadable({
  loader: () => import("./HeaderPlaceHolder"),
  loading() {
    return <Loader spinning />;
  },
});

const RecentlyPublishedItems = Loadable({
  loader: () => import("./RecentlyPublishedItems"),
  loading() {
    return <Loader spinning />;
  },
});

const DisplayName = Loadable({
  loader: () => import("./DisplayName"),
  render(loaded, props) {
    let DisplayName = loaded.DisplayName;
    return <DisplayName {...props} />;
  },
  loading() {
    return <Loader spinning />;
  },
});

const HowItWorks = Loadable({
  loader: () => import("../../common"),
  render(loaded, props) {
    const HowItWorks = loaded.HowItWorks;
    return <HowItWorks {...props} />;
  },
  loading: () => <Loader spinning />,
});

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        Cookies.get("loggedIn") ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function LandingPage() {
  const match = useRouteMatch({
    path: "/",
    strict: true,
    sensitive: true,
  });
  const screens = useBreakpoint();
  const [showHowItWorks, setHowItWorks] = useState(
    !Boolean(localStorage.getItem("firstTime"))
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const { loggedIn, user, error } = useSelector(
    (state) => ({
      loggedIn: window.getValue(state, "profile.loggedIn"),
      user: window.getValue(state, "profile.user"),
      error: "" || window.getValue(state, "global.error"),
    }),
    isEqual
  );

  const dataForSendBtn = useMemo(
    () => [
      <Button onClick={() => setHowItWorks(!showHowItWorks)}>
        What is RentoCircle
      </Button>,
      <Share />,
      <Feedback />,
    ],
    [showHowItWorks]
  );

  history.listen(() => {
    window.document.title = "RentoCircle | " + window.location.href;
    window.scrollTo(0, 0);
  });
  // useEffect(() => {
  //   if (window.getValue(searchState, "configure.aroundLatLng")) {
  //     dispatch({
  //       type: recentlyPublishedItemsActions.SET_SEARCH_LAT_LNG,
  //       payload: {
  //         ...searchState.configure.aroundLatLng,
  //         city: searchState.city,
  //       },
  //     });
  //   } else {
  //     if (
  //       localStorage.getItem("search-city") &&
  //       localStorage.getItem("search-city").length > 0
  //     ) {
  //       dispatch({
  //         type: recentlyPublishedItemsActions.SET_SEARCH_LAT_LNG,
  //         payload: JSON.parse(localStorage.getItem("search-city")),
  //       });
  //     }
  //   }

  //   if (window.getValue(searchState, "configure.hitsPerPage")) {
  //     dispatch({
  //       type: recentlyPublishedItemsActions.SET_ITEMS_PER_PAGE,
  //       payload: searchState.configure.hitsPerPage,
  //     });
  //   }
  // }, []);
  useEffect(() => firebase.analytics().logEvent("new_landing_page_called"), []);
  useEffect(() => {
    if (showHowItWorks) {
      localStorage.setItem("firstTime", "true");
    }
  }, [showHowItWorks]);

  if (error.length > 0) {
    message.error(error, 6).then(
      () =>
        dispatch({
          type: globalActions.CLEAR_ERROR,
        }),
      null
    );
  }
  return (
    <React.Fragment>
      <Layout
        className="app-layout"
        style={{
          display: !showHowItWorks ? "block" : "none",
        }}
      >
        {screens.xs ? <MobileHeader /> : <HeaderPlaceHolder />}

        <Content className="site-layout">
          {screens.xs ? (
            <Fragment />
          ) : (
            <BackTop visibilityHeight={150}>
              <div className="ant-back-top-inner">
                <ArrowUpOutlined />
              </div>
            </BackTop>
          )}
          <div className="site-layout-background" style={{ padding: 10 }}>
            {loggedIn && user && user.displayName === null ? (
              <DisplayName />
            ) : (
              <React.Fragment />
            )}
            <Switch>
              <PrivateRoute path="/publish">
                <PostItem />
              </PrivateRoute>

              <Route path="/item/:itemId">
                <PublishedItem />
              </Route>

              <PrivateRoute path="/order/:orderId">
                <OrderPlaced />
              </PrivateRoute>

              <PrivateRoute path="/profile/:subMenu?">
                <Profile />
              </PrivateRoute>

              <Route path="/" exact>
                <RecentlyPublishedItems />
              </Route>

              <Route path="/faq" exact>
                <FAQ />
              </Route>
              <Route path="/about-us" exact>
                <AboutUs />
              </Route>
              <Route path="/contact" exact>
                <ContactUs />
              </Route>
            </Switch>
          </div>

          {(match && match.isExact) || !screens.xs ? (
            <Affix
              style={{
                bottom: screens.xs ? "18pt" : "30pt",
                display: "inline",
                fontSize: "11px",
                position: "fixed",
                left: screens.xs ? "6pt" : "16pt",
                top: "auto",
                borderRadius: "30px",
                zIndex: 2147483646,
                color: "white",
                background: "#0066ff",
              }}
            >
              <Popover
                placement="top"
                content={
                  <List
                    size="small"
                    className="send-items-list"
                    dataSource={dataForSendBtn}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                  />
                }
                trigger="click"
              >
                <ShareAltOutlined
                  style={{ fontSize: "30px", padding: "10px" }}
                />
              </Popover>
            </Affix>
          ) : (
            <Fragment />
          )}
          {screens.xs ? (
            <Affix
              style={{
                display: "flex",
                position: "fixed",
                flexDirection: "row",
                left: 0,
                right: 0,
                top: 0,
                zIndex: 2147483646,
                background: "#000000",
                padding: "6px 6% 0px 12%",
              }}
            >
              <div
                style={{
                  height: 30,
                }}
              >
                <h4 style={{ color: "#ffffff", fontWeight: 600 }}>
                  List your item and get real money!
                </h4>
              </div>
            </Affix>
          ) : (
            <Affix
              style={{
                display: "flex",
                position: "fixed",
                flexDirection: "row",
                top: 2,
                right: "7%",
                zIndex: 2147483646,
                background: "#6bffb5",
                borderRadius: 6,
                padding: "6px 0px 0px 10px",
              }}
            >
              <div
                style={{
                  height: 30,
                }}
              >
                <h4 style={{ color: "#000000", fontWeight: 600 }}>
                  &nbsp;List your item and get real money &nbsp;
                </h4>
              </div>
            </Affix>
          )}
          {match && match.isExact ? (
            <Affix
              style={{
                bottom: "20pt",
                display: "flex",
                fontSize: "11px",
                position: "fixed",
                right: "6pt",
                top: "auto",
                borderRadius: "30px",
                zIndex: 2147483646,
                color: "white",
                visibility: screens.xs ? "visible" : "hidden",
              }}
            >
              <Button
                type="primary"
                shape="circle"
                style={{
                  height: 45,
                  width: 45,
                  paddingBottom: 2,
                  backgroundColor: "#ffffff",
                }}
                href="https://wa.me/918484988851?text=Hi"
                icon={
                  <WhatsAppOutlined
                    style={{
                      color: "#01e675",
                      fontSize: 45,
                    }}
                  />
                }
              ></Button>
            </Affix>
          ) : (
            <Fragment />
          )}

          <Footer
            id="footer"
            key="footer"
            dataSource={footerData(screens.xs)}
            isMobile={screens.xs}
          />
        </Content>
      </Layout>
      {showHowItWorks ? <HowItWorks hide={setHowItWorks} /> : null}
    </React.Fragment>
  );
}

export default LandingPage;
