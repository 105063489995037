import React, { Fragment } from "react";
import { Table, Button } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { takenOnRentOrdersItem } from "../service";
import { Loader, postItemConstants } from "../../common";
import { useSelector, shallowEqual } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { AddRating } from "./AddRating";
import { RateDisplay } from "./RateDisplay";
import { useCallback } from "react";

export function MyConsumedOrders({ type }) {
  const [displayRateModal, setDisplayRateModal] = useState(false);
  const [star, setStar] = useState(0);
  const [selectedRow, setSelectedRow] = useState({});
  const {
    loadingRentIn,
    dataForRentIn,
    loadingMoreRentIn,
    anyMoreRentIn,
  } = useSelector(
    (state) => ({
      loadingRentIn: window.getValue(state, "takenOnRentOrdersItem.loading"),
      dataForRentIn:
        window.getValue(state, "takenOnRentOrdersItem.items") || [],
      loadingMoreRentIn: window.getValue(
        state,
        "takenOnRentOrdersItem.loadingMore"
      ),
      anyMoreRentIn: window.getValue(state, "takenOnRentOrdersItem.anyMore"),
    }),
    shallowEqual
  );

  useEffect(() => {
    takenOnRentOrdersItem(type);
  }, [type]);

  const updateStateForModal = useCallback((updatedStar, row) => {
    //console.log("consum updateStateForModal called", updatedStar, row);
    setStar(updatedStar);
    setDisplayRateModal(true);
    setSelectedRow(row);
  }, []);

  const columnsForRentIn = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Ordered Date",
      dataIndex: "ordered",
      key: "ordered",
      align: "center",
    },
    {
      title: "Order Type",
      dataIndex: "orderType",
      key: "orderType",
      align: "center",
    },

    // {
    //   title: "Order Status",
    //   dataIndex: "orderStatus",
    //   key: "orderStatus",
    //   align: "center",
    // },
    {
      title: "Average Rating",
      dataIndex: "",
      key: "ratings",
      align: "center",
      render: (row) => {
        return row.orderType === postItemConstants.SELL ? <Fragment>--</Fragment> :
          (<RateDisplay
            updateStateForModal={updateStateForModal}
            row={row}
            rateKey={window.getValue(row, "itemId")}
          />
        )
        // let rate = 0;
        // getItemRatings(window.getValue(row, 'detailId')).then((response) => {
        //   console.log('getItemRatings response = ', response);
        //   let star = 0;
        //   if (response && window.getValue(response, "success") && window.getValue(response, "data.ratings")) {
        //     console.log('getItemRatings if case = ', window.getValue(response, "data.ratings"));
        //     rate = window.getValue(response, "data.ratings");
        //     if (rate.length > 0)
        //       star = rate[0].star;
        //   }
        //   return (
        //     <Rate tooltips={ratingTooltip} defaultValue={star} onChange={(value) => {
        //       setStar(value);
        //       setDisplayRateModal(true);
        //       setSelectedItemId(window.getValue(row, 'detailId'))
        //     }}/>
        //   )
        // })
        // return <Spin />;
      },
    },
    {
      title: "View Product Details",
      dataIndex: "itemId",
      key: "open",
      align: "center",
      render: (itemId) => <Link to={`/item/${itemId}`}>Click to view</Link>,
    },
  ];
  console.log("consumer getItemRatings testing = ", dataForRentIn);
  let finalItemRentIn = [];
  if (dataForRentIn) {
    for (let i = 0; i < dataForRentIn.length; i++) {
      const item = dataForRentIn[i];
      finalItemRentIn.push({
        key: window.getValue(item, "id") + i,
        id: window.getValue(item, "id"),
        name: window.getValue(item, "item.name"),
        ordered: moment(window.getValue(item, "booking_timestamp")).format(
          "DD-MM-YYYY"
        ),
        orderType: window.getValue(item, "item.type"),
        // orderStatus: window.getValue(item, "status"),
        ratings: window.getValue(item, "item.ratings"),
        itemId: window.getValue(item, "item.id"),
      });
    }
  }
  const onCancelHideModal = () => {
    setDisplayRateModal(false);
  };
  return (
    <div>
      <Table
        title={() => <div>{type === postItemConstants.RENT ? 'Items you have taken on Rent'
          : type === postItemConstants.SELL ? 'Items you have bought'  : 'All your consumed Rent & Sell orders'} </div>}
        size="middle"
        bordered
        columns={columnsForRentIn}
        tableLayout={"auto"}
        loading={{
          spinning: loadingRentIn,
          indicator: <Loader spinning={loadingRentIn} tip="" />,
        }}
        dataSource={finalItemRentIn}
        pagination={false}
        footer={() =>
          anyMoreRentIn ? (
            loadingMoreRentIn ? (
              <Loader
                spinning={loadingMoreRentIn}
                tip=""
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              />
            ) : (
              <Button
                type="primary"
                onClick={() => {
                  takenOnRentOrdersItem(type,
                    finalItemRentIn[finalItemRentIn.length - 1].id
                  );
                }}
                size="middle"
              >
                Load More
              </Button>
            )
          ) : (
            ""
          )
        }
      />
      {displayRateModal ? (
        <AddRating
          type={"item"}
          id={selectedRow.itemId}
          star={star}
          ratingsInfo={selectedRow.ratings}
          onCancelHideModal={onCancelHideModal}
        />
      ) : (
        ""
      )}
    </div>
  );
}
