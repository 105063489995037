import React, { useState } from "react";
import { DeleteFilled, LoadingOutlined } from "@ant-design/icons";
import { Popconfirm, message } from "antd";
import { useDispatch } from "react-redux";
import { profileActions } from "../actions";

const style = { fontSize: "18px" };

export function DeleteAddress({ row }) {
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  return loader ? (
    <LoadingOutlined style={style} />
  ) : (
    <Popconfirm
      title="Are you sure delete this Address?"
      onConfirm={(e) => {
        e.stopPropagation();
        setLoader(true);
        fetch(`${window["apiURL"]}/address/${row.id}`, {
          method: "DELETE",
        })
          .then((response) => {
            if (response && window.getValue(response, "success")) {
              message.success("Address removed.");
              setLoader(false);
              dispatch({
                type: profileActions.MY_ADDRESSES_DELETE_DONE,
                payload: row.id,
              });
            } else {
              message.error("Error removing Address");
              setLoader(false);
            }
          })
          .catch((e) => {
            setLoader(false);
            message.error("Error removing Address" + e.toString());
          });
      }}
      onCancel={(e) => {
        e.stopPropagation();
      }}
      okText="Yes"
      cancelText="No"
    >
      <DeleteFilled
        style={style}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </Popconfirm>
  );
}
