import { profileActions } from "./actions";
import { globalActions } from "../landing";
import { store } from "../../store";
import { message } from "antd";

export const verifyKYC = userKYCUUID => {
  store.dispatch({
    type: profileActions.KYC_VERIFY_REQUEST,
    payload: true
  });

  fetch(`${window["apiURL"]}/kyc/details`, {
    method: "POST",
    body: JSON.stringify({
      kyc: {
        aadhaar: {
          uuid: "" + userKYCUUID
        }
      }
    }),
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(response => {
      if (response && window.getValue(response, "success")) {
        store.dispatch({
          type: profileActions.KYC_VERIFY_SUCCESS,
          payload: response
        });
        store.dispatch({
          type: profileActions.FETCH_KYC_VERIFY_SUCCESS,
          payload: `XXXX-XXXX-XXXX-${userKYCUUID.slice(-4)}`
        });
        message.success("Verified your KYC !!!");
      } else {
        store.dispatch({
          type: profileActions.KYC_VERIFY_FAILURE,
          payload: null
        });
        store.dispatch({
          type: globalActions.SET_ERROR,
          payload: "Failed to update KYC"
        });
      }
    })
    .catch(error => {
      store.dispatch([
        {
          type: profileActions.KYC_LOADING,
          payload: false
        },
        {
          type: globalActions.SET_ERROR,
          payload: error.toString()
        }
      ]);
    });
};

export const fetchVerifyKYC = () => {
  store.dispatch({
    type: profileActions.FETCH_KYC_VERIFY_REQUEST,
    payload: true
  });

  fetch(`${window["apiURL"]}/kyc/details`)
    .then(response => {
      if (response && window.getValue(response, "success")) {
        store.dispatch({
          type: profileActions.FETCH_KYC_VERIFY_SUCCESS,
          payload: `XXXX-XXXX-XXXX-${window.getValue(
            response,
            "data.kycDetails.aadhaar.uuid"
          )}`
        });
      } else {
        store.dispatch({
          type: profileActions.FETCH_KYC_VERIFY_FAILURE,
          payload: ""
        });
      }
    })
    .catch(error => {
      store.dispatch({
        type: profileActions.FETCH_KYC_VERIFY_FAILURE,
        payload: ""
      });
      store.dispatch({
        type: globalActions.SET_ERROR,
        payload: error.toString()
      });
    });
};

export const myPublishedItems = pointerToLastItemLoaded => {
  let url = "";
  if (pointerToLastItemLoaded) {
    store.dispatch({
      type: profileActions.MY_PUBLISHED_ITEMS_LOADING_MORE,
      payload: true
    });
    url = `${window["apiURL"]}/item/all?nextToken=${pointerToLastItemLoaded}`;
  } else {
    store.dispatch({
      type: profileActions.MY_PUBLISHED_ITEMS_LOADING,
      payload: true
    });
    url = `${window["apiURL"]}/item/all`;
  }

  return fetch(url)
    .then(response => {
      if (response && window.getValue(response, "success")) {
        store.dispatch({
          type: profileActions.MY_PUBLISHED_ITEMS_SUCCESS,
          payload: window.getValue(response, "data.items")
        });
      } else {
        store.dispatch([
          {
            type: profileActions.MY_PUBLISHED_ITEMS_LOADING,
            payload: false
          },
          {
            type: profileActions.MY_PUBLISHED_ITEMS_LOADING_MORE,
            payload: false
          },
          {
            type: globalActions.SET_ERROR,
            payload: "Unable to fetch your items"
          }
        ]);
      }
    })
    .catch(e => {
      store.dispatch([
        {
          type: profileActions.MY_PUBLISHED_ITEMS_LOADING,
          payload: false
        },
        {
          type: profileActions.MY_PUBLISHED_ITEMS_LOADING_MORE,
          payload: false
        },
        {
          type: globalActions.SET_ERROR,
          payload: "Unable to fetch your items" + e
        }
      ]);
    });
};

export const togglePublishedItem = (newStatus, record) => {
  let originalStatus = record.status;
  store.dispatch({
    type: profileActions.MY_PUBLISHED_ITEM_UNPUBLISH_UPDATING,
    payload: {
      id: record.id,
      status: "updating"
    }
  });
  return fetch(`${window["apiURL"]}/item/status`, {
    method: "PUT",
    body: JSON.stringify({
      item_id: record.id,
      status: newStatus
    }),
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(response => {
      if (response && window.getValue(response, "success")) {
        store.dispatch({
          type: profileActions.MY_PUBLISHED_ITEM_UNPUBLISH_UPDATING,
          payload: {
            id: record.id,
            status: newStatus
          }
        });
        message.info(`Item is ${newStatus} now`);
      } else {
        store.dispatch({
          type: profileActions.MY_PUBLISHED_ITEM_UNPUBLISH_UPDATING,
          payload: {
            id: record.id,
            status: originalStatus
          }
        });
        store.dispatch({
          type: globalActions.SET_ERROR,
          payload:
            "Unable to publish/unpublish your items" + response.data.message
        });
      }
    })
    .catch(e => {
      store.dispatch({
        type: profileActions.MY_PUBLISHED_ITEM_UNPUBLISH_UPDATING,
        payload: {
          id: record.id,
          status: originalStatus
        }
      });
      store.dispatch({
        type: globalActions.SET_ERROR,
        payload: "Unable to publish/unpublish your items " + e
      });
    });
};

export const myBookmarkItems = pointerToLastItemLoaded => {
  let url = "";
  if (pointerToLastItemLoaded) {
    store.dispatch({
      type: profileActions.MY_BOOKMARK_ITEMS_LOADING_MORE,
      payload: true
    });
    url = `${window["apiURL"]}/item/bookmark/all?nextToken=${pointerToLastItemLoaded}`;
  } else {
    store.dispatch({
      type: profileActions.MY_BOOKMARK_ITEMS_LOADING,
      payload: true
    });
    url = `${window["apiURL"]}/item/bookmark/all`;
  }

  return fetch(url)
    .then(response => {
      if (response && window.getValue(response, "success")) {
        store.dispatch({
          type: profileActions.MY_BOOKMARK_ITEMS_SUCCESS,
          payload: window.getValue(response, "data.bookmarks")
        });
      } else {
        store.dispatch({
          type: profileActions.MY_BOOKMARK_ITEMS_LOADING,
          payload: false
        });
        store.dispatch({
          type: profileActions.MY_BOOKMARK_ITEMS_LOADING_MORE,
          payload: false
        });
        store.dispatch({
          type: globalActions.SET_ERROR,
          payload: "Unable to load bookmarks."
        });
      }
    })
    .catch(error => {
      store.dispatch({
        type: profileActions.MY_BOOKMARK_ITEMS_LOADING,
        payload: false
      });
      store.dispatch({
        type: profileActions.MY_BOOKMARK_ITEMS_LOADING_MORE,
        payload: false
      });
      store.dispatch({
        type: globalActions.SET_ERROR,
        payload: "Unable to load bookmarks."
      });
    });
};

export const myAddresses = pointerToLastItemLoaded => {
  let url = "";
  if (pointerToLastItemLoaded) {
    store.dispatch({
      type: profileActions.MY_ADDRESSES_LOADING_MORE,
      payload: true
    });
    url = `${window["apiURL"]}/address/all?nextToken=${pointerToLastItemLoaded}`;
  } else {
    store.dispatch({
      type: profileActions.MY_ADDRESSES_LOADING,
      payload: true
    });
    url = `${window["apiURL"]}/address/all`;
  }

  return fetch(url)
    .then(response => {
      if (response && window.getValue(response, "success")) {
        store.dispatch({
          type: profileActions.MY_ADDRESSES_SUCCESS,
          payload: window.getValue(response, "data.address")
        });
      } else {
        store.dispatch({
          type: profileActions.MY_ADDRESSES_LOADING,
          payload: false
        });
        store.dispatch({
          type: profileActions.MY_ADDRESSES_LOADING_MORE,
          payload: false
        });
        store.dispatch({
          type: globalActions.SET_ERROR,
          payload: "Unable to load your addresses."
        });
      }
    })
    .catch(error => {
      store.dispatch({
        type: profileActions.MY_ADDRESSES_LOADING,
        payload: false
      });
      store.dispatch({
        type: profileActions.MY_ADDRESSES_LOADING_MORE,
        payload: false
      });
      store.dispatch({
        type: globalActions.SET_ERROR,
        payload: "Unable to load your addresses."
      });
    });
};

export const givenOnRentOrdersItem = (type, pointerToLastItemLoaded) => {
  let url = "";
  if (pointerToLastItemLoaded) {
    store.dispatch({
      type: profileActions.ORDERS_GIVEN_ITEMS_LOADING_MORE,
      payload: true
    });
    url = `${window["apiURL"]}/order/publisher/${type.toLowerCase()}?nextToken=${pointerToLastItemLoaded}`;
  } else {
    store.dispatch({
      type: profileActions.ORDERS_GIVEN_ITEMS_LOADING,
      payload: true
    });
    url = `${window["apiURL"]}/order/publisher/${type.toLowerCase()}`;
  }

  return fetch(url)
    .then(response => {
      if (response && window.getValue(response, "success")) {
        store.dispatch({
          type: profileActions.ORDERS_GIVEN_ITEMS_SUCCESS,
          payload: window.getValue(response, "data.orders")
        });
      } else {
        store.dispatch({
          type: profileActions.ORDERS_GIVEN_ITEMS_LOADING,
          payload: false
        });
        store.dispatch({
          type: profileActions.ORDERS_GIVEN_ITEMS_LOADING_MORE,
          payload: false
        });
        store.dispatch({
          type: globalActions.SET_ERROR,
          payload: "Unable to fetch your items"
        });
      }
    })
    .catch(e => {
      store.dispatch({
        type: profileActions.ORDERS_GIVEN_ITEMS_LOADING,
        payload: false
      });
      store.dispatch({
        type: profileActions.ORDERS_GIVEN_ITEMS_LOADING_MORE,
        payload: false
      });
      store.dispatch({
        type: globalActions.SET_ERROR,
        payload: "Unable to fetch your items" + e
      });
    });
};

export const takenOnRentOrdersItem = (type, pointerToLastItemLoaded) => {
  let url = "";
  if (pointerToLastItemLoaded) {
    store.dispatch({
      type: profileActions.ORDERS_TAKEN_ITEMS_LOADING_MORE,
      payload: true
    });
    url = `${window["apiURL"]}/order/consumer/${type.toLowerCase()}?nextToken=${pointerToLastItemLoaded}`;
  } else {
    store.dispatch({
      type: profileActions.ORDERS_TAKEN_ITEMS_LOADING,
      payload: true
    });
    url = `${window["apiURL"]}/order/consumer/${type.toLowerCase()}`;
  }

  return fetch(url)
    .then(response => {
      if (response && window.getValue(response, "success")) {
        store.dispatch({
          type: profileActions.ORDERS_TAKEN_ITEMS_SUCCESS,
          payload: window.getValue(response, "data.orders")
        });
      } else {
        store.dispatch({
          type: profileActions.ORDERS_TAKEN_ITEMS_LOADING,
          payload: false
        });
        store.dispatch({
          type: profileActions.ORDERS_TAKEN_ITEMS_LOADING_MORE,
          payload: false
        });
        store.dispatch({
          type: globalActions.SET_ERROR,
          payload: "Unable to fetch your items"
        });
      }
    })
    .catch(e => {
      store.dispatch({
        type: profileActions.ORDERS_TAKEN_ITEMS_LOADING,
        payload: false
      });
      store.dispatch({
        type: profileActions.ORDERS_TAKEN_ITEMS_LOADING_MORE,
        payload: false
      });
      store.dispatch({
        type: globalActions.SET_ERROR,
        payload: "Unable to fetch your items" + e
      });
    });
};

export function getItemRatings(itemId) {
  store.dispatch({
    type: profileActions.GET_ITEM_RATING_LOADING,
    payload: true
  });

  return fetch(`${window["apiURL"]}/ratings/item/${itemId}`)
    .then(response => {
      if (response && window.getValue(response, "success")) {
        store.dispatch({
          type: profileActions.GET_ITEM_RATING_SUCCESS,
          payload: window.getValue(response, "data.ratings")
        });
        return response;
      } else {
        store.dispatch({
          type: profileActions.GET_ITEM_RATING_FAILURE,
          payload: null,
        });
        store.dispatch({
          type: globalActions.SET_ERROR,
          payload: "Unable to load bookmarks."
        });
        return null;
      }
    })
    .catch(error => {
      store.dispatch({
        type: globalActions.SET_ERROR,
        payload: "Unable to load bookmarks."
      });
      store.dispatch({
        type: profileActions.GET_ITEM_RATING_FAILURE,
        payload: error.toString(),
      });
      return null;
    });
}

export function getConsumerRatings(consumerId) {
  store.dispatch({
    type: profileActions.GET_CONSUMER_RATING_LOADING,
    payload: true
  });

  return fetch(`${window["apiURL"]}/ratings/consumer/${consumerId}`)
    .then(response => {
      if (response && window.getValue(response, "success")) {
        store.dispatch({
          type: profileActions.GET_CONSUMER_RATING_SUCCESS,
          payload: window.getValue(response, "data.ratings")
        });
        return window.getValue(response, "data.ratings");
      } else {
        store.dispatch({
          type: globalActions.SET_ERROR,
          payload: "Unable to load bookmarks."
        });
        return null;
      }
    })
    .catch(error => {
      store.dispatch({
        type: globalActions.SET_ERROR,
        payload: "Unable to load bookmarks."
      });
      return null;
    });
}

export const addRatings = (ratingObject) => {
  store.dispatch({
    type: profileActions.ADD_ITEM_RATING_LOADING,
  });
  return fetch(`${window["apiURL"]}/ratings/add`, {
    method: "POST",
    body: JSON.stringify(ratingObject),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response && window.getValue(response, "success")) {
        store.dispatch({
          type: profileActions.ADD_ITEM_RATING_SUCCESS,
          payload: window.getValue(response, "data.ratings"),
        });
      } else {
        store.dispatch({
            type: globalActions.SET_ERROR,
            payload: "Unable to submit ratings. Please try again"
          });
      }
      return response;
    })
    .catch((e) => {
      store.dispatch({
        type: globalActions.SET_ERROR,
        payload: "Unable to submit ratings",
      });
      return null;
    });
};
