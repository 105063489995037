import React, { useState } from "react";
import { Map, Marker, InfoWindow, Circle } from "google-maps-react";
export function CustomMap(props) {
  const [activeMarker, setActiveMarker] = useState(null);

  return (
    <Map
      google={window.google}
      style={{
        position: "relative",
        width: "108%",
        height: "10rem",
      }}
      zoom={15}
      containerStyle={{
        position: "relative",
      }}
      initialCenter={{
        lat: props.lat,
        lng: props.lng,
      }}
    >
      <Marker
        title={"Pick Up Point"}
        onClick={(props, marker, e) => {
          setActiveMarker(marker);
        }}
        position={{
          lat: props.lat,
          lng: props.lng,
        }}
      />
      <InfoWindow marker={activeMarker} visible={true}>
        <div>
          <h3>Check how far item is located from your current location</h3>
          <a href={`https://maps.google.com/?q=${props.lat},${props.lng}&z=8`}>
            Check it
          </a>
        </div>
      </InfoWindow>
      <Circle
        radius={300}
        center={{
          lat: props.lat,
          lng: props.lng,
        }}
        strokeColor="transparent"
        strokeOpacity={0}
        strokeWeight={5}
        fillColor="#0276fd"
      />
    </Map>
  );
}
