import { recentlyPublishedItemsActions } from "../actions";
export const recentlyPublishedItemsReducer = (
  state = { loadMore: false, loading: true, refresh: true },
  action
) => {
  switch (action.type) {
    case recentlyPublishedItemsActions.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case recentlyPublishedItemsActions.SUCCESS:
      return {
        ...state,
        loading: false,
        loadMore: action.payload.length === 8,
        items: action.payload,
      };
    case recentlyPublishedItemsActions.REFRESH_RECENT_ITEMS:
      return {};
    case recentlyPublishedItemsActions.SINGLE_ITEM_LOADING:
      return {
        ...state,
        loadingSingleItem: action.payload,
      };
    case recentlyPublishedItemsActions.SINGLE_ITEM_SUCCESS:
      return {
        ...state,
        loadingSingleItem: false,
        items: state.items
          ? [...state.items, action.payload]
          : [action.payload],
      };

    case recentlyPublishedItemsActions.LOAD_MORE_ITEMS:
      return {
        ...state,
        loadingMoreItems: false,
        loadMore: action.payload.length === 8,
        items: state.items.concat(action.payload),
      };
    case recentlyPublishedItemsActions.SET_ITEMS_PER_PAGE:
      return {
        ...state,
        hitsPerPage: action.payload,
      };
    case recentlyPublishedItemsActions.SET_SEARCH_LAT_LNG:
      return {
        ...state,
        latlng: action.payload,
      };
    case recentlyPublishedItemsActions.LOAD_MORE_ITEMS_LOADING:
      return {
        ...state,
        loadingMoreItems: action.payload,
      };

    case recentlyPublishedItemsActions.UPDATE_INDIVIDUAL_ITEM_FOR_BOOKMARK:
      const items = state.items;
      items.forEach((item, index) => {
        if (item.id === action.payload.itemId) {
          if (action.payload.type === "bookmark") {
            items[index].bookmarked_by.push(action.payload.uuid);
          } else {
            const temp = items[index];
            const tempIndex = temp.bookmarked_by.indexOf(action.payload.uuid);
            temp.bookmarked_by.splice(tempIndex, 1);
            items[index] = temp;
          }
        }
      });

      return {
        ...state,
        items,
      };
    case recentlyPublishedItemsActions.SET_REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };
    default:
      return state;
  }
};
