import { publishActions, bookActions } from "../";

const initialState = {
  STEP_1_FORM_DATA: {},
  STEP_2_FORM_DATA: {},
  STEP_3_FORM_DATA: {}
};
export const publish = (state = initialState, action) => {
  switch (action.type) {
    case publishActions.STEP_1_FORM_DATA:
      return {
        ...state,
        STEP_1_FORM_DATA: action.payload
      };
    case publishActions.STEP_2_FORM_DATA:
      return {
        ...state,
        STEP_2_FORM_DATA: action.payload
      };
    case publishActions.STEP_3_FORM_DATA:
      return {
        ...state,
        STEP_3_FORM_DATA: action.payload
      };
    case publishActions.CLEAR_CAPTCHA:
      return {
        ...state,
        STEP_3_FORM_DATA: {
          ...state.STEP_3_FORM_DATA,
          captcha: ""
        }
      };
    case publishActions.CLEAR_FORM_DATA:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export const bookItemReducer = (state = {}, action) => {
  switch (action.type) {
    case bookActions.BOOK_ITEM_REQUEST:
      return {
        ...state,
        sendRequest: true
      };
    case bookActions.BOOK_ITEM_SUCCESS:
      return {
        ...state,
        error: "",
        sendRequest: false,
        book: action.payload
      };
    case bookActions.BOOK_ITEM_ERROR:
      return {
        ...state,
        sendRequest: false,
        error: action.payload
      };
    default:
      return state;
  }
};
