import React from "react";
import { Select, Tag } from "antd";
import { connectRefinementList, Highlight } from "react-instantsearch-dom";

const { Option } = Select;
const RefinementList = ({
  items,
  isFromSearch,
  refine,
  searchForItems,
  createURL,
  currentRefinement,
  placeholder,
  isMobile,
  width,
}) => {
  const children = [];
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    children.push(
      <Option key={item.isRefined + "" + i} value={item.label}>
        {isFromSearch ? <Highlight attribute="label" hit={item} /> : item.label}{" "}
        ({item.count})
      </Option>
    );
  }
  return (
    <div className="categories-filter">
      <Select
        placeholder={placeholder}
        mode="tags"
        style={
          isMobile
            ? { width: "100%", marginTop: 10 }
            : { width: width, fontWeight: "bolder", color: "black" }
        }
        dropdownClassName="categories-filter-dropdown"
        onChange={(value) => {
          refine(value);
        }}
        tokenSeparators={[","]}
        showArrow
        allowClear
        maxTagCount={2}
        size={isMobile ? "large" : "small"}
        value={currentRefinement}
        tagRender={(props) => {
          const { label, closable, onClose } = props;
          return (
            <Tag
              closable={closable}
              onClose={onClose}
              style={{ marginRight: 2, padding: 5 }}
            >
              {label}
            </Tag>
          );
        }}
      >
        {children}
      </Select>
    </div>
  );
};
const CustomRefinementList = connectRefinementList(RefinementList);
export default CustomRefinementList;
