import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { recentlyPublishedItemsActions } from "../";
import { connectStateResults } from "react-instantsearch-dom";

const StateResults = ({ searchResults, searching, error }) => {
  const dispatch = useDispatch();
  const hasResults = searchResults && searchResults.nbHits !== 0;
  // const nbHits = searchResults && searchResults.nbHits;
  useEffect(() => {
    if (searching) {
      dispatch({
        type: recentlyPublishedItemsActions.LOADING,
        payload: true,
      });
    } else if (!error && searchResults) {
      dispatch({
        type: recentlyPublishedItemsActions.SUCCESS,
        payload: searchResults.hits,
      });
    }
  }, [searching, error, searchResults, hasResults, dispatch]);

  // return searching ? (
  //   <div>finding Items</div>
  // ) : (
  //   <div>
  //     <div hidden={!hasResults} style={{ width: 150, textAlign: 'center' }}>Found {nbHits} Items</div>
  //     <div hidden={hasResults}>Found zero Items</div>
  //   </div>
  // );
  return (
    <h4
      style={{
        paddingTop: "8px",
        fontWeight: "550",
      }}
    ></h4>
  );
};
const CustomStateResults = connectStateResults(StateResults);
export default CustomStateResults;
