import React from "react";
import { Tabs } from "antd";
import Loadable from "react-loadable";
import {
  BookOutlined,
  FileProtectOutlined,
  CreditCardOutlined,
  ShoppingOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { history } from "../../../utility";
import { useParams } from "react-router-dom";
import { Loader } from "../../common";
const { TabPane } = Tabs;

const VerifyAadhaarCard = Loadable({
  loader: () => import("./VerifyAadhaarCard"),
  render(loaded, props) {
    let VerifyAadhaarCard = loaded.VerifyAadhaarCard;
    return <VerifyAadhaarCard {...props} />;
  },
  loading() {
    return <Loader spinning />;
  },
});

// const ChangeSettings = Loadable({
//   loader: () => import("./ChangeSettings"),
//   render(loaded, props) {
//     let ChangeSettings = loaded.ChangeSettings;
//     return <ChangeSettings {...props} />;
//   },
//   loading() {
//     return <Loader spinning />;
//   },
// });

const AddEditAddress = Loadable({
  loader: () => import("./AddEditAddress"),
  render(loaded, props) {
    let AddEditAddress = loaded.AddEditAddress;
    return <AddEditAddress {...props} />;
  },
  loading() {
    return <Loader spinning />;
  },
});

const MyPublishedItems = Loadable({
  loader: () => import("./MyPublishedItems"),
  render(loaded, props) {
    let MyPublishedItems = loaded.MyPublishedItems;
    return <MyPublishedItems {...props} />;
  },
  loading() {
    return <Loader spinning />;
  },
});

const BookmarkItems = Loadable({
  loader: () => import("./BookmarkItems"),
  render(loaded, props) {
    let BookmarkItems = loaded.BookmarkItems;
    return <BookmarkItems {...props} />;
  },
  loading() {
    return <Loader spinning />;
  },
});

const Orders = Loadable({
  loader: () => import("./Orders"),
  render(loaded, props) {
    let Orders = loaded.Orders;
    return <Orders {...props} />;
  },
  loading() {
    return <Loader spinning />;
  },
});
const styleForIcons = {
  fontSize: 22,
};
export function Profile() {
  let { subMenu } = useParams();
  return (
    <div className="profile-page">
      <Tabs
        onTabClick={(key) => history.push(`/profile/${key}`)}
        size="large"
        centered
        activeKey={subMenu}
        tabBarStyle={{
          margin: "0% 1% 1% 5%",
        }}
      >
        <TabPane
          tab={
            <strong>
              <FileProtectOutlined style={styleForIcons} />
              My Items
            </strong>
          }
          key="1"
        >
          <MyPublishedItems />
        </TabPane>

        <TabPane
          tab={
            <strong>
              <BookOutlined style={styleForIcons} />
              Bookmark Items
            </strong>
          }
          key="2"
        >
          <BookmarkItems />
        </TabPane>
        <TabPane
          tab={
            <strong>
              <CreditCardOutlined style={styleForIcons} />
              Verify yourself (KYC)
            </strong>
          }
          key="3"
        >
          <VerifyAadhaarCard />
        </TabPane>

        <TabPane
          tab={
            <strong>
              <ShoppingOutlined style={styleForIcons} />
              Orders
            </strong>
          }
          key="4"
        >
          <Orders />
        </TabPane>

        <TabPane
          tab={
            <strong>
              <EnvironmentOutlined style={styleForIcons} />
              Manage Address
            </strong>
          }
          key="5"
        >
          <AddEditAddress />
        </TabPane>

        {/* <TabPane
          tab={
            <strong>
              <SettingOutlined style={styleForIcons} />
              Change Settings
            </strong>
          }
          key="6"
        >
          <ChangeSettings />
        </TabPane> */}
      </Tabs>
    </div>
  );
}
