import React, { useState } from "react";
import { UserOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Modal, Input, Tooltip, Form, message } from "antd";
import firebase from "./../../../firebase";

export function DisplayName() {
  const [update, setUpdate] = useState(false);
  const [visible, setVisible] = useState(true);
  const [form] = Form.useForm();
  return (
    <Modal
      title="Display name"
      centered
      visible={visible}
      closable={false}
      cancelButtonProps={{
        disabled: true
      }}
      onOk={e => {
        e.stopPropagation();
        form.validateFields().then(formValues => {
          // console.log(formValues.displayName);
          setUpdate(true);
          return fetch(`${window["apiURL"]}/users/create`, {
            method: "POST",
            body: JSON.stringify({ displayName: formValues.displayName }),
            headers: {
              "Content-Type": "application/json"
            }
          })
            .then(response => {
              if (response && response.success) {
                firebase
                  .auth()
                  .currentUser.getIdToken(true)
                  .then(() => {
                    setUpdate(false);
                    setVisible(false);
                    window.location.reload();
                  });
              } else {
                message.error("Unable to process your request.");
              }
              setUpdate(false);
            })
            .catch(e => {
              message.error("Unable to process your request.");
              setUpdate(false);
            });
        });
      }}
      confirmLoading={update}
    >
      <Form form={form} colon={false} name="display_name_form">
        <Form.Item
          name="displayName"
          required
          rules={[
            {
              required: true,
              message: "Please enter display name."
            }
          ]}
        >
          <Input
            placeholder="Enter your Display Name"
            allowClear
            prefix={<UserOutlined className="site-form-item-icon" />}
            suffix={
              <Tooltip title="Without entering display name you can't proceed further">
                <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
              </Tooltip>
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
