import { store } from "../../../store";
import { globalActions } from "../../landing/actions";
import { reportItemActions } from "./actions";

export function reportItem(reportItemInfo) {
  try {
    store.dispatch({
      type: reportItemActions.REPORT_ITEM_REQUEST,
    });
    return fetch(`${window["apiURL"]}/report/add`, {
      method: "POST",
      body: JSON.stringify(reportItemInfo),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (window.getValue(response, "success")) {
          store.dispatch({
            type: reportItemActions.REPORT_ITEM_SUCCESS,
            payload: window.getValue(response, "data"),
          });
        } else {
          store.dispatch({
            type: reportItemActions.REPORT_ITEM_ERROR,
            payload: window.getValue(response, "data.message"),
          });
          store.dispatch({
            type: globalActions.SET_ERROR,
            payload: window.getValue(response, "data.message"),
          });
        }
        
      })
      .catch((error) => {
        store.dispatch({
          type: reportItemActions.REPORT_ITEM_ERROR,
          payload: error.toString(),
        });
        store.dispatch({
          type: globalActions.SET_ERROR,
          payload: error.toString(),
        });
      });
  } catch (error) {
    store.dispatch({
      type: globalActions.SET_ERROR,
      payload: error.toString(),
    });
  }
}

