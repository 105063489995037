import React from "react";
import { Input } from "antd";
import { Grid } from "antd";
import { connectSearchBox } from "react-instantsearch-dom";
import { history } from "../../../utility";
import { useRouteMatch } from "react-router-dom";
const { Search } = Input;
const { useBreakpoint } = Grid;

const SearchBox = ({ currentRefinement, refine }) => {
  const screens = useBreakpoint();
  const match = useRouteMatch({
    path: "/",
    strict: true,
    sensitive: true,
  });
  return (
    <Search
      placeholder="Search an Item"
      enterButton={screens.xs ? '' : "Search"}
      className={screens.xs ? "search-custom" : ''}
      size="large"
      disabled={!(match && match.isExact)}
      onChange={(e) => {
        console.log("query", e.target.value);
        history.push("/");
        refine(e.target.value);
      }}
      allowClear
      defaultValue={currentRefinement}
    />
  );
};
const CustomSearchBox = connectSearchBox(SearchBox);
export default CustomSearchBox;
