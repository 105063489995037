import React from "react";
import { Link } from "react-router-dom";
import qs from "qs";

export const isImg = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?/;
export const getFooterItemsToRender = (item, i) => {
  let tag = item.name.indexOf("title") === 0 ? "h1" : "div";
  tag = item.href ? "a" : tag;
  let children =
    typeof item.children === "string" && item.children.match(isImg)
      ? React.createElement("img", { src: item.children, alt: "img" })
      : item.children;
  if(item.to) {
    return <Link key={item.children} to={`${item.to}`} >{item.children}</Link>
  }
  return React.createElement(tag, { key: i.toString(), ...item }, children);
};
export const getHeaderItemsToRender = (item, i) => {
  let children =
    typeof item.children === "string" && item.children.match(isImg)
      ? React.createElement("img", { src: item.children, alt: "img" })
      : item.children;
    return React.createElement(Link, { key: i.toString(), ...item }, children);
};

export const urlToSearchState = (location) => qs.parse(location.search.slice(1));

export const createURL = (state) => `?${qs.stringify(state)}`;

export const searchStateToUrl = (location, searchState) =>
  searchState ? `${location.pathname}${createURL(searchState)}` : "";