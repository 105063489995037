import { globalActions } from "../actions";
const initialState = {
  error: "",
};
export const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case globalActions.SET_ERROR:
      window.scrollTo(0, 0);
      return {
        ...state,
        error: action.payload,
      };
    case globalActions.CLEAR_ERROR:
      return {
        ...state,
        error: "",
      };
    case globalActions.SHOW_LOGIN_MODAL:
      return {
        ...state,
        showLoginModal: action.payload,
      };
    default:
      return state;
  }
};
