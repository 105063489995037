import { profileActions } from "../actions";

export const givenOnRentOrdersItem = (
  state = { items: [], anyMore: false },
  action
) => {
  switch (action.type) {
    case profileActions.ORDERS_GIVEN_ITEMS_LOADING:
      return {
        ...state, 
        loading: action.payload
      };
    case profileActions.ORDERS_GIVEN_ITEMS_LOADING_MORE:
      return {
        ...state,
        loadingMore: action.payload 
      };
    case profileActions.ORDERS_GIVEN_ITEMS_SUCCESS:
      let items = [];
      if(state.loadingMore){
        items = state.items.concat(action.payload); 
      } else {
        items = items.concat(action.payload);
      }
      return {
        ...state,
        loading: false,
        anyMore: Boolean(action.payload.length >= 8),
        loadingMore: false,
        items: items,
      };
    default:
      return state;
  }
};

export const takenOnRentOrdersItem = (
  state = { items: [], anyMore: false },
  action
) => {
  switch (action.type) {
    case profileActions.ORDERS_TAKEN_ITEMS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case profileActions.ORDERS_TAKEN_ITEMS_LOADING_MORE:
      return {
        ...state,
        loadingMore: action.payload
      };
    case profileActions.GET_ITEM_RATING_LOADING:
        return {
          ...state,
          ratingLoading: true,
        };
    case profileActions.GET_ITEM_RATING_SUCCESS:
          return {
            ...state,
            ratingLoading: false,
            ratings: action.payload,
          };
    case profileActions.GET_ITEM_RATING_FAILURE:
          return {
            ...state,
            ratingLoading: false, 
            ratings: null,
          };
    case profileActions.ORDERS_TAKEN_ITEMS_SUCCESS:
      let items = [];
      if(state.loadingMore){
        items = state.items.concat(action.payload); 
      } else {
        items = items.concat(action.payload);
      }
      return {
        ...state,
        loading: false,
        loadingMore: false,
        anyMore: Boolean(action.payload.length >= 8),
        items: items
      };
    default:
      return state;
  }
};
