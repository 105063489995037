import { profileActions } from "../actions";

export const myBookmarkItems = (
  state = { items: [], anyMore: false },
  action
) => {
  switch (action.type) {
    case profileActions.MY_BOOKMARK_ITEMS_LOADING:
      return {
        ...state,
        loading: action.payload,
        items: []
      };
    case profileActions.MY_BOOKMARK_ITEMS_LOADING_MORE:
      return {
        ...state,
        loadingMore: action.payload
      };
    case profileActions.MY_BOOKMARK_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        anyMore: Boolean(action.payload.length >= 8),
        items: state.items.concat(action.payload)
      };
    case profileActions.MY_BOOKMARK_ITEMS_UPDATING_DONE:
      const items = Array.from(state.items);
      items.forEach((item, index) => {
        if (item.id === action.payload.id) {
          items[index] = action.payload;
        }
      });
      return {
        ...state,
        items
      };

    default:
      return state;
  }
};
