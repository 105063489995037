import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "./pages/reducers";

const loggerMiddleware = createLogger();
const middlewares = [];
middlewares.push(thunkMiddleware);
if (process.env.NODE_ENV === "development") {
  middlewares.push(loggerMiddleware);
}
export const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(...middlewares),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
);
