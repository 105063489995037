import React, { useState } from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { history } from "../../../utility";

export function Share() {
  const size = 40;
  const [URL, setURL] = useState(window.location.href);
  const shapeRound = true;
  history.listen(() => {
    //console.log("window.location.href", window.location.href);
    setURL(window.location.href);
    window.document.title = window.location.href;
  });

  const shareBtnLayout = (
    <React.Fragment>
      <WhatsappShareButton url={URL}>
        <WhatsappIcon round={shapeRound} size={size} />
      </WhatsappShareButton>
      <TwitterShareButton url={URL}>
        <TwitterIcon round={shapeRound} size={size} />
      </TwitterShareButton>
      <FacebookShareButton url={URL}>
        <FacebookIcon round={shapeRound} size={size} />
      </FacebookShareButton>
    </React.Fragment>
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        background: "white",
        alignItems: "center",
      }}
    >
      {shareBtnLayout}
    </div>
  );
}
