import React from "react";
import { Card, Space, Grid } from "antd";
import Meta from "antd/lib/card/Meta";
import { Link } from "react-router-dom";
// import d from '../../assets/images/'
const { useBreakpoint } = Grid;

export function AboutUs() {
  const screens = useBreakpoint();
  return (
    <div className="about-us">
      <h2 style={{ margin: 20 }}>
        <b>About Us</b>
      </h2>
      <Space direction={screens.xs ? 'vertical' : "horizontal"}>
        <Card
          hoverable
          style={{ alignContent: "center", margin: 10 }}
          cover={
            <img
              alt="example"
              style={{ width: 240, alignContent: "center" }}
              src={require("../../assets/images/about-us.jpg")}
            />
          }
        >
          <Meta title="Luwak Tech" description="www.luwaktech.com" />
        </Card>
        <Space direction="vertical" style={{ padding: 10 }}>
          <h1>Welcome to RentoCircle</h1>
          <div>
            <strong>RentoCircle</strong> is your number one source for all
            things that you can lent stuff as well as borrow stuff. We're
            dedicated to giving you the very best of platform, with a focus on
            good quality products, dependability, customer service and
            uniqueness.
          </div>
          <div>
            Founded in 2020 by Four friends based in Pune with Mumbai touch, <strong>RentoCircle</strong> has
            just started few months back in a co-working space. When{" "}
            <strong>RentoCircle</strong> first started out, passion of all of us
            for helping others to get better quality, providing the best
            products drove us do intense research with late night discussions. It gave us
            the impetus to turn hard work and inspiration into to becoming a best
            online rental store. We have designed our platform in such a way that customers from all over India  
            can publish and consume items based on their locality as per their needs. and now We are
            thrilled to be a part of the quirky, eco-friendly, fair trade
            wing of the rent out industry. We hope you enjoy our products as
            much as we enjoy offering them to you.
            <br />
            <br />
            <div>
              If you have any questions or comments, please don't hesitate to
              &nbsp;<Link to="/contact">contact us</Link>.
            </div>
          </div>
          <div>
            <h4>Sincerely,</h4>
            <h4>RentoCircle Team</h4>
          </div>
        </Space>
      </Space>
    </div>
  );
}
