import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import * as firebaseui from "firebaseui";
// import * as firebase from 'firebase/app';
import { profileActions } from "./pages/profile";
import { store } from "./store";
import Cookies from "js-cookie";
import { logout } from "./utility";

// const config = {
//   apiKey: "AIzaSyDG42HBcPpC3awyOPYWm5BMx6PL5DZJHmk",
//   authDomain: "rentodev-b951a.firebaseapp.com",
//   projectId: "rentodev-b951a",
//   appId: "1:149233458785:web:0ca70aed75eb0156c31420",
//   measurementId: "G-K9TPXXJJ22",
// };
let config = {};
if (
  process.env.REACT_APP_ENV === undefined ||
  process.env.REACT_APP_ENV === "dev"
) {
  config = {
    apiKey: "AIzaSyC15MvFeBS4nQrHPNNPxqTwd1yEgiIUPhY",
    authDomain: "rento-dev-c0da8.firebaseapp.com",
    projectId: "rento-dev-c0da8",
    appId: "1:838207576238:web:ad74c7e728014b2184f53f",
    measurementId: "G-JCHJWY4ZYZ",
  };
} else {
  config = {
    apiKey: "AIzaSyDXEqjTj30c9DMeF1O76g-10jqjIi8pTDY",
    authDomain: "rento-prod-bb93f.firebaseapp.com",
    projectId: "rento-prod-bb93f",
    appId: "1:1019977833595:web:37abdba8d0a40f842a16de",
    measurementId: "G-SLJK926MFN",
  };
}

firebase.initializeApp(config);

export const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "/",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    {
      provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      requireDisplayName: true,
      defaultCountry: "IN",
      whitelistedCountries: ["IN"],
    },
  ],
  popupMode: true,

  credentialHelper: firebaseui.auth.CredentialHelper.NONE,
};

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    let forceRefresh = false;
    const refreshToken = () => {
      user
        .getIdTokenResult(forceRefresh)
        .then((tokenResult) => {
          Cookies.set("loggedIn", "true", {
            expires: new Date(tokenResult.expirationTime),
          });
          Cookies.set("__r__c__t", tokenResult.token, {
            expires: new Date(tokenResult.expirationTime),
          });
          // Cookies.set("displayName", user.displayName, {
          //   expires: new Date(tokenResult.expirationTime)
          // });
          setTimeout(() => {
            forceRefresh = true;
            refreshToken();
          }, new Date(tokenResult.expirationTime) - new Date());
        })
        .catch((error) => {
          console.error(error);
          logout();
        });
    };
    refreshToken();
    Cookies.set("displayName", user.displayName);
    store.dispatch({
      type: profileActions.LOGIN_SUCCESS,
      payload: user,
      displayName: user.displayName,
    });
  }
});

export default firebase;
