import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ color: "rgb(215, 50, 50)" }} spin />;

export function Loader(props) {
  return (
    <div>
      <Spin indicator={antIcon} size="large" tip="Please wait..." {...props} />
    </div>
  );
}
