import React, { useState } from "react";
import { Switch, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { togglePublishedItem } from "../";
export function CustomSwitch(props) {
  const [checked, setChecked] = useState(false);
  //console.log(props.status, props);
  return (
    <Switch
      loading={window.getValue(props, "record.status") === "updating"}
      checked={window.getValue(props, "status") === "active" || checked}
      onClick={(checked, e) => {
        e.stopPropagation();
        if (!checked) {
          Modal.confirm({
            title: "Are you sure unpublish this item?",
            icon: <ExclamationCircleOutlined />,
            content:
              "Once you unpublish item it wont be available for booking.",
            okText: "Yes",
            okType: "primary",
            cancelText: "No",
            onOk() {
              togglePublishedItem("inactive", window.getValue(props, "record"));
              setChecked(checked);
            },
            onCancel() {
              //console.log("Cancel");
            },
          });
        } else {
          togglePublishedItem("active", window.getValue(props, "record"));
          setChecked(checked);
        }
      }}
    />
  );
}
