import React, { Fragment } from "react";

import { Menu, Modal, Drawer, Divider, Button, Row, Col } from "antd";
import { FilterFilled } from "@ant-design/icons";
import { history } from "./../../../utility";
import { useSelector, useDispatch } from "react-redux";
import firebase from "../../../firebase";
import { uiConfig } from "./../../../firebase";

import { globalActions } from "../actions";
import { StyledFirebaseAuth } from "react-firebaseui";
import { Link, useRouteMatch } from "react-router-dom";
import { useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { publishActions } from "../../publish";
import { UserMenuOptions } from "./UserMenuOptions";
import { Filter } from ".";
import CustomSearchBox from "./CustomSearchBox";
import LocationContainer from "./LocationContainer";
import Logo from "../../../assets/images/Rento.png";

function MobileHeader() {
  const match = useRouteMatch({
    path: "/",
    strict: true,
    sensitive: true,
  });
  const [visible, setVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const { loggedIn, displayName, showLoginModal } = useSelector((state) => ({
    loggedIn: window.getValue(state, "profile.loggedIn"),
    displayName: window.getValue(state, "profile.displayName"),
    showLoginModal: window.getValue(state, "global.showLoginModal"),
  }));

  const [showFilter, setShowFilter] = useState(false);
  const onFilterClick = () => {
    //console.log("filter onFilterClick clicked");
    setShowFilter(!showFilter);
  };
  const onFilterClosed = () => {
    //console.log("filter onFilterClosed called");
    setShowFilter(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        boxShadow: "0 0 5px rgba(166,166,166,0.9)",
        top: 25,
        zIndex: 100,
        position: "sticky",
      }}
    >
      <div
        className="header-place-holder"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "14px",
          background: "#ffbc36",
          //boxShadow: "0 0 5px rgba(166,166,166,0.9)",
          top: 26,
          zIndex: 100,
          position: "sticky",
        }}
      >
        <div className="mobile-logo">
          <Link to="/">
            <img src={Logo} alt='RentoCircle'></img>
          </Link>
          <LocationContainer />
          {/* <Button
            size="middle"
            type="primary"
            style={{ marginLeft: 5 }}
            icon={<ReloadOutlined style={{ fontSize: 18 }} />}
            onClick={() => {
              message.info("Refreshing");
              props.setRefresh(false);
              setTimeout(() => props.setRefresh(true), 0);
            }}
          ></Button> */}
        </div>
        <MenuOutlined
          style={{ color: "black", fontSize: "28px" }}
          onClick={() => setVisible(true)}
        />

        <Drawer
          title={loggedIn ? displayName : "Login & List Item"}
          placement="right"
          onClose={() => setVisible(false)}
          visible={visible}
        >
          <Menu>
            <Menu.Item
              style={{
                background: "#000000d9",
                border: "#000000d9",
                color: "white",
              }}
              onClick={() => {
                if (loggedIn) {
                  firebase.analytics().logEvent("list_your_item_clicked");
                  history.push("/publish");
                  dispatch({
                    type: publishActions.CLEAR_FORM_DATA,
                  });
                } else {
                  setShowModal(true);
                }
                setVisible(false);
              }}
            >
              List your Item
            </Menu.Item>
            {!loggedIn ? (
              <Menu.Item
                style={{ border: "1px solid #47525d" }}
                onClick={() => {
                  setShowModal(true);
                  setVisible(false);
                }}
              >
                Login / Signup
              </Menu.Item>
            ) : null}
          </Menu>

          {loggedIn ? (
            <Divider orientation="left">Manage Account</Divider>
          ) : null}
          {loggedIn ? (
            <UserMenuOptions closeMobileDrawer={() => setVisible(false)} />
          ) : null}
        </Drawer>
        <Modal
          title={<strong>Login using Phone number</strong>}
          centered
          visible={showModal || Boolean(showLoginModal)}
          footer={null}
          onCancel={() => {
            setShowModal(false);
            dispatch({
              type: globalActions.SHOW_LOGIN_MODAL,
              payload: false,
            });
          }}
        >
          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
          />
        </Modal>
      </div>
      <Col>
        {match && match.isExact ? (
          <Row
            style={{
              display: "flex",
              flexFlow: "inherit",
              background: "#ffbc36",
              // border: "2px solid #0276fd",
            }}
            justify="start"
            align="middle"
          >
            <CustomSearchBox />
            <Button
              size="large"
              style={{ borderRadius: 0, margin: 1 }}
              icon={<FilterFilled style={{ color: "black" }} />}
              onClick={onFilterClick}
            />
          </Row>
        ) : (
          <Fragment />
        )}
        <Row style={{ visibility: showFilter ? "visible" : "hidden" }}>
          <Drawer
            placement={"top"}
            closable={true}
            onClose={onFilterClosed}
            visible={showFilter}
            getContainer={false}
            style={{ position: "absolute" }}
            height={300}
          >
            <Filter />
          </Drawer>
        </Row>
      </Col>
    </div>
  );
}

export default MobileHeader;
