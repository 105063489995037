import React, { useCallback } from "react";
import { message } from "antd";
import { Modal } from "antd";
import { Form, Input, Button } from "antd";
import { useParams } from "react-router-dom";
import firebase from "../../../firebase";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import { reportItem } from "./service";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const validateMessages = {
  required: "Reason is required!",
  types: {
    reason: "Please add a valid reason!",
  },
};

export function Report({ hideReportModal }) {
  const { itemId } = useParams();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const onFinish = (values) => {
    setConfirmLoading(true);
    reportItem({ ...values.report, itemId }).finally(() => {
      firebase.analytics().logEvent("report_sent", {
        report: values.report,
      });
      setConfirmLoading(false);
      handleCancel();
      message.success("Report sent!!!");
    });
  };

  const handleCancel = useCallback(() => {
    hideReportModal();
  }, [hideReportModal]);

  return (
    <Modal
      title="Report Item"
      visible={true}
      footer={null}
      style={{ width: "auto", height: "auto" }}
      maskClosable={false}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
    >
      <Form
        {...layout}
        name="nest-messages"
        size="large"
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <Form.Item
          name={["report", "reason"]}
          rules={[
            { required: true },
            {
              min: 3,
              message: "Please enter minimum 3 characters",
            },
          ]}
        >
          <Input allowClear placeholder="Reason" />
        </Form.Item>
        <Form.Item
          name={["report", "description"]}
          rules={[
            { required: true, message: "Please enter description" },
            { type: "string" },
            {
              min: 10,
              message: "Please enter description of minimum 10 characters",
            },
          ]}
        >
          <Input.TextArea
            allowClear
            placeholder="Write your description here"
            autoSize={{ minRows: 3 }}
          />
        </Form.Item>
        <Form.Item
          required
          {...{
            wrapperCol: {
              span: 24
            },
            labelCol: {
              span: 24
            }
          }}
          hasFeedback
          name="captcha"
          rules={[
            {
              required: true,
              message: "Please verify Captcha",
            },
          ]}
        >
          <ReCAPTCHA sitekey="6LdEV-wUAAAAAMmMrziXprUarEsoSor9YORKfAZW" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginTop: 25 }}
            loading={confirmLoading}
          >
            Report
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
