import React from "react";
import { Card, Grid, message } from "antd";
import Cookies from "js-cookie";
import { Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Loader } from "../../common";
import { profileActions } from "../actions";

const { useBreakpoint } = Grid;

export function ChangeProfilePicture({ photoURL }) {
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleChange = (info) => {
    if (window.getValue(info, "file.status") === "uploading") {
      setLoading(true);
      return;
    }
    if (window.getValue(info, "file.status") === "done") {
      dispatch({
        type: profileActions.UPDATE_PROFILE_IMAGE,
        payload: window.getValue(info, "file.response.data.url"),
      });
      setLoading(false);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed`);
      setLoading(false);
    }
  };

  return (
    <Loader spinning={loading}>
      <Card
        title="Current Profile Picture"
        style={
          screens.xs
            ? { textAlign: "center" }
            : { width: 300, textAlign: "center" }
        }
      >
        <img
          className="lazyload"
          src={
            photoURL && photoURL.length > 0
              ? photoURL
              : require("../../../assets/images/user_icon.png")
          }
          alt=""
          height="250"
          width="250"
          style={{
            objectFit: "contain",
          }}
        ></img>
        <Upload
          action={`${window["apiURL"]}/users/account/profile/image/upload`}
          headers={{
            Authorization: "Bearer " + Cookies.get("__r__c__t"),
          }}
          name="avatar"
          className="avatar-uploader"
          accept=".jpg,.png,.jpeg"
          showUploadList={false}
          onChange={handleChange}
        >
          <Button style={{ marginTop: 20 }}>
            <UploadOutlined />
            Change Profile Pic
          </Button>
        </Upload>
      </Card>
    </Loader>
  );
}
