import React from "react";
import {
  List,
  Card,
  Descriptions,
  Carousel,
  Skeleton,
  Grid,
  Tooltip,
} from "antd";
import { useSelector } from "react-redux";
import firebase from "../../../firebase";
import { history } from "../../../utility";
import Loadable from "react-loadable";
import { Loader, postItemConstants } from "../../common";
import defaultImg from "../../../assets/images/no-img-avail.png";

const { useBreakpoint } = Grid;

const CarouselArrows = Loadable({
  loader: () => import("./CarouselArrows"),
  render(loaded, props) {
    let CarouselArrows = loaded.CarouselArrows;
    return <CarouselArrows {...props} />;
  },
  loading() {
    return <Loader spinning />;
  },
});

const Bookmark = Loadable({
  loader: () => import("./Bookmark"),
  render(loaded, props) {
    let Bookmark = loaded.Bookmark;
    return <Bookmark {...props} />;
  },
  loading() {
    return <Loader spinning />;
  },
});

const ConnectedPagination = Loadable({
  loader: () => import("./ConnectedPagination"),
  loading() {
    return <Loader spinning />;
  },
});

export default function RecentlyPublishedItems() {
  const screens = useBreakpoint();
  const { loading, recentItems } = useSelector((state) => ({
    loading: window.getValue(state, "recentpublisheditems.loading"),
    recentItems: window.getValue(state, "recentpublisheditems.items"),
  }));
  return (
    <div
      className={screens.xs ? "recently-published-items-mobile" : "recently-published-items"}
      style={screens.xs ? { width: "90%", margin: "25px 5% 0px 5%" } : {}}
    >
      <Skeleton
        loading={loading}
        active
        paragraph={{
          rows: 15,
          width: 50,
        }}
      >
        <List
          // grid={{
          //   gutter: 20,
          //   column: 4,
          // }}
          grid={{
            gutter: 16,
            xs: 2,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 4,
            xxl: 3,
          }}
          dataSource={recentItems}
          renderItem={(item) => {
            return (
              <List.Item
                onClick={() => {
                  firebase.analytics().logEvent("landing_list_item_clicked", {
                    item: item.id,
                  });
                  history.push("/item/" + item.id);
                  window.scrollTo(0, 0);
                }}
              >
                <Card
                  hoverable
                  loading={false}
                  cover={
                    <Carousel
                      dots={false}
                      arrows={false}
                      nextArrow={<CarouselArrows type="next" />}
                      prevArrow={<CarouselArrows type="prev" />}
                    >
                      <div key="carousel-">
                        <div className="item-type">
                          {item.type === postItemConstants.SELL
                            ? "Sell"
                            : "Rent"}
                        </div>
                        {/* <Bookmark item={item} /> */}
                        <img
                          alt={"carousel-"}
                          onError={(ev) => {
                            ev.target.src = defaultImg;
                            ev.target.className = "img-rpi-error";
                          }}
                          data-sizes="auto"
                          className="lazyload img-rpi"
                          data-src={item.images && item.images[0]}
                          height={screens.xs ? 150 : 250}
                        />
                      </div>
                    </Carousel>
                  }
                >
                 <Card.Meta
                    description={
                      <React.Fragment>
                        <Descriptions
                          layout="horizontal"
                          colon={false}
                          size="small"
                          column={1}
                        >
                          <Descriptions.Item
                          className={screens.xs ? "card-item-name" : ""}
                            label={
                              screens.xs ? (
                                item.name && item.name.length > 14 ? 
                               (
                                  <div
                                    style={{
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {`${item.name.slice(0, 14)}...`}
                                  </div>
                              ) : (
                                <div
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item.name}
                                </div>
                              )
                              ) : (
                              item.name && item.name.length > 20 ? 
                               (
                                <Tooltip title={item.name} color={"black"}>
                                  <h3
                                    style={{
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {`${item.name.slice(0, 20)}...`}
                                  </h3>
                                </Tooltip>
                              ) : (
                                <h3
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item.name}
                                </h3>
                              )
                              )
                            }
                          >
                            <Bookmark item={item} />
                          </Descriptions.Item>
                          {item.type === postItemConstants.RENT ? (
                            <React.Fragment>
                              <Descriptions.Item className={screens.xs ? "card-item-price" : ""} label={screens.xs ? "Daily" : "Rental Daily"}>
                                <strong>
                                  Rs. {window.getDisplayPrice(item, "rent.day")}
                                </strong>
                              </Descriptions.Item>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <Descriptions.Item className={screens.xs ? "card-item-price" : ""} label={screens.xs ? "Price": "Selling Price"}>
                                <strong>
                                  Rs.{" "}
                                  {window.getDisplayPrice(
                                    item,
                                    "selling_price"
                                  )}
                                </strong>
                              </Descriptions.Item>
                            </React.Fragment>
                          )}
                        </Descriptions>
                      </React.Fragment>
                    }
                  />
                  {/* } */}
                </Card>
              </List.Item>
            );
          }}
        />
      </Skeleton>
      <ConnectedPagination />
    </div>
  );
}
