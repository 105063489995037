import { store } from "../../../store";
import { globalActions } from "../../landing/actions";
import { feedbackActions } from "./actions";

export const sendFeedback = (feedback) => {
  store.dispatch({
    type: feedbackActions.FEEDBACK_REQUEST,
  });
  return fetch(`${window["apiURL"]}/feedback/add`, {
    method: "POST",
    body: JSON.stringify(feedback),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response && window.getValue(response, "success")) {
        store.dispatch({
          type: feedbackActions.FEEDBACK_SUCCESS,
          payload: window.getValue(response, "data.feedback"),
        });
      } else {
        store.dispatch({
            type: globalActions.SET_ERROR,
            payload: "Unable to send feedback. Please try again"
          });
      }
    })
    .catch((e) => {
      store.dispatch({
        type: globalActions.SET_ERROR,
        payload: "Unable to bookmark current item",
      });
      store.dispatch({
        type: feedbackActions.FEEDBACK_ERROR,
        payload: e,
      });
    });
};
