import React, { useState } from "react";
import {
  Table,
  Button,
  Skeleton,
  Modal,
  Form,
  Row,
  Col,
  Select,
  Grid,
  message,
} from "antd";
import { EditFilled } from "@ant-design/icons";
import Loadable from "react-loadable";
import { saveAddress } from "../../publish";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { myAddresses } from "../";
import { Loader } from "../../common";
import { profileActions } from "../actions";
import { useEffect } from "react";

const { useBreakpoint } = Grid;

const DeleteAddress = Loadable({
  loader: () => import("./DeleteAddress"),
  render(loaded, props) {
    let DeleteAddress = loaded.DeleteAddress;
    return <DeleteAddress {...props} />;
  },
  loading() {
    return <Loader spinning />;
  },
});

const LocationSearchInput = Loadable({
  loader: () => import("../../publish/components/LocationSearch"),
  render(loaded, props) {
    let LocationSearchInput = loaded.LocationSearchInput;
    return <LocationSearchInput {...props} />;
  },
  loading() {
    return <Loader spinning />;
  },
});

const addressType = [
  <Select.Option key={"home"} value={"Home"}>
    Home
  </Select.Option>,
  <Select.Option key={"office"} value={"office"}>
    Office
  </Select.Option>,
  <Select.Option key={"other"} value={"Other"}>
    Other
  </Select.Option>,
];
export function AddEditAddress() {
  const screens = useBreakpoint();
  const { loading, addresses, loadingMore, anyMore } = useSelector(
    (state) => ({
      loading: window.getValue(state, "myaddresses.loading"),
      addresses: window.getValue(state, "myaddresses.addresses"),
      loadingMore: window.getValue(state, "myaddresses.loadingMore"),
      anyMore: window.getValue(state, "myaddresses.anyMore"),
    }),
    shallowEqual
  );
  // console.log("addressesaddresses", addresses);
  const [addNewAddress, setAddNewAddress] = useState(false);
  const [currentRowInEditing, setCurrentRowInEditing] = useState(null);
  const [columns] = useState([
    {
      title: "Address",
      dataIndex: "formatted_address",
    },
    {
      title: "Label",
      dataIndex: "title",
      align: "center",
    },
    {
      title: "Delete",
      dataIndex: "",
      align: "center",
      render: (row) => <DeleteAddress row={row} />,
    },
    {
      title: "Edit",
      dataIndex: "",
      align: "center",
      render: (row) => {
        return (
          <EditFilled
            onClick={() => {
              // console.log(currentRowInEditing, row);
              setCurrentRowInEditing(row);
              form.setFieldsValue({
                new_address: {
                  resultAddresstoSave: {
                    ...row,
                    formattedAddress: row.formatted_address,
                    location: row.map_location,
                    postalCode: row.pincode,
                  },
                },
              });
              setAddNewAddress(true);
            }}
          />
        );
      },
    },
  ]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [addAddressLoader, setAddAddressLoader] = useState(false);
  useEffect(() => {
    myAddresses();
  }, []);
  return (
    <React.Fragment>
      <Button
        onClick={() => {
          setAddNewAddress(true);
        }}
        type="primary"
        style={{
          marginBottom: 16,
          marginLeft: 16,
        }}
      >
        Add new Address
      </Button>
      <Modal
        title={currentRowInEditing ? "Edit Address" : "Add New Address"}
        centered
        visible={addNewAddress}
        okText={currentRowInEditing ? "Update" : "Save"}
        onOk={() => {
          if (addAddressLoader) {
            return;
          }
          form.validateFields().then((formValues) => {
            if (form.isFieldsTouched()) {
              const address = formValues.new_address.resultAddresstoSave;
              address.type = formValues.type;
              if (currentRowInEditing) {
                address.id = currentRowInEditing.id;
              }
              setAddAddressLoader(true);
              saveAddress(address, Boolean(currentRowInEditing))
                .then((response) => {
                  if (response && window.getValue(response, "success")) {
                    //myAddresses();

                    dispatch({
                      type: profileActions.MY_ADDRESSES_ADD_OR_EDIT_DONE,
                      payload: {
                        edit: Boolean(currentRowInEditing),
                        address: window.getValue(response, "data.address"),
                        id: window.getValue(response, "data.address.id"),
                      },
                    });
                    setAddNewAddress(false);
                    if (currentRowInEditing) {
                      setCurrentRowInEditing(null);
                    }
                  } else {
                    message.error(window.getValue(response, "data.message"));
                  }
                  setAddAddressLoader(false);
                })
                .catch((e) => {
                  message.error("Unable to save new address.");
                  setAddAddressLoader(false);
                });
            } else {
              message.error("Please make some changes to your fields.");
            }
          });
        }}
        onCancel={() => {
          setCurrentRowInEditing(null);
          setAddNewAddress(false);
        }}
      >
        <Loader
          spinning={addAddressLoader}
          tip=""
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <Form form={form} colon={false} size="large" name="add_new_address">
            <Row></Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Enter Address"
                  required
                  name="new_address"
                  key={Math.random()}
                  rules={[
                    {
                      required: true,
                      message: currentRowInEditing
                        ? "Please enter new address"
                        : "Please enter address.",
                    },
                  ]}
                >
                  <LocationSearchInput
                    placeHolder="Search address..."
                    address={
                      currentRowInEditing
                        ? currentRowInEditing.formatted_address
                        : ""
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  {...{
                    wrapperCol: screens.xs
                      ? {
                          offset: 0,
                        }
                      : {
                          offset: 3,
                        },
                  }}
                  name="type"
                  label="Type&nbsp;"
                  required
                  key={Math.random()}
                  rules={[
                    {
                      required: true,
                      message: "Please select address type.",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Address Type"
                    key={Math.random()}
                    defaultValue={
                      currentRowInEditing ? currentRowInEditing.title : ""
                    }
                  >
                    {addressType}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Loader>
      </Modal>
      <Skeleton loading={loading} active paragraph={{ rows: 15, width: 50 }}>
        <Table
          size="large"
          bordered
          columns={columns}
          dataSource={addresses}
          pagination={false}
          rowKey={"id"}
          tableLayout={"auto"}
          footer={() =>
            anyMore ? (
              loadingMore ? (
                <Loader
                  spinning={loadingMore}
                  tip=""
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                />
              ) : (
                <Button
                  type="primary"
                  onClick={() => {
                    myAddresses(addresses[addresses.length - 1].id);
                  }}
                  size="middle"
                >
                  Load More
                </Button>
              )
            ) : (
              ""
            )
          }
        />
      </Skeleton>
    </React.Fragment>
  );
}
