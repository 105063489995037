import { profileActions } from "../actions";

export const myPublishedItems = (
  state = { items: [], anyMore: true },
  action
) => {
  switch (action.type) {
    case profileActions.MY_PUBLISHED_ITEMS_LOADING:
      return {
        ...state,
        loading: action.payload,
        items: [],
      };
    case profileActions.MY_PUBLISHED_ITEMS_LOADING_MORE:
      return {
        ...state,
        loadingMore: action.payload,
      };
    case profileActions.MY_PUBLISHED_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        anyMore: Boolean(action.payload.length >= 8),
        items: state.items.concat(action.payload),
      };
    case profileActions.MY_PUBLISHED_ITEM_UPDATE_DONE:
      const items = Array.from(state.items);
      items.forEach((item, index) => {
        if (item.id === action.payload.id) {
          items[index] = action.payload;
        }
      });
      return {
        ...state,
        items,
      };

    case profileActions.MY_PUBLISHED_ITEM_UNPUBLISH_UPDATING:
      const tempItems = Array.from(state.items);
      tempItems.forEach((item, index) => {
        if (item.id === action.payload.id) {
          tempItems[index].status = action.payload.status;
        }
      });
      return {
        ...state,
        items: tempItems,
      };

    default:
      return state;
  }
};
