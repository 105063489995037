import React, { useState } from "react";
import { HeartOutlined, HeartFilled, LoadingOutlined } from "@ant-design/icons";
import firebase from "../../../firebase";
import { bookmarkItem, unBookmarkItem } from "../";
import { useSelector, useDispatch } from "react-redux";
import { globalActions } from "../actions";
import { message } from "antd";

const style = { color: "rgb(215, 50, 50)", fontSize: 20 };
export function Bookmark(props) {
  const { item } = props;
  const dispatch = useDispatch();
  const uuid = useSelector(
    (state) =>
      window.getValue(state, "profile") &&
      window.getValue(state, "profile.user") &&
      window.getValue(state, "profile.user.uid")
  );

  const bookmarked =
    window.getValue(item, "bookmarked_by") &&
    item.bookmarked_by.length > 0 &&
    item.bookmarked_by.indexOf(uuid) !== -1;
  const [update, setUpdate] = useState(false);
  return update ? (
    <LoadingOutlined />
  ) : bookmarked ? (
    <HeartFilled
      style={style}
      onClick={(e) => {
        e.stopPropagation();
        setUpdate(true);
        unBookmarkItem(window.getValue(item, "id"), uuid).finally(() => {
          firebase.analytics().logEvent("new_unbookmark_item_clicked", {
            itemId: item.id,
          });
          setUpdate(false);
          message.success("Removed bookmarked Item");
        });
      }}
    />
  ) : (
    <HeartOutlined
      style={style}
      onClick={(e) => {
        e.stopPropagation();
        if (uuid) {
          setUpdate(true);
          bookmarkItem(window.getValue(item, "id"), uuid).finally(() => {
            firebase.analytics().logEvent("new_bookmark_item_clicked", {
              itemId: item.id,
            });
            setUpdate(false);
            message.success("Item is bookmarked");
          });
          // setBookmarked(true);
        } else {
          dispatch({
            type: globalActions.SHOW_LOGIN_MODAL,
            payload: true,
          });
        }
      }}
    />
  );
}
