import Cookies from "js-cookie";
import { logout } from "./logout";
import { store } from "../store";
import { globalActions } from "../pages/landing";
import { message } from "antd";
export const fetch = ((originalFetch) => {
  message.info('Loading...')
  return (...args) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Cookies.get("__r__c__t"),
    };
    args[1] = args[1] ? { ...args[1] } : {};
    if (!args[1].headers) {
      args[1] = {
        ...args[1],
        headers,
      };
    }
    args[1].headers["Authorization"] = "Bearer " + Cookies.get("__r__c__t");
    const result = originalFetch.apply(this, args);
    
    return result
      .then((response) => {
        if (response.status === 403) {
          logout();
        }
        return response.json ? response.json() : response;
      })
      .then((response) => {
        const errorMessage = window.getValue(response, "data.message");
        const success = window.getValue(response, "success");
        if (errorMessage && !success) {
          store.dispatch({
            type: globalActions.SET_ERROR,
            payload: errorMessage,
          });
        } else {
          store.dispatch({
            type: globalActions.CLEAR_ERROR,
          });
        }

        return response;
      })
      .catch((e) => {
        console.error("Error in the common fetch", e);
        console.error(e);
      });
  };
})(window.fetch);

window["apiURL"] = process.env.REACT_APP_API_URL_BASE;
