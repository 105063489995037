import React, { useEffect } from "react";
import algoliasearch from "algoliasearch";
import { InstantSearch, Configure } from "react-instantsearch-dom";
import { useLocation } from "react-router-dom";
import { isEqual } from "lodash";
import { recentlyPublishedItemsActions } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Landing from "./Landing";
import { createURL, searchStateToUrl, urlToSearchState } from "../../../utility";

const searchClient = algoliasearch(
  process.env.REACT_APP_SEARCH_CLIENT,
  process.env.REACT_APP_SEARCH_CLIENT_API_KEY
);

const DEBOUNCE_TIME = 0;

function AlgoliaInstantSearch() {
  const location = useLocation();
  const [searchState, setSearchState] = React.useState(
    urlToSearchState(location)
  );
  const setStateId = React.useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  const { hitsPerPage, latlng, refresh } = useSelector(
    (state) => ({
      hitsPerPage:
        window.getValue(state, "recentpublisheditems.hitsPerPage") || "8",
      latlng: window.getValue(state, "recentpublisheditems.latlng") || {},
      refresh: window.getValue(state, "recentpublisheditems.refresh") || {},
    }),
    isEqual
  );

  // state management for url
  useEffect(() => {
    const nextSearchState = urlToSearchState(location);
    const deepCompare = isEqual(searchState, nextSearchState);
    if (!deepCompare) {
      setSearchState(nextSearchState);
    }
  }, [location, searchState]);

  useEffect(() => {
    console.log('useEffect 1234 called');
    if (window.getValue(searchState, "configure.aroundLatLng")) {
      dispatch({
        type: recentlyPublishedItemsActions.SET_SEARCH_LAT_LNG,
        payload: {
          ...searchState.configure.aroundLatLng,
          city: searchState.city,
        },
      });
    } else {
      if (
        localStorage.getItem("search-city") &&
        localStorage.getItem("search-city").length > 0
      ) {
        dispatch({
          type: recentlyPublishedItemsActions.SET_SEARCH_LAT_LNG,
          payload: JSON.parse(localStorage.getItem("search-city")),
        });
      }
    }

    if (window.getValue(searchState, "configure.hitsPerPage")) {
      dispatch({
        type: recentlyPublishedItemsActions.SET_ITEMS_PER_PAGE,
        payload: searchState.configure.hitsPerPage,
      });
    }
  }, []);

  function onSearchStateChange(nextSearchState) {
    clearTimeout(setStateId.current);

    if (latlng.city) {
      nextSearchState = {
        ...nextSearchState,

        city: latlng.city,
      };
    }

    setStateId.current = setTimeout(() => {
      history.replace(
        searchStateToUrl(location, nextSearchState),
        nextSearchState
      );
    }, DEBOUNCE_TIME);
    setSearchState(nextSearchState);
  }

  return (
    <InstantSearch
      indexName="items"
      searchClient={searchClient}
      refresh={refresh}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
    >
      <Configure
        hitsPerPage={hitsPerPage}
        distinct={
          searchState && searchState.configure && searchState.configure.distinct
        }
        aroundLatLng={
          (latlng && latlng.lat
            ? `${latlng.lat}, ${latlng.lng}`
            : searchState &&
              searchState.configure &&
              searchState.configure.aroundLatLng) || "1,1"
        }
        aroundRadius="all"
      />
      <Landing />
    </InstantSearch>
  );
}
export default AlgoliaInstantSearch;
