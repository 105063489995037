import React from "react";
import { Modal, Divider, Tag, Tooltip } from "antd";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import { colors } from "../../common";
import UploadFiles from "./UploadFiles";
export function ConfirmPublishOrderModal({
  readyToPostOrder,
  editOrder,
  hideConfirmDialog,
}) {
  const form1Values = useSelector((state) =>
    window.getValue(state, "publish.STEP_1_FORM_DATA")
  );
  const form2Values = useSelector((state) =>
    window.getValue(state, "publish.STEP_2_FORM_DATA")
  );
  const form3Values = useSelector((state) =>
    window.getValue(state, "publish.STEP_3_FORM_DATA")
  );

  const handlePublish = (e) => {
    readyToPostOrder();
  };

  const handleEdit = (e) => {
    editOrder();
  };

  const nameLayout = {
    xs: { span: 10 },
    lg: { span: 8 },
  };

  const valueLayout = {
    xs: { span: 14 },
    lg: { span: 16 },
  };

  return (
    <Modal
      title={
        <h2
          style={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Review Item Details
        </h2>
      }
      visible={true}
      afterClose={hideConfirmDialog}
      onOk={handlePublish}
      onCancel={handleEdit}
      closable={true}
      okText="Publish Now"
      cancelText="Edit"
      zIndex={2147483647}
      width={"90%"}
      bodyStyle={{
        height: "500px",
        overflow: "scroll",
      }}
    >
      <Row>
        <Col {...nameLayout}>Product Name:</Col>
        <Col {...valueLayout}>
          <h3>{window.getValue(form1Values, "product_name")}</h3>
        </Col>
      </Row>
      <Row>
        <Col {...nameLayout}>Category:</Col>
        <Col {...valueLayout}>
          <h3>{window.getValue(form1Values, "category")}</h3>
        </Col>
      </Row>
      <Row>
        <Col {...nameLayout}>Sub Category:</Col>
        <Col {...valueLayout}>
          <h3>{window.getValue(form1Values, "sub-category")}</h3>
        </Col>
      </Row>
      <Row>
        <Col {...nameLayout}>Description:</Col>
        <Col {...valueLayout}>
          <h3>{window.getValue(form1Values, "description")}</h3>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col {...nameLayout}>Tags:</Col>
        <Col {...valueLayout} style={{ lineHeight: 3 }}>
          {window.getValue(form1Values, "tags") &&
            form1Values.tags.map((tag, index) => {
              const isLongTag = tag.length > 20;
              const tagElem = (
                <Tag
                  key={tag + index}
                  closable={false}
                  onClose={false}
                  style={{ padding: 8 }}
                  color={index > 4 ? colors[index % 4] : colors[index]}
                >
                  {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                </Tag>
              );
              return isLongTag ? (
                <Tooltip title={tag} key={tag + index}>
                  {tagElem}
                </Tooltip>
              ) : (
                tagElem
              );
            })}
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col {...nameLayout}>Product Images:</Col>
        <Col {...valueLayout}>
          {window.getValue(form1Values, "fileList") && (
            <UploadFiles
              onChange={() => {}}
              showProps={{
                showPreviewIcon: false,
                showRemoveIcon: false,
              }}
              hideUploadBtn={true}
              fileList={form1Values.fileList}
              isDisabled={true}
            />
          )}
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col {...nameLayout}>Market Price:</Col>
        <Col {...valueLayout}>
          <h3>Rs. {window.getDisplayPrice(form2Values, "market_price")}</h3>
        </Col>
      </Row>
      <Row>
        <Col {...nameLayout}>Deposit:</Col>
        <Col {...valueLayout}>
          <h3>Rs. {window.getDisplayPrice(form2Values, "deposit")}</h3>
        </Col>
      </Row>
      <Row>
        <Col {...nameLayout}>Rent Per Day:</Col>
        <Col {...valueLayout}>
          <h3>Rs. {window.getDisplayPrice(form2Values, "rent_per_day")}</h3>
        </Col>
      </Row>
      <Row>
        <Col {...nameLayout}>Rent Per Week:</Col>
        <Col {...valueLayout}>
          <h3>Rs. {window.getDisplayPrice(form2Values, "rent_per_week")}</h3>
        </Col>
      </Row>
      <Row>
        <Col {...nameLayout}>Rent Per Month:</Col>
        <Col {...valueLayout}>
          <h3>Rs. {window.getValue(form2Values, "rent_per_month")}</h3>
        </Col>
      </Row>
      <Row>
        <Col {...nameLayout}>Age Of Item:</Col>
        <Col {...valueLayout}>
          <h3>
            {window.getValue(form2Values, "years")}{" "}
            {window.getValue(form2Values, "months")}
          </h3>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col {...nameLayout}>Mobile No:</Col>
        <Col {...valueLayout}>
          <h3>{window.getValue(form3Values, "mobile_no")}</h3>
        </Col>
      </Row>
      <Row>
        <Col {...nameLayout}>PickUp Address:</Col>
        <Col {...valueLayout}>
          <h3>
            {window.getValue(
              form3Values,
              "pick_up_address.resultAddresstoSave.formatted_address"
            )}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col {...nameLayout}>Drop Address:</Col>
        <Col {...valueLayout}>
          <h3>
            {window.getValue(form3Values, "drop_address.sameAsPickUp")
              ? "Same As Above"
              : window.getValue(
                  form3Values,
                  "drop_address.resultAddresstoSave.formattedAddress"
                )}
          </h3>
        </Col>
      </Row>
    </Modal>
  );
}
