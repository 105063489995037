import { Menu } from "antd";
import React, { useCallback } from "react";
import { history, logout } from "../../../utility";
import {
  BookOutlined,
  FileProtectOutlined,
  CreditCardOutlined,
  ShoppingOutlined,
  EnvironmentOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

export function UserMenuOptions(props) {
  const closeMobileDrawer = useCallback(() => {
    if (props.closeMobileDrawer) {
      props.closeMobileDrawer();
    }
  }, [props]);
  return (
    <Menu
      onClick={closeMobileDrawer}
      style={{
        fontWeight: "600",
        boxShadow: props.closeMobileDrawer
          ? "none:"
          : "rgb(0 0 0 / 47%) 0px 28px 41px",
        borderRight: props.closeMobileDrawer ? "none" : "",
      }}
    >
      <Menu.Item
        key="1"
        onClick={() => {
          history.push("/profile/1");
        }}
      >
        <span>
          <FileProtectOutlined />
          My Items
        </span>
      </Menu.Item>

      <Menu.Item
        key="2"
        onClick={() => {
          history.push("/profile/2");
        }}
      >
        <span>
          <BookOutlined />
          Bookmark Items
        </span>
      </Menu.Item>

      <Menu.Item
        key="3"
        onClick={() => {
          history.push("/profile/3");
        }}
      >
        <span>
          <CreditCardOutlined />
          Verify yourself (KYC)
        </span>
      </Menu.Item>

      <Menu.Item
        key="4"
        onClick={() => {
          history.push("/profile/4");
        }}
      >
        <span>
          <ShoppingOutlined />
          Orders
        </span>
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item
        key="5"
        onClick={() => {
          history.push("/profile/5");
        }}
      >
        <span>
          <EnvironmentOutlined />
          Manage Address
        </span>
      </Menu.Item>
      <Menu.Divider />

      {/* <Menu.Item
        key="6"
        onClick={() => {
          history.push("/profile/6");
        }}
      >
        <span>
          <SettingOutlined />
          Change Settings
        </span>
      </Menu.Item>

      <Menu.Divider /> */}

      <Menu.Item key="7" onClick={() => logout()}>
        <span>
          <LogoutOutlined />
          Log out
        </span>
      </Menu.Item>
    </Menu>
  );
}
