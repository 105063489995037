import React from "react";
import {
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  TwitterSquareFilled,
} from "@ant-design/icons";

export const footerData = (isMobile) => {
  return {
    wrapper: { className: "home-page-wrapper footer1-wrapper" },
    OverPack: { className: "footer1", playScale: 0.2 },
    block: {
      className: "home-page",
      gutter: 0,
      children: [
        {
          name: "block0",
          xs: 24,
          md: 6,
          className: "block",
          title: {
            className: "logo",
            children: "RENTOCIRCLE",
          },
          childWrapper: {
            className: "block",
            children: [
              { name: "link0", to: "/about-us", children: "About Us" },
              { name: "link2", to: "/contact", children: "Contact" },
            ],
          },
        },
        {
          name: "block1",
          xs: 24,
          md: 6,
          className: "block",
          title: { children: "INFORMATION" },
          childWrapper: {
            children: [{ name: "link1", to: "/faq", children: "FAQ" }],
          },
        },
        {
          name: "block2",
          xs: 24,
          md: 6,
          className: "block",
          title: { children: "POLICIES" },
          childWrapper: {
            children: [
              {
                href: process.env.REACT_APP_PRIVATE_POLICIES_URL,
                name: "PrivacyPolicy",
                children: "Privacy Policy",
                target: "_blank",
                rel: "noopener noreferrer",
              },
              {
                href: process.env.REACT_APP_TERMS_AND_CONDITIONS_URL,
                name: "RentalTerms&Conditions",
                children: "Rental Terms & Conditions",
                target: "_blank",
                rel: "noopener noreferrer",
              },
            ],
          },
        },
        {
          name: "block3",
          xs: 24,
          md: 6,
          className: "block",
          title: { children: "NEED HELP?" },
          childWrapper: {
            children: [
              {
                href: "tel:8484988851",
                name: "HelpNumber",
                children: "Dial 8484988851",
              },
            ],
          },
        },
      ],
    },
    copyrightWrapper: { className: "copyright-wrapper" },
    copyrightPage: { className: "home-page" },
    copyright: {
      className: "copyright",
      children: (
        <div>
          <span>
            ©2020 by{" "}
            <a
              href="https://www.luwaktech.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>
                <u style={{ color: "white" }}>Luwak Technologies</u>
              </b>
            </a>{" "}
            All Rights Reserved
          </span>
          <div className={isMobile ? "mobile-social-footer" : ""}>
            <a
              href={process.env.REACT_APP_FACEBOOK_PAGE}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookFilled style={{ fontSize: 25, color: "#ffffff" }} />
            </a>
            <a
              href={process.env.REACT_APP_INSTAGRAM_PAGE}
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramFilled style={{ fontSize: 25, color: "#ffffff" }} />
            </a>
            <a
              href={process.env.REACT_APP_TWITTER_PAGE}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterSquareFilled style={{ fontSize: 25, color: "#ffffff" }} />
            </a>
            <a
              href={process.env.REACT_APP_LINKEDIN_PAGE}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedinFilled style={{ fontSize: 25, color: "#ffffff" }} />
            </a>
          </div>
        </div>
      ),
    },
  };
};
