import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { Input, AutoComplete, Button } from "antd";
import { useSelector, shallowEqual } from "react-redux";
import { myAddresses } from "../../profile";
import { Loader } from "../../common";
import { HistoryOutlined } from "@ant-design/icons";
export function LocationSearchInput(props) {
  let propsAddress = "";
  const { loading, addresses, loadingMore, anyMore } = useSelector(
    (state) => ({
      // @ts-ignore
      loading: window.getValue(state, "myaddresses.loading"),
      // @ts-ignore
      addresses: window.getValue(state, "myaddresses.addresses"),
      // @ts-ignore
      loadingMore: window.getValue(state, "myaddresses.loadingMore"),
      // @ts-ignore
      anyMore: window.getValue(state, "myaddresses.anyMore"),
    }),
    shallowEqual
  );

  if (loading === undefined) {
    myAddresses();
  }
  if (props.address) {
    propsAddress = props.address;
  } else if (props.address_step3 && props.address_step3.resultAddresstoSave) {
    propsAddress = props.address_step3.resultAddresstoSave.formatted_address;
  }
  const [address, setAddress] = useState(propsAddress);
  const handleChange = (address) => {
    if (props.onChange) {
      props.onChange(address);
    }
    setAddress(address);
  };
  const handleSelect = (address) => {
    const resultAddresstoSave = {};
    geocodeByAddress(address)
      .then((results) => {
        const lastIndex = results.length - 1;
        resultAddresstoSave.formatted_address =
          results[lastIndex].formatted_address;
        resultAddresstoSave["lat"] = results[lastIndex].geometry.location.lat();
        resultAddresstoSave["long"] = results[
          lastIndex
        ].geometry.location.lng();
        resultAddresstoSave.placeId = results[lastIndex].place_id;
        if (props.onChange) {
          props.onChange({
            resultAddresstoSave,
          });
        }
        if (props.selectedAddressInfo) {
          props.selectedAddressInfo({
            resultAddresstoSave,
          });
        }
      })
      .catch((error) => console.error("Error", error));
  };
  const onError = (status, clearSuggestions) => {
    //console.log("Google Maps API returned error with status: ", status);
    clearSuggestions();
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      onError={onError}
      highlightFirstSuggestion
      shouldFetchSuggestions={address.length > 2}
      searchOptions={{
        componentRestrictions: {
          country: "IN",
        },
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        let options = [];

        //console.log("I m here", suggestions);
        options = loading
          ? [
              {
                value: "",
                label: <div style={{ padding: 10 }}>Loading...</div>,
              },
            ]
          : suggestions.map((suggestion) => {
              return {
                value: suggestion.description,
                label: (
                  <div
                    {...getSuggestionItemProps(suggestion)}
                    style={{ padding: 10 }}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                ),
              };
            });
        if (props.placeHolder && props.placeHolder.indexOf("drop") !== -1) {
          options.push({
            value: "Same As Pick Up Address",
            label: "Same As Pick Up Address",
            address: {},
            sameAsPickUp: true,
          });
        }
        addresses.forEach((address, index) => {
          options.push({
            value: address.formatted_address + " " + index,
            label: (
              <React.Fragment>
                <HistoryOutlined style={{ padding: "7px", color: "#696c70" }} />
                <span>{address.formatted_address}</span>
              </React.Fragment>
            ),
            address: address,
          });
        });
        if (anyMore) {
          options.push({
            value: Math.random() + "",
            label: anyMore ? (
              loadingMore ? (
                <Loader
                  spinning={loadingMore}
                  tip=""
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                />
              ) : (
                <Button
                  type="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    myAddresses(addresses[addresses.length - 1].id);
                  }}
                  size="middle"
                >
                  Load More
                </Button>
              )
            ) : (
              ""
            ),
          });
        }

        return (
          <AutoComplete
            options={options}
            defaultValue={address}
            disabled={Boolean(props.disabled)}
            // dropdownMatchSelectWidth={500}
            // @ts-ignore
            onSelect={(value, optionData) => {
              if (props.onChange && optionData.address) {
                if (optionData.sameAsPickUp) {
                  props.onChange({ sameAsPickUp: true });
                } else {
                  props.onChange({
                    resultAddresstoSave: {
                      ...optionData.address,
                      address_id: optionData.address.id,
                      location: optionData.address.map_location,
                      formattedAddress: optionData.address.formatted_address,
                      postalCode: optionData.address.pincode,
                    },
                  });
                }
              }
            }}
          >
            <Input.Search
              allowClear
              size="large"
              {...getInputProps({
                placeholder: props.placeHolder,
              })}
            />
          </AutoComplete>
        );
      }}
    </PlacesAutocomplete>
  );
}
