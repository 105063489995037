import React from "react";
import { Form, Select, InputNumber, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

// initialization

const formItemLayout = {
  labelCol: {
    xs: {
      span: 6,
      push: 3,
    },
    md: {
      push: 0,
      offset: 2,
      span: 8,
    },
    lg: {
      offset: 1,
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 4,
    },
    md: {
      span: 12,
    },
    lg: {
      span: 6,
      push: 1,
    },
  },
};

const years = [];
for (let i = 0; i < 100; i++) {
  years.push(
    <Select.Option key={i} value={i + " years"}>
      {i + (i === 1 ? " year" : " years")}
    </Select.Option>
  );
}
const months = [];
for (let i = 1; i < 12; i++) {
  months.push(
    <Select.Option key={i} value={i + " months"}>
      {i + (i === 1 ? " month" : " months")}
    </Select.Option>
  );
}
const gender = [
  <Select.Option key={"men"} value={"Men"}>
    Men
  </Select.Option>,
  <Select.Option key={"women"} value={"Women"}>
    Women
  </Select.Option>,
  <Select.Option key={"both"} value={"Both"}>
    Both
  </Select.Option>,
];

function SellItemStep2(props) {
  const [form] = Form.useForm();
  const formData = useSelector((state) =>
    window.getValue(state, "publish.STEP_2_FORM_DATA")
  );
  if (props.setFormReference) {
    props.setFormReference(form);
  }

  return (
    <Form
      form={form}
      colon={false}
      size="large"
      name="step2"
      initialValues={formData}
      scrollToFirstError
      {...formItemLayout}
    >
      <Form.Item
        name="selling_price"
        label={
          <React.Fragment>
            <span>SELLING PRICE &nbsp;</span>
            <Tooltip
              placement="rightTop"
              title="Enter the sell price of your Product. For e.g: New Camera is 20k"
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </React.Fragment>
        }
        required
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please enter selling price of the Item.",
          },
        ]}
      >
        <InputNumber min={1} max={10000000} />
      </Form.Item>

      <Form.Item
        label={
          <React.Fragment>
            <span>AGE OF ITEM &nbsp;</span>
            <Tooltip
              placement="rightTop"
              title="How old is your item, let's say you bough this item in 2018, so you need to enter 2 years."
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </React.Fragment>
        }
      >
        <Form.Item
          name="years"
          noStyle
          required
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please select year",
            },
          ]}
        >
          <Select placeholder="Age" style={{ width: 100, marginRight: 20 }}>
            {years}
          </Select>
        </Form.Item>

        <Form.Item
          name="months"
          noStyle
          required
          rules={[
            {
              required: true,
              message: "Please select month",
            },
          ]}
        >
          <Select placeholder="Months" style={{ width: 120 }}>
            {months}
          </Select>
        </Form.Item>
      </Form.Item>

      <Form.Item
        name="use_by"
        style={{ padding: 4 }}
        label={
          <React.Fragment>
            <span>USE BY &nbsp;</span>
            <Tooltip
              placement="rightTop"
              title="Who can use your item, for e.g Camera can be used by both."
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </React.Fragment>
        }
        required
        rules={[
          {
            required: true,
            message: "Please select who can use this item.",
          },
        ]}
      >
        <Select placeholder="Select Gender">{gender}</Select>
      </Form.Item>
    </Form>
  );
}
export default SellItemStep2;
