import React from 'react';
import { RightCircleFilled, LeftCircleFilled } from '@ant-design/icons';
export function CarouselArrows (props) {
  const { className, style, onClick } = props;
  return (
    props.type === 'next' ?
      <RightCircleFilled className={className} style={{ ...style, display: "block", color: '#5588ff' }} onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }} />
      :
      <LeftCircleFilled className={className} style={{ ...style, display: "block", color: '#5588ff' }} onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }} />

  );
}