import React, { useState, useRef, useEffect } from "react";
import { Tag, Input, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { colors } from "../../common";
const { union } = require("lodash");

export function EditableTags(props) {
  const [tags, setTags] = useState(props.tags ? props.tags : []);

  const [inputVisible, setInputVisible] = useState(props.inputVisible);
  const [inputTag, setInputTag] = useState("");
  const [disabled] = useState(Boolean(props.disabled));
  const inputElement = useRef(null);

  useEffect(() => {
    setTags((previousTags) => {
      if (props.tags && props.tags.length === 0) {
        return [];
      }
      return union(previousTags, props.tags);
    });
  }, [props.tags]);
  useEffect(() => {
    if (inputVisible) {
      inputElement.current.focus();
    }
  }, [inputVisible]);

  const handleClose = (removedTag) => {
    const tempTags = tags.filter((tag) => tag !== removedTag);
    setTags(tempTags);
    props.onChange(tempTags);
  };

  const handleInputConfirm = () => {
    let temp = tags;
    if (inputTag && tags.indexOf(inputTag) === -1) {
      temp = [...tags, inputTag];
      setTags(temp);
    }
    setInputVisible(false);
    setInputTag("");
    props.onChange(temp);
  };

  return (
    <div style={{ lineHeight: "2.6rem", display:'flex', flexWrap: 'wrap' }}>
      {tags.map((tag, index) => {
        const isLongTag = tag.length > 20;
        const tagElem = (
          <Tag
            key={tag}
            closable={true}
            onClose={() => handleClose(tag)}
            style={{ padding: 5, margin: 2 }}
            color={index > 4 ? colors[index % 4] : colors[index]}
          >
            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      {inputVisible && !disabled && (
        <Input
          ref={inputElement}
          type="text"
          size="medium"
          style={{ width: 78, textAlign: 'center' }}
          value={inputTag}
          onChange={(e) => setInputTag(e.target.value)}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!inputVisible && !disabled && (
        <Tag className="site-tag-plus" onClick={() => setInputVisible(true)}>
          <PlusOutlined /> Add New Tag
        </Tag>
      )}
    </div>
  );
}
