import React from "react";
import { message, Rate } from "antd";
import { Modal } from "antd";
import { Form, Input, Button } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import firebase from "../../../firebase";

import { useState } from "react";
import { addRatings } from "../service";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export function AddRating({ type, id, star, ratingsInfo, onCancelHideModal }) {
  //console.log('AddRating star valeu = ', star, ratingsInfo);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const onFinish = (values) => {
    setConfirmLoading(true);
    let ratingObj = {
      title: values.title,
      description: values.description,
      star: values.star,
      type: type,
    };
    if (type === "consumer") {
      ratingObj.consumer_id = id;
    } else {
      ratingObj.item_id = id;
    }
    addRatings(ratingObj).then((response) => {
      //console.log('addRatings respoe = ', response);
      setConfirmLoading(false);
      if (response && window.getValue(response, "success")) {
        firebase.analytics().logEvent("ratings_sent", {
          rating: ratingObj,
        });
        handleCancel();
        message.success("Ratings added successfully!!!");
      }
    });
  };

  const handleCancel = () => {
    onCancelHideModal();
  };

  return (
    <React.Fragment>
      <Modal
        title={"Rate " + type}
        visible={true}
        footer={null}
        style={{ width: "auto", height: "auto" }}
        maskClosable={false}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <Form {...layout} name="nest-messages" size="large" onFinish={onFinish}>
          <Form.Item
            name={"star"}
            label="Rate"
            rules={[
              { type: "number", required: true, message: "Rating is required" },
            ]}
            initialValue={star}
          >
            <Rate />
          </Form.Item>
          <Form.Item
            name={"title"}
            initialValue={ratingsInfo.title}
            rules={[{ type: "string" }]}
          >
            <Input allowClear placeholder="Name" />
          </Form.Item>
          <Form.Item
            name={"description"}
            initialValue={ratingsInfo.description}
            rules={[{ type: "string" }]}
          >
            <Input.TextArea
              allowClear
              placeholder="Write your description here"
              autoSize={{ minRows: 4 }}
            />
          </Form.Item>
          <Form.Item
            required
            {...{
              wrapperCol: {
                span: 24,
              },
              labelCol: {
                span: 24,
              },
            }}
            hasFeedback
            name="captcha"
            rules={[
              {
                required: true,
                message: "Please verify Captcha",
              },
            ]}
          >
            <ReCAPTCHA sitekey="6LdEV-wUAAAAAMmMrziXprUarEsoSor9YORKfAZW" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: 55 }}
              loading={confirmLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
}
