import React, { Fragment } from "react";
import "./assets/styles/components.less";
import { AlgoliaInstantSearch } from "./pages/landing";
import { history } from "./utility";
import { Router } from "react-router-dom";
import { Offline, Online } from "react-detect-offline";
import { Provider } from "react-redux";
import { store } from "./store";
import { Empty } from "antd";

window.onerror = (msg, url, lineNo, columnNo, error) => {
  // add global error handling;
  return false;
};

function App() {
  return (
    <Fragment>
      <Online>
        <Provider store={store}>
          <Router history={history}>
            <AlgoliaInstantSearch />
          </Router>
        </Provider>
      </Online>
      <Offline>
        <Empty
          className="loader"
          description={<span>No Internet Connection!</span>}
        />
      </Offline>
    </Fragment>
  );
}

export default App;
