import React, { Fragment, useState } from "react";
import { Button, Dropdown, Modal } from "antd";
import { DownOutlined } from "@ant-design/icons";
import Loadable from "react-loadable";
import firebase from "../../../firebase";
import { UserMenuOptions } from "./";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { history } from "../../../utility";
import { useRouteMatch } from "react-router-dom";
import { publishActions } from "../../publish";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import { uiConfig } from "./../../../firebase";
import { globalActions } from "../actions";
import { Loader } from "../../common";
import LocationDropdown from "./LocationDropdown";
import Logo from "../../../assets/images/Rento.png";

const Filter = Loadable({
  loader: () => import("./Filter"),
  loading() {
    return <Loader spinning />;
  },
});

const CustomSearchBox = Loadable({
  loader: () => import("./CustomSearchBox"),
  loading() {
    return <Loader spinning />;
  },
});

function HeaderPlaceHolder() {
  const match = useRouteMatch({
    path: "/",
    strict: true,
    sensitive: true,
  });
  const dispatch = useDispatch();
  const { loggedIn, displayName, showLoginModal } = useSelector(
    (state) => ({
      loggedIn: window.getValue(state, "profile.loggedIn"),
      displayName: window.getValue(state, "profile.displayName"),
      showLoginModal: window.getValue(state, "global.showLoginModal"),
    }),
    isEqual
  );
  const [showModal, setModal] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        justifyContent: "left",
        paddingBottom: "5px",
        background: "white",
        boxShadow: "rgba(0, 0, 0, 0.34) -1px 1px 17px 0px",
        top: 0,
        zIndex: 100,
        position: "sticky",
      }}
    >
      <div
        className="header-place-holder"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "left",
          justifyContent: "left",
          paddingTop: "25px",
          paddingLeft: "55px",
          // background: "white",
          // boxShadow: "0 0 5px rgba(166,166,166,0.9)",
          // top: 0,
          // zIndex: 100,
          position: "sticky",
          borderBottom: match && match.isExact ? "1px solid #00000030" : "",
          paddingBottom: "16px",
        }}
      >
        <div className="logo">
          <a href="/">
            <img src={Logo} alt='RentoCircle' ></img>
          </a>
        </div>
        <div
          style={{
            margin: "0px 10px",
            paddingTop: 20,
          }}
        >
          <LocationDropdown />
        </div>
        <div
          style={{
            flexGrow: 0.75,
            marginRight: "5%",
            width: "16rem",
            paddingTop: 20,
          }}
        >
          <CustomSearchBox />
        </div>
        <div style={{ marginRight: "2%", paddingTop: 20 }}>
          <Button
            style={{
              background: "#000000d9",
              border: "#000000d9",
              color: "white",
            }}
            shape="round"
            size={"large"}
            onClick={() => {
              if (loggedIn) {
                firebase.analytics().logEvent("list_your_item_clicked");
                history.push("/publish");
                dispatch({
                  type: publishActions.CLEAR_FORM_DATA,
                });
              } else {
                setModal(true);
              }
            }}
          >
            List your Item
          </Button>
        </div>
        <div style={{ paddingTop: 20 }}>
          {loggedIn ? (
            <Dropdown
              overlay={<UserMenuOptions />}
              placement="bottomCenter"
              trigger={["hover", "click"]}
              arrow
            >
              <Button
                size="large"
                style={{
                  color: "#03162f",
                  border: "1px solid #47525d",
                }}
              >
                {displayName} <DownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <Button
              style={{ border: "1px solid #47525d" }}
              size={"large"}
              onClick={() => {
                setModal(true);
              }}
            >
              Login / Signup
            </Button>
          )}
        </div>
        <Modal
          title={<strong>Login using Phone number</strong>}
          centered
          visible={showModal || Boolean(showLoginModal)}
          footer={null}
          onCancel={() => {
            setModal(false);
            dispatch({
              type: globalActions.SHOW_LOGIN_MODAL,
              payload: false,
            });
          }}
          destroyOnClose
        >
          <StyledFirebaseAuth
            uiConfig={{
              ...uiConfig,
              signInSuccessUrl: window.location.href,
            }}
            firebaseAuth={firebase.auth()}
          />
        </Modal>
      </div>
      {match && match.isExact ? (
        <div className="filter-item-layout">
          <Filter
            style={{
              display: "block",
            }}
          />
        </div>
      ) : (
        <Fragment />
      )}
    </div>
  );
}

export default HeaderPlaceHolder;
