export const profileActions = {
  // login
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",

  // logout
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",

  // KYC
  KYC_LOADING: "KYC_LOADING",
  KYC_VERIFY_REQUEST: "KYC_VERIFY_REQUEST",
  KYC_VERIFY_SUCCESS: "KYC_VERIFY_SUCCESS",
  KYC_VERIFY_FAILURE: "KYC_VERIFY_FAILURE",

  FETCH_KYC_VERIFY_REQUEST: "FETCH_KYC_VERIFY_REQUEST",
  FETCH_KYC_VERIFY_SUCCESS: "FETCH_KYC_VERIFY_SUCCESS",
  FETCH_KYC_VERIFY_FAILURE: "FETCH_KYC_VERIFY_FAILURE",
  FETCH_KYC_VERIFIED_AADHAR_ID: 'KYC_VERIFIED_AADHAR_ID',

  //my published items
  MY_PUBLISHED_ITEMS_LOADING: "MY_PUBLISHED_ITEMS_LOADING",
  MY_PUBLISHED_ITEMS_SUCCESS: "MY_PUBLISHED_ITEMS_SUCCESS",

  MY_PUBLISHED_ITEMS_LOADING_MORE: "MY_PUBLISHED_ITEMS_LOADING_MORE",

  MY_PUBLISHED_ITEM_UPDATE_DONE: "MY_PUBLISHED_ITEM_UPDATE_DONE",

  MY_PUBLISHED_ITEM_UNPUBLISH_UPDATING: "MY_PUBLISHED_ITEM_UNPUBLISH_UPDATING",

  MY_BOOKMARK_ITEMS_LOADING: "MY_BOOKMARK_ITEMS_LOADING",
  MY_BOOKMARK_ITEMS_SUCCESS: "MY_BOOKMARK_ITEMS_SUCCESS",
  MY_BOOKMARK_ITEMS_LOADING_MORE: "MY_BOOKMARK_ITEMS_LOADING_MORE",
  MY_BOOKMARK_ITEMS_UPDATING_DONE: "MY_BOOKMARK_ITEMS_UPDATING_DONE",

  MY_ADDRESSES_LOADING: "MY_ADDRESSES_LOADING",
  MY_ADDRESSES_SUCCESS: "MY_ADDRESSES_SUCCESS",
  MY_ADDRESSES_LOADING_MORE: "MY_ADDRESSES_LOADING_MORE",
  MY_ADDRESSES_DELETE_DONE: "MY_ADDRESSES_DELETE_DONE",
  MY_ADDRESSES_ADD_OR_EDIT_DONE: "MY_ADDRESSES_ADD_OR_EDIT_DONE",

  //orders given on rent items
  ORDERS_GIVEN_ITEMS_LOADING: "ORDERS_GIVEN_ITEMS_LOADING",
  ORDERS_GIVEN_ITEMS_SUCCESS: "ORDERS_GIVEN_ITEMS_SUCCESS",

  ORDERS_GIVEN_ITEMS_LOADING_MORE: "ORDERS_GIVEN_ITEMS_LOADING_MORE",

  //orders taken on rent items
  ORDERS_TAKEN_ITEMS_LOADING: "ORDERS_TAKEN_ITEMS_LOADING",
  ORDERS_TAKEN_ITEMS_SUCCESS: "ORDERS_TAKEN_ITEMS_SUCCESS",

  ORDERS_TAKEN_ITEMS_LOADING_MORE: "ORDERS_TAKEN_ITEMS_LOADING_MORE",

  // get item ratings
  GET_ITEM_RATING_LOADING: 'GET_ITEM_RATING_LOADING',
  GET_ITEM_RATING_SUCCESS: 'GET_ITEM_RATING_SUCCESS',
  GET_ITEM_RATING_FAILURE: 'GET_ITEM_RATING_FAILURE',

    // get cosumer ratings
    GET_CONSUMER_RATING_LOADING: 'GET_CONSUMER_RATING_LOADING',
    GET_CONSUMER_RATING_SUCCESS: 'GET_CONSUMER_RATING_SUCCESS',

    // update profile from settings page
    UPDATE_PROFILE_IMAGE: 'UPDATE_PROFILE_IMAGE',

    // add item ratings
  ADD_ITEM_RATING_LOADING: 'ADD_ITEM_RATING_LOADING',
  ADD_ITEM_RATING_SUCCESS: 'ADD_ITEM_RATING_SUCCESS',
};
