import { store } from "./../store";
import { notification } from "antd";
import firebase from "../firebase";
import { profileActions } from "../pages/profile";
import Cookies from "js-cookie";

export function logout() {
  Cookies.remove("__r__c__t");
  Cookies.remove("loggedIn");
  Cookies.remove("displayName");
  store.dispatch({
    type: profileActions.LOGOUT_REQUEST
  });
  firebase
    .auth()
    .signOut()
    .then(() => {
      store.dispatch({
        type: profileActions.LOGOUT_SUCCESS
      });
    });

  notification.open({
    message: "Logged Out",
    description: "You are successfully logged out.",
    onClick: () => {
      //console.log("closed.");
    }
  });
}
