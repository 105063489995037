export const Category = [
  {
    type: "Properties on Rent",
    icon: "",
    subCategory: [
      {
        type: "1/2/3/4 BHK Flat",
        icon: "",
      },
      {
        type: "1 RK Flat",
        icon: "",
      },
      {
        type: "Shops",
        icon: "",
      },
      {
        type: "PG/Hostel Homes",
        icon: "",
      },
      {
        type: "Parking Space",
        icon: "",
      },
      {
        type: "Office Space",
        icon: "",
      },
      {
        type: "Other",
        icon: "",
      },
    ],
  },
  {
    type: "Vehicles (cars etc)",
    icon: "",
    subCategory: [
      {
        type: "2 Wheeler",
        icon: "",
      },
      {
        type: "3 Wheeler",
        icon: "",
      },
      {
        type: "4 Wheeler",
        icon: "",
      },
      {
        type: "Tempo",
        icon: "",
      },
      {
        type: "Bus",
        icon: "",
      },
      {
        type: "Cycles",
        icon: "",
      },
      {
        type: "Other",
        icon: "",
      },
    ],
  },
  {
    type: "Agriculture",
    icon: "",
    subCategory: [
      {
        type: "Tractor",
        icon: "",
      },
      {
        type: "Farm Machinery",
        icon: "",
      },
      {
        type: "Other",
        icon: "",
      },
    ]
  },
  {
    type: "Camera",
    icon: "",
    subCategory: [
      {
        type: "DSLR Cameras",
        icon: "",
      },
      {
        type: "DSLR Lenses",
        icon: "",
      },
      {
        type: "Camera Accessories",
        icon: "",
      },
      {
        type: "Other",
        icon: "",
      },
    ],
  },
  {
    type: "Electronics",
    icon: "",
    subCategory: [
      {
        type: "Smartphones",
        icon: "",
      },
      {
        type: "Camera",
        icon: "",
      },
      {
        type: "Laptops",
        icon: "",
      },
      {
        type: "Desktops",
        icon: "",
      },
      {
        type: "Tablets",
        icon: "",
      },
      {
        type: "Printers",
        icon: "",
      },
      {
        type: "Projectors and screens",
        icon: "",
      },
      {
        type: "Other",
        icon: "",
      },
    ],
  },
  {
    type: "Furniture",
    icon: "",
    subCategory: [
      {
        type: "Beds",
        icon: "",
      },
      {
        type: "Sofas",
        icon: "",
      },
      {
        type: "Dining tables & Sets",
        icon: "",
      },
      {
        type: "Mattresses",
        icon: "",
      },
      {
        type: "Tables",
        icon: "",
      },
      {
        type: "Chairs",
        icon: "",
      },
      {
        type: "Bean Bags",
        icon: "",
      },
      {
        type: "Shoe racks",
        icon: "",
      },
      {
        type: "TV Units & Cabinets",
        icon: "",
      },
      {
        type: "Wardrobe",
        icon: "",
      },
      {
        type: "Drawers",
        icon: "",
      },
      {
        type: "Work / Study Tables",
        icon: "",
      },
      {
        type: "Cushion",
        icon: "",
      },
      {
        type: "Other",
        icon: "",
      },
    ],
  },
  {
    type: "Home Appliance",
    icon: "",
    subCategory: [
      {
        type: "AC / Coolers",
        icon: "",
      },
      {
        type: "Television sets",
        icon: "",
      },
      {
        type: "Fridge",
        icon: "",
      },
      {
        type: "Washing Machine",
        icon: "",
      },
      {
        type: "Microwave Ovens / Grills",
        icon: "",
      },
      {
        type: "Home Theater Systems",
        icon: "",
      },
      {
        type: "Speakers",
        icon: "",
      },
      {
        type: "Vacuum Cleaners",
        icon: "",
      },
      {
        type: "Juicer / Mixer / Grinder",
        icon: "",
      },

      {
        type: "Other",
        icon: "",
      },
    ],
  },
  {
    type: "Travel",
    icon: "",
    subCategory: [
      {
        type: "Camping tents",
        icon: "",
      },
      {
        type: "Sleeping bags",
        icon: "",
      },
      {
        type: "Bags",
        icon: "",
      },
      {
        type: "Torch",
        icon: "",
      },
      {
        type: "Head-Lamp",
        icon: "",
      },
      {
        type: "Binoculars",
        icon: "",
      },
      {
        type: "Others",
        icon: "",
      },
    ],
  },
  {
    type: "Sports",
    icon: "",
    subCategory: [
      {
        type: "Cricket",
        icon: "",
      },
      {
        type: "Badminton",
        icon: "",
      },
      {
        type: "Football",
        icon: "",
      },
      {
        type: "Swimming",
        icon: "",
      },
      {
        type: "Volleyball",
        icon: "",
      },
      {
        type: "Tennis",
        icon: "",
      },
      {
        type: "Other",
        icon: "",
      },
    ],
  },
  {
    type: "Gym and Fitness",
    icon: "",
    subCategory: [
      {
        type: "Personal Trainer",
        icon: "",
      },
      {
        type: "Dumbbells",
        icon: "",
      },
      {
        type: "Cardio",
        icon: "",
      },
      {
        type: "Massagers",
        icon: "",
      },
      {
        type: "Medicine balls",
        icon: "",
      },
      {
        type: "Other",
        icon: "",
      },
    ],
  },
  {
    type: "Books",
    icon: "",
    subCategory: [
      {
        type: "Fiction",
        icon: "",
      },
      {
        type: "Business",
        icon: "",
      },
      {
        type: "Poetry",
        icon: "",
      },
      {
        type: "Children books",
        icon: "",
      },
      {
        type: "Textbooks",
        icon: "",
      },
      {
        type: "Biographies",
        icon: "",
      },
      {
        type: "Autobiographies",
        icon: "",
      },
      {
        type: "Self Help",
        icon: "",
      },
      {
        type: "Other",
        icon: "",
      },
    ],
  },
  {
    type: "Clothing",
    icon: "",
    subCategory: [
      {
        type: "Men",
        icon: "",
      },
      {
        type: "Women",
        icon: "",
      },
      {
        type: "Kids",
        icon: "",
      },
      {
        type: "Other",
        icon: "",
      },
    ],
  },
  {
    type: "Games",
    icon: "",
    subCategory: [
      {
        type: "Gaming Remotes",
        icon: "",
      },
      {
        type: "Gaming Consoles",
        icon: "",
      },
      {
        type: "Gaming CDs",
        icon: "",
      },
      {
        type: "Other",
        icon: "",
      },
    ],
  },
  {
    type: "Musical Instruments",
    icon: "",
    subCategory: [
      {
        type: "Tabla",
        icon: "",
      },
      {
        type: "Guitar",
        icon: "",
      },
      {
        type: "Violin",
        icon: "",
      },
      {
        type: "Drum sets",
        icon: "",
      },
      {
        type: "Other",
        icon: "",
      },
    ],
  },
  {
    type: "Party items",
    icon: "",
    subCategory: [
      {
        type: "Karaoke",
        icon: "",
      },
      {
        type: "systemsDJ",
        icon: "",
      },
      {
        type: "Equipment",
        icon: "",
      },
      {
        type: "Other",
        icon: "",
      },
    ],
  },

  {
    type: "Baby and Kids",
    icon: "",
    subCategory: [
      {
        type: "Strollers / Prams",
        icon: "",
      },
      {
        type: "Cradles",
        icon: "",
      },
      {
        type: "Toys",
        icon: "",
      },
      {
        type: "Other",
        icon: "",
      },
    ],
  },
  {
    type: "Others",
    icon: "",
    subCategory: [
      {
        type: "Unknown",
        icon: "",
      },
    ],
  },
];
