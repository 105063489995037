import { profileActions } from "../actions";

export const myAddresses = (
  state = { addresses: [], anyMore: false },
  action
) => {
  switch (action.type) {
    case profileActions.MY_ADDRESSES_LOADING:
      return {
        ...state,
        addresses: [],
        loading: action.payload
      };
    case profileActions.MY_ADDRESSES_LOADING_MORE:
      return {
        ...state,
        loadingMore: action.payload
      };
    case profileActions.MY_ADDRESSES_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        anyMore: Boolean(action.payload.length >= 8),
        addresses: state.addresses.concat(action.payload)
      };
    case profileActions.MY_ADDRESSES_ADD_OR_EDIT_DONE:
      const currentAddresses = Array.from(state.addresses);
      if (!action.payload.edit) {
        currentAddresses.unshift(action.payload.address);
      } else {
        for (let i = 0; i < currentAddresses.length; i++) {
          if (currentAddresses[i].id === action.payload.id) {
            currentAddresses[i] = action.payload.address;
            break;
          }
        }
      }
      return {
        ...state,
        addresses: currentAddresses
      };
    case profileActions.MY_ADDRESSES_DELETE_DONE:
      const addresses = Array.from(state.addresses);

      let index = -1;
      for (let i = 0; i < addresses.length; i++) {
        if (addresses[i].id === action.payload) {
          index = i;
          break;
        }
      }
      addresses.splice(index, 1);
      //console.log("====addresssss", addresses);
      return {
        ...state,
        addresses: addresses
      };
    default:
      return state;
  }
};
