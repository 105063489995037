import React, { useState, useCallback, Fragment } from "react";
import {
  Row,
  Col,
  Descriptions,
  Button,
  Modal,
  InputNumber,
  Form,
  Skeleton,
  Carousel,
  Grid,
  Tooltip,
  PageHeader,
  Rate,
} from "antd";
import ReactImageMagnify from "react-image-magnify";
import PhoneInput from "react-phone-input-2";
import ReCAPTCHA from "react-google-recaptcha";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { SafetyOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import Loadable from "react-loadable";
import { useEffect } from "react";
import firebase from "../../../firebase";
import { history } from "../../../utility";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchSingleItem, CarouselArrows, globalActions } from "../../landing";
import { bookItem, fetchPostedComments } from "../service";
import { Loader, postItemConstants } from "../../common";
import { Report } from "../../common/report/Report";
import { CustomMap } from "./";
import { Bookmark } from "../../landing/components";
import defaultImg from "../../../assets/images/no-img-avail.png";

const { useBreakpoint } = Grid;

const AddCommentComponent = Loadable({
  loader: () => import("./AddAndDisplayComment"),
  render(loaded, props) {
    let AddAndDisplayComment = loaded.AddAndDisplayComment;
    return <AddAndDisplayComment {...props} />;
  },
  loading() {
    return <Loader spinning />;
  },
});

let formRef = null;
function BeforePlacingOrderForm({ mobileNumber, itemType }) {
  //console.log('BeforePlacingOrderForm ', mobileNumber)
  const [form] = Form.useForm();
  formRef = form;
  const formLayout = {
    wrapperCol: {
      span: 14,
    },
  };
  return (
    <Form {...formLayout} layout={"vertical"} form={form}>
      {itemType === postItemConstants.RENT ? (
        <Form.Item
          label="For How many days you need this Product?"
          required
          rules={[
            {
              required: true,
              message: "Please enter number of days",
            },
          ]}
          initialValue={1}
          name="renting_duration"
        >
          <InputNumber min={1} max={365} type="number" />
        </Form.Item>
      ) : (
        <Fragment />
      )}
      <Form.Item
        name="mobile"
        label="Phone Number"
        initialValue={mobileNumber || ""}
        rules={[
          {
            required: true,
            message: "Mobile is required",
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              //console.log("phone", value);
              if (!(value && value !== "")) {
                return Promise.resolve();
              }
              value = value.replace(/[^0-9]/g, "");
              if (
                value &&
                value.match(/^(\+?91|0)?[6789]\d{9}$/) &&
                value.match(/^(\+?91|0)?[6789]\d{9}$/)[1]
              ) {
                return Promise.resolve();
              }
              return Promise.reject("Please Enter valid Phone number");
            },
          }),
        ]}
      >
        <PhoneInput
          disableDropdown
          // value={mobileNumber}
          inputClass="ant-input ant-input-lg"
          country={"in"}
        />
      </Form.Item>
      <Form.Item
        required
        hasFeedback
        name="captcha"
        rules={[
          {
            required: true,
            message: "Please verify Captcha",
          },
        ]}
      >
        <ReCAPTCHA sitekey="6LdEV-wUAAAAAMmMrziXprUarEsoSor9YORKfAZW" />
      </Form.Item>
    </Form>
  );
}

function Labels(props) {
  return <strong style={{ fontSize: "16px" }}>{props.children}</strong>;
}

export function PublishedItem() {
  const screens = useBreakpoint();
  const [showModal, toggleModal] = useState(false);
  const [showReportModal, toggleReportModal] = useState(false);
  const { itemId } = useParams(); // fetch itemId from the url.
  const dispatch = useDispatch();
  const {
    item,
    loading,
    uuid,
    bookItemRequest,
    error,
    comments,
    mobileNumber,
  } = useSelector((state) => ({
    item:
      (window.getValue(state, "recentpublisheditems.items") &&
        window
          .getValue(state, "recentpublisheditems.items")
          .find((item) => item.id === itemId)) ||
      {},
    loading: Boolean(
      window.getValue(state, "recentpublisheditems.loadingSingleItem")
    ),
    uuid:
      window.getValue(state, "profile") &&
      window.getValue(state, "profile.user") &&
      window.getValue(state, "profile.user.uid"),
    mobileNumber:
      window.getValue(state, "profile") &&
      window.getValue(state, "profile.user") &&
      window.getValue(state, "profile.user.phoneNumber"),
    bookItemRequest: window.getValue(state, "book.sendRequest"),
    error: window.getValue(state, "book.error"),
    comments: window.getValue(state, "getItemComments.comments"),
  }));

  if (!item.id && !loading) {
    // if unable to find item details in the store fetch it from backend.
    fetchSingleItem(itemId);
  }
  console.log("book item details = ", item);
  useEffect(() => {
    fetchPostedComments({
      itemId,
      loadMore: false,
    });
  }, [itemId]);

  const hideReportModal = useCallback(() => {
    toggleReportModal(false);
  }, []);

  return (
    <div
      className="published-item"
      style={
        screens.xs
          ? {
              padding: 20,
              border: "2px solid #e9e9e9",
              width: "100%",
              margin: "25px 0 0 0",
            }
          : {
              padding: 20,
              border: "2px solid #e9e9e9",
            }
      }
    >
      {/* <Loader spinning={Boolean(bookItemRequest)} tip="Sending Item Request..."> */}
      <React.Fragment>
        <Skeleton loading={loading} active paragraph={{ rows: 15, width: 50 }}>
          <Modal
            centered
            title="Booking Details"
            style={{ top: 20 }}
            visible={showModal}
            onCancel={() => toggleModal(false)}
            footer={[
              <Button
                key="submit"
                type="primary"
                loading={Boolean(bookItemRequest)}
                onClick={() => {
                  formRef.validateFields().then((form) => {
                    bookItem({
                      item_id: item.id,
                      renting_duration: form.renting_duration,
                      mobile: form.mobile,
                    })
                      .then((order) => {
                        if (!error && order && window.getValue(order, "id")) {
                          history.push(`/order/${order.id}`);
                        }
                      })
                      .finally(() => {
                        toggleModal(false);
                      });
                  });
                }}
              >
                Send Request
              </Button>,
            ]}
          >
            <BeforePlacingOrderForm
              mobileNumber={mobileNumber}
              itemType={item.type}
            />
          </Modal>

          <PageHeader
            title={
              <span
                style={{
                  fontSize: "27px",
                }}
              >
                Product Details
              </span>
            }
            subTitle={
              <Tooltip
                placement="bottom"
                title={`Aadhaar ${
                  window.getValue(item, "isPublisherVerified") ? "" : "un-"
                }verified user.`}
                // color={"#33691e"}
              >
                <SafetyOutlined
                  style={{
                    color: window.getValue(item, "isPublisherVerified")
                      ? "#408e19"
                      : "#d73232",
                    fontSize: "250%",
                  }}
                />
              </Tooltip>
            }
            backIcon={<Button shape="circle" icon={<ArrowLeftOutlined />} />}
            onBack={() => window.history.back()}
            extra={[
              <React.Fragment key="extra">
                <Tooltip
                  placement="bottom"
                  key={"red"}
                  title={"Report this item"}
                >
                  <Button
                    size="small"
                    type="text"
                    disabled={item.status === "inactive"}
                    style={{
                      border: "#DB1F22",
                    }}
                    icon={
                      <ExclamationCircleFilled
                        style={{
                          color:
                            item.status === "inactive" ? "#e0b6b6" : "#DB1F22",
                        }}
                      />
                    }
                    shape="circle"
                    onClick={() => {
                      //console.log("onclicked report clicked ");
                      firebase.analytics().logEvent("report_now_item_clicked");
                      if (uuid) {
                        toggleReportModal(true);
                      } else {
                        dispatch({
                          type: globalActions.SHOW_LOGIN_MODAL,
                          payload: true,
                        });
                      }
                    }}
                  >
                    Report issue
                  </Button>
                </Tooltip>
              </React.Fragment>,
              <Button
                type="primary"
                size="large"
                disabled={item.status === "inactive"}
                key={"dd"}
                style={
                  screens.xs
                    ? {
                        width: "78%",
                        background:
                          item.status === "inactive" ? "#e0b6b6" : "#fb641b",
                        border: "#fb641b",
                      }
                    : {
                        width: 200,
                        background:
                          item.status === "inactive" ? "#e0b6b6" : "#fb641b",
                        border: "#fb641b",
                        // margin: screens.xs ? "0% 20%" : null,
                      }
                }
                onClick={() => {
                  firebase.analytics().logEvent("book_now_item_clicked");
                  if (uuid) {
                    toggleModal(true);
                  } else {
                    dispatch({
                      type: globalActions.SHOW_LOGIN_MODAL,
                      payload: true,
                    });
                  }
                }}
              >
                Book Now
              </Button>,
            ]}
            footer={
              <React.Fragment>
                <h4>Add comments</h4>
                <Row>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    lg={{ span: 10, offset: 0 }}
                    md={{ span: 6, offset: 0 }}
                  >
                    {item && window.getValue(item, "id") && comments ? (
                      <AddCommentComponent
                        itemId={window.getValue(item, "id")}
                        comments={comments}
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </React.Fragment>
            }
          >
            <Row>
              <Col xs={{ span: 24 }} lg={{ span: 12 }} md={{ span: 12 }}>
                <Carousel
                  arrows={true}
                  swipeToSlide={true}
                  dots={false}
                  nextArrow={<CarouselArrows type="next" />}
                  prevArrow={<CarouselArrows type="prev" />}
                >
                  {window.getValue(item, "images") &&
                    item.images.map((image, index) => (
                      <div key={"carousel-" + index} className="carousel-div">
                        <ReactImageMagnify
                          {...{
                            smallImage: {
                              alt: "carousel-" + index,
                              src: image,
                              // dataSrc: image.small,
                              // dataSizes: "auto",
                              // className: "lazyload",
                              width: screens.md ? 400 : 250,
                              height: screens.md ? 400 : 250,
                              onError: (ev) => (ev.target.src = defaultImg),
                              // style: {
                              //   objectFit: "contain",
                              // },
                            },
                            largeImage: {
                              src: image,
                              onError: (ev) => (ev.target.src = defaultImg),
                              width: 1200,
                              height: 1200,
                            },
                            lensStyle: { backgroundColor: "rgba(0,0,0,.6)" },
                          }}
                          enlargedImagePortalId="portal"
                          enlargedImageContainerDimensions={{
                            width: "200%",
                            height: "100%",
                          }}
                          imageStyle={{
                            objectFit: "scale-down",
                          }}
                          enlargedImageStyle={{
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    ))}
                </Carousel>
                <div
                  id="portal"
                  style={{
                    position: "fixed",
                    top: "200px",
                    right: "30px",
                    zIndex: 10,
                  }}
                />
              </Col>
              <Col
                xs={{ span: 24 }}
                lg={{ span: 11, push: 1 }}
                md={{ span: 12 }}
              >
                <Descriptions
                  layout="horizontal"
                  bordered
                  column={1}
                  size="middle"
                  className="description-table-border"
                >
                  <Descriptions.Item label={<Labels>Name</Labels>}>
                    <strong>{window.getValue(item, "name")}</strong>
                  </Descriptions.Item>

                  <Descriptions.Item
                    label={<Labels>Description</Labels>}
                    style={{ lineBreak: "auto" }}
                  >
                    <strong>{window.getValue(item, "description")}</strong>
                  </Descriptions.Item>

                  <Descriptions.Item label={<Labels>Bookmark</Labels>}>
                    <Bookmark item={item} />
                  </Descriptions.Item>
                  {item.type === postItemConstants.RENT ? (
                    <Fragment>
                      <Descriptions.Item label={<Labels>Ratings</Labels>}>
                        <strong>
                          <Rate
                            allowHalf
                            defaultValue={window.getValue(item, "rating")}
                            disabled
                          />{" "}
                          ({window.getValue(item, "rating_count")})
                        </strong>
                      </Descriptions.Item>

                      <Descriptions.Item label={<Labels>Market Price</Labels>}>
                        <strong>
                          Rs. {window.getDisplayPrice(item, "market_price")}
                        </strong>
                      </Descriptions.Item>
                      <Descriptions.Item label={<Labels>Deposit</Labels>}>
                        <strong>
                          Rs. {window.getDisplayPrice(item, "deposit")}
                        </strong>
                      </Descriptions.Item>
                      <Descriptions.Item label={<Labels>One Day Rent</Labels>}>
                        <strong>
                          Rs.{" "}
                          {window.getValue(item, "rent") &&
                            window.getDisplayPrice(item, "rent.day")}
                        </strong>
                      </Descriptions.Item>
                      <Descriptions.Item label={<Labels>One Week Rent</Labels>}>
                        <strong>
                          Rs.{" "}
                          {window.getValue(item, "rent") &&
                            window.getDisplayPrice(item, "rent.week")}
                        </strong>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={<Labels>One Month Rent</Labels>}
                      >
                        <strong>
                          Rs.{" "}
                          {window.getValue(item, "rent") &&
                            window.getDisplayPrice(item, "rent.month")}
                        </strong>
                      </Descriptions.Item>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Descriptions.Item label={<Labels>Selling Price</Labels>}>
                        <strong>
                          Rs. {window.getDisplayPrice(item, "selling_price")}
                        </strong>
                      </Descriptions.Item>
                    </Fragment>
                  )}
                  <Descriptions.Item label={<Labels>Pick Up Point</Labels>}>
                    <CustomMap
                      screens={screens}
                      lat={parseFloat(window.getValue(item, "pickAddress.lat"))}
                      lng={parseFloat(
                        window.getValue(item, "pickAddress.long")
                      )}
                    />
                  </Descriptions.Item>
                  {item.type === postItemConstants.RENT ? (
                    <Descriptions.Item label={<Labels>Drop Point</Labels>}>
                      <CustomMap
                        screens={screens}
                        lat={parseFloat(
                          window.getValue(item, "dropAddress.lat")
                        )}
                        lng={parseFloat(
                          window.getValue(item, "dropAddress.long")
                        )}
                      />
                    </Descriptions.Item>
                  ) : (
                    <Fragment />
                  )}

                  <Descriptions.Item label={<Labels>Posted Date</Labels>}>
                    <strong>
                      {new Date(
                        window.getValue(item, "publish_timestamp")
                      ).toDateString()}
                    </strong>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
          </PageHeader>
        </Skeleton>
      </React.Fragment>
      {showReportModal ? <Report hideReportModal={hideReportModal} /> : ""}
    </div>
  );
}
