import React, { useState } from "react";
import { Category } from "./";
import { Form, Input, Select, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import Loadable from "react-loadable";
import { Loader } from "../../common";
const { union } = require("lodash");

const EditableTags = Loadable({
  loader: () => import("./EditableTags"),
  render(loaded, props) {
    let EditableTags = loaded.EditableTags;
    return <EditableTags {...props} />;
  },
  loading() {
    return <Loader spinning />;
  },
});

const UploadFiles = Loadable({
  loader: () => import("./UploadFiles"),
  loading() {
    return <Loader spinning />;
  },
});

const formItemLayout = {
  labelCol: {
    xs: {
      span: 6,
      push: 3,
    },
    md: {
      push: 0,
      offset: 2,
      span: 8,
    },
    lg: {
      offset: 1,
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 6,
    },
    md: {
      span: 9,
    },
    lg: {
      span: 9,
    },
  },
};

function PublishItemStep1(props) {
  const [subCategory, setSubCategory] = useState([]);
  const formData = useSelector((state) =>
    window.getValue(state, "publish.STEP_1_FORM_DATA")
  );
  const [form] = Form.useForm();
  if (props.setFormReference) {
    props.setFormReference(form);
  }
  let categories = [];
  for (let i = 0; i < Category.length; i++) {
    categories.push(
      <Select.Option key={i + ""} value={Category[i].type}>
        {Category[i].type}
      </Select.Option>
    );
  }

  return (
    <Form
      initialValues={formData}
      form={form}
      colon={false}
      size="large"
      style={{ padding: 4 }}
      name="step1"
      scrollToFirstError
      {...formItemLayout}
      onFieldsChange={(changedFields) => {
        if (
          window.getValue(changedFields[0], "name[0]") === "fileList" &&
          window.getValue(changedFields[0], "value[0].response.data.labels")
        ) {
          const length = window.getValue(changedFields[0], "value.length");

          let labels = form.getFieldValue("tags") || [];

          for (let i = 0; i < length; i++) {
            const tempLabels = window.getValue(
              changedFields[0],
              `value[${i}].response.data.labels`
            );

            if (tempLabels) {
              labels = union(labels, tempLabels);
            }
          }
          if (labels.length > 0) {
            form.setFields([{ name: "tags", value: labels }]);
          }
        }
        if (
          window.getValue(changedFields[0], "name[0]") === "fileList" &&
          window.getValue(changedFields[0], "value.length") === 0
        ) {
          form.setFields([{ name: "tags", value: [] }]);
        }
      }}
    >
      <Form.Item
        name="product_name"
        required
        hasFeedback
        label={
          <React.Fragment>
            <span>NAME OF PRODUCT&nbsp;</span>
            <Tooltip
              placement="rightTop"
              title="Enter name of your item like camera, bicycle or car etc..."
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </React.Fragment>
        }
        rules={[
          {
            required: true,
            message: "Please enter name of the Item.",
          },
          {
            min: 2,
            message: "Please enter name of minimum 3 characters",
          },
        ]}
      >
        <Input
          allowClear
          className="published"
          textAlign="center"
          placeholder="Camera, DSLR, T-shirt"
        />
      </Form.Item>

      <Form.Item
        name="category"
        required
        hasFeedback
        label={
          <React.Fragment>
            <span>CATEGORY&nbsp;</span>
            <Tooltip
              placement="rightTop"
              title="Choosing right category will help renter to search your item."
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </React.Fragment>
        }
        rules={[{ required: true, message: "Please Select Category." }]}
      >
        <Select
          showSearch
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          placeholder="Please select Category"
          onChange={(value, option) => {
            const temp = [];

            form.resetFields(["sub-category"]);
            const subCategoryTemp = Category[option.key].subCategory;
            for (let i = 0; i < subCategoryTemp.length; i++) {
              temp.push(
                <Select.Option
                  key={i + "sub" + subCategoryTemp[i].type}
                  value={subCategoryTemp[i].type}
                >
                  {subCategoryTemp[i].type}
                </Select.Option>
              );
            }
            setSubCategory(temp);
          }}
        >
          {categories}
        </Select>
      </Form.Item>

      <Form.Item
        name="sub-category"
        required
        hasFeedback
        label={
          <React.Fragment>
            <span>SUB-CATEGORY&nbsp;</span>
            <Tooltip
              placement="rightTop"
              title="Choosing right sub-category will help renter to search your item."
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </React.Fragment>
        }
        rules={[{ required: true, message: "Please Select SubCategory." }]}
      >
        <Select
          showSearch
          placeholder="Please select SubCategory"
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
        >
          {subCategory}
        </Select>
      </Form.Item>

      <Form.Item
        name="description"
        label={
          <React.Fragment>
            <span>DESCRIPTION&nbsp;</span>
            <Tooltip
              placement="rightTop"
              title="Enter as much description as possible may be write your term and condition for your item."
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </React.Fragment>
        }
        required
        hasFeedback
        rules={[
          { required: true, message: "Please enter description." },
          {
            min: 20,
            message: "Please enter description of minimum 20 characters",
          },
        ]}
      >
        <Input.TextArea allowClear autoSize={{ maxRows: 4, minRows: 4 }} />
      </Form.Item>

      <Form.Item
        valuePropName="fileList"
        name="fileList"
        required
        label={
          <React.Fragment>
            <span>UPLOAD ITEM PICTURES&nbsp;</span>
            <Tooltip
              placement="rightTop"
              title="Upload really good quality images."
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </React.Fragment>
        }
        rules={[
          { required: true, message: "Please upload files." },
          () => ({
            validator(rule, fileList) {
              //console.log(fileList);
              if (fileList && fileList.length >= 1) {
                for (let i = 0; i < fileList.length; i++) {
                  if (fileList[i].status !== "done") {
                    return Promise.reject("Please wait while uploading files.");
                  }
                }
                return Promise.resolve();
              }
              return Promise.reject(
                "Please upload minimum one file and maximum 5 files"
              );
            },
          }),
        ]}
      >
        <UploadFiles />
      </Form.Item>

      <Form.Item
        required
        valuePropName="tags"
        label={
          <React.Fragment>
            <span>ENTER TAGS&nbsp;</span>
            <Tooltip
              placement="rightTop"
              title="Tags can help search your product faster and better."
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </React.Fragment>
        }
        name="tags"
        rules={[{ required: true, message: "Please enter tags." }]}
      >
        <EditableTags inputVisible={false} />
      </Form.Item>
    </Form>
  );
}
export default PublishItemStep1;
