import React from "react";
import { Pagination } from "antd";
import { connectPagination } from "react-instantsearch-dom";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";
const CustomPagination = ({ currentRefinement, nbPages, refine }) => {
  const hitsPerPage = useSelector(
    (state) =>
      parseInt(
        window.getValue(state, "recentpublisheditems.hitsPerPage") || "8"
      ),
    isEqual
  );
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Pagination
        current={currentRefinement}
        total={nbPages * hitsPerPage}
        pageSize={hitsPerPage}
        onChange={(page) => {
          refine(page);
          window.scrollTo(0, 0);
        }}
        showSizeChanger={false}
      />
    </div>
  );
};
const ConnectedPagination = connectPagination(CustomPagination);
export default ConnectedPagination;
