import React, { Fragment } from "react";
import { Table, Button, Popover } from "antd";
import { Link } from "react-router-dom";
import { Rate } from "antd";
import moment from "moment";
import { givenOnRentOrdersItem } from "../service";
import { Loader, postItemConstants } from "../../common";
import { useSelector, shallowEqual } from "react-redux";
import { useEffect } from "react";

const columnsForRentOut = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "Ordered Date",
    dataIndex: "ordered",
    key: "ordered",
    align: "center",
  },
  {
    title: "Order By",
    dataIndex: "orderBy",
    key: "orderBy",
    align: "center",
  },

  // {
  //   title: "Order Status",
  //   dataIndex: "orderStatus",
  //   key: "orderStatus",
  //   align: "center",
  // },
  {
    title: "Order Type",
    dataIndex: "orderType",
    key: "orderType",
    align: "center",
  },

  {
    title: "Average Rating",
    // dataIndex: "rating",
    key: "rating",
    align: "center",
    render: (row) =>
     row.orderType === postItemConstants.SELL ? <Fragment>--</Fragment> :
      row.rating.star ? (
        <Popover
          content={() => <div>{row.rating.description}</div>}
          title={row.rating.title}
        >
          <div>
            <Rate allowHalf defaultValue={row.rating.star} disabled />
          </div>
        </Popover>
      ) : (
        <Rate allowHalf defaultValue={row.rating.star} disabled />
      ),
  },

  {
    title: "View Product Details",
    dataIndex: "detailId",
    key: "open",
    align: "center",
    render: (detailId) => <Link to={`/item/${detailId}`}>Click to view</Link>,
  },
];

export function MyPublishedOrders({ type }) {
  const {
    loadingRentOut,
    dataForRentOut,
    loadingMoreRentOut,
    anyMoreRentOut,
  } = useSelector(
    (state) => ({
      loadingRentOut: window.getValue(state, "givenOnRentOrdersItem.loading"),
      dataForRentOut:
        window.getValue(state, "givenOnRentOrdersItem.items") || [],
      loadingMoreRentOut: window.getValue(
        state,
        "givenOnRentOrdersItem.loadingMore"
      ),
      anyMoreRentOut: window.getValue(state, "givenOnRentOrdersItem.anyMore"),
    }),
    shallowEqual
  );

  useEffect(() => {
    givenOnRentOrdersItem(type);
  }, [type]);

  let finalItemRentOut = [];
  if (dataForRentOut) {
    for (let i = 0; i < dataForRentOut.length; i++) {
      const item = dataForRentOut[i];
      finalItemRentOut.push({
        key: window.getValue(item, "id") + i,
        id: window.getValue(item, "id"),
        name: window.getValue(item, "item.name"),
        ordered: moment(window.getValue(item, "booking_timestamp")).format(
          "DD-MM-YYYY"
        ),
        // orderStatus: window.getValue(item, "status"),
        orderType: window.getValue(item, "item.type"),
        orderBy: window.getValue(item, "consumer.name"),
        rating: window.getValue(item, "item.ratings"),
        detailId: window.getValue(item, "item.id"),
      });
    }
  }
  return (
    <div>
      <Table
        title={() => <div>{type === postItemConstants.RENT ? 'Items you have given for Rent' :
        type === postItemConstants.SELL ? 'Items you have put on for Sell'  : 'All your published Rent & Sell orders'}</div>}
        size="middle"
        bordered
        columns={columnsForRentOut}
        dataSource={finalItemRentOut}
        pagination={false}
        loading={{
          spinning: loadingRentOut,
          indicator: <Loader spinning={loadingRentOut} tip="" />,
        }}
        footer={() =>
          anyMoreRentOut ? (
            loadingMoreRentOut ? (
              <Loader
                spinning={loadingMoreRentOut}
                tip=""
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              />
            ) : (
              <Button
                type="primary"
                onClick={() => {
                  givenOnRentOrdersItem(type,
                    finalItemRentOut[finalItemRentOut.length - 1].id
                  );
                }}
                size="middle"
              >
                Load More
              </Button>
            )
          ) : (
            ""
          )
        }
      />
    </div>
  );
}
