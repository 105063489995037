export const publishActions = {
  // login
  STEP_1_FORM_DATA: "STEP_1_FORM_DATA",
  STEP_2_FORM_DATA: "STEP_2_FORM_DATA",
  STEP_3_FORM_DATA: "STEP_3_FORM_DATA",
  CLEAR_FORM_DATA: "CLEAR_FORM_DATA",
  CLEAR_CAPTCHA: "CLEAR_CAPTCHA"
};

export const bookActions = {
  BOOK_ITEM_REQUEST: "BOOK_ITEM_REQUEST",
  BOOK_ITEM_SUCCESS: "BOOK_ITEM_SUCCESS",
  BOOK_ITEM_ERROR: "BOOK_ITEM_ERROR"
};