import React from "react";
import { Result, Button } from "antd";
import { history } from "../../../utility";
import { useParams } from "react-router-dom";
export function OrderPlaced() {
  const { orderId } = useParams();
  return (
    <div className="order-placed-page">
      <Result
        status="success"
        title="Order Placed Successfully."
        subTitle={`Order number: ${orderId}. You will receive SMS contains owner details in couple of seconds.`}
        extra={[
          <Button
            type="primary"
            key="home"
            onClick={() => {
              history.push("/");
            }}
          >
            Go To Home Page
          </Button>
        ]}
      />
      ,
    </div>
  );
}
