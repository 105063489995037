import React from "react";
import { Comment, Avatar, Form, Button, List, Input } from "antd";
import moment from "moment";
import { useState } from "react";

import { postComment, fetchPostedComments } from "../service";
import { useSelector } from "react-redux";
import { Loader } from "../../common";

const { TextArea } = Input;

export function CommentList({ comments }) {
  const { loadMore, loadingMoreItems, updatedComments } = useSelector(
    (state) => ({
      loadMore: window.getValue(state, "getItemComments.loadMore"),
      loadingMoreItems: window.getValue(
        state,
        "getItemComments.loadingMoreItems"
      ),
      updatedComments: window.getValue(state, "getItemComments.comments"),
    })
  );

  comments =
    updatedComments && updatedComments.length > comments.length
      ? updatedComments
      : comments;

  return (
    <List
      dataSource={comments}
      header={`${comments.length} ${comments.length > 1 ? "replies" : "reply"}`}
      itemLayout="horizontal"
      // pagination={{
      //   pageSize: 3
      // }}
      loadMore={
        loadMore && !loadingMoreItems ? (
          <Button
            type="primary"
            style={{ marginLeft: "50%", marginTop: "3%" }}
            onClick={() => {
              fetchPostedComments({
                loadMore: true,
                itemId: comments[comments.length - 1].itemId,
                lastCommentId: comments[comments.length - 1].id,
              });
            }}
            size="large"
          >
            Load More
          </Button>
        ) : loadMore && loadingMoreItems ? (
          <Loader
            spinning={loadingMoreItems}
            tip=""
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 40,
            }}
          />
        ) : (
          ""
        )
      }
      renderItem={(item) => (
        <li key={window.getValue(item, "id")}>
          <Comment
            author={window.getValue(item, "displayName")}
            avatar={require("../../../assets/images/user_icon.png")}
            content={window.getValue(item, "comment")}
            datetime={
              item.isNewComment
                ? window.getValue(item, "created_timestamp")
                : moment(window.getValue(item, "created_timestamp")).format(
                    "MMM DD, YYYY h:mm:ss a"
                  )
            }
          />
        </li>
      )}
    />
  );
}

const Editor = ({ onChange, onSubmit, submitting, value, loggedIn }) => (
  <div>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button
        htmlType="submit"
        loading={submitting}
        onClick={onSubmit}
        disabled={!(value && loggedIn)}
        type="primary"
      >
        Add Comment
      </Button>
    </Form.Item>
  </div>
);

export function AddAndDisplayComment({ itemId }) {
  const { updatedComments, loggedIn } = useSelector((state) => ({
    updatedComments: window.getValue(state, "getItemComments.comments"),
    loggedIn: window.getValue(state, "profile.loggedIn") || false,
  }));
  const [value, setValue] = useState("");
  const [submitLoading, setSubmitLoading] = useState();

  const handleSubmit = () => {
    if (!value) {
      return;
    }
    setSubmitLoading(true);
    postComment({
      item_id: itemId,
      comment: value,
    }).then(() => {
      setSubmitLoading(false);
      setValue("");
    });
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div>
      <Comment
        avatar={
          <Avatar
            src={require("../../../assets/images/user_icon.png")}
            alt="Han Solo"
          />
        }
        content={
          <Editor
            onChange={handleChange}
            onSubmit={handleSubmit}
            submitting={submitLoading}
            loggedIn={loggedIn}
            value={value}
          />
        }
      />
      {updatedComments && updatedComments.length > 0 && (
        <CommentList comments={updatedComments} />
      )}
    </div>
  );
}