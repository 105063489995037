import React, { useState } from "react";
import { DeleteFilled, LoadingOutlined } from "@ant-design/icons";
import { Popconfirm, message } from "antd";
import { myPublishedItems } from "../service";

const style = { fontSize: "18px", padding: 20  };

export function DeletePublishedItem({ row }) {
  const [loader, setLoader] = useState(false);
  return loader ? (
    <LoadingOutlined style={style} />
  ) : (
    <Popconfirm
      title="Are you sure delete this item?"
      onConfirm={(e) => {
        e.stopPropagation();
        setLoader(true);
        fetch(`${window["apiURL"]}/item/${row.id}`, {
          method: "DELETE",
        })
          .then((response) => {
            if (response && window.getValue(response, "success")) {
              message.success("Deleted item from your list");
              myPublishedItems();
            } else {
              message.error("Error removing Item");
              setLoader(false);
            }
          })
          .catch((e) => {
            setLoader(false);
            message.error("Error removing Item" + e.toString());
          });
      }}
      onCancel={(e) => {
        e.stopPropagation();
      }}
      okText="Yes"
      cancelText="No"
    >
      <DeleteFilled
        style={style}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </Popconfirm>
  );
}
