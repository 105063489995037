export const commentActions = {
    // for add comments
    ADD_COMMENT_REQUEST: "ADD_COMMENT_REQUEST",
    ADD_COMMENT_SUCCESS: "ADD_COMMENT_SUCCESS",
    ADD_COMMENT_ERROR: "ADD_COMMENT_ERROR",
    ADD_SINGLE_COMMENT_SUCCESS: 'ADD_SINGLE_COMMENT_SUCCESS',

    // for get comments
    LOADING: 'COMMENTS_LOADING',
    LOAD_MORE_COMMENTS_LOADING: 'LOAD_MORE_COMMENTS_LOADING',
    LOAD_MORE_ITEMS: 'LOAD_MORE_ITEMS',
    COMMENT_SUCCESS: 'COMMENT_SUCCESS',

  };
  