import React from "react";
import { Table, Skeleton, Button, Grid, Col, Row } from "antd";
import { useSelector, shallowEqual } from "react-redux";
import Loadable from "react-loadable";
import { myBookmarkItems } from "../";
import { Loader, postItemConstants } from "../../common";
import { history } from "../../../utility";
import defaultImg from "../../../assets/images/no-img-avail.png";
import { useEffect } from "react";

const { useBreakpoint } = Grid;
const DeleteBookmarkItem = Loadable({
  loader: () => import("./DeleteBookmarkItem"),
  render(loaded, props) {
    let DeleteBookmarkItem = loaded.DeleteBookmarkItem;
    return <DeleteBookmarkItem {...props} />;
  },
  loading() {
    return <Loader spinning />;
  },
});

export function BookmarkItems() {
  const screens = useBreakpoint();
  const { loading, items, loadingMore, anyMore } = useSelector(
    (state) => ({
      loading: window.getValue(state, "mybookmarkitems.loading"),
      items: window.getValue(state, "mybookmarkitems.items") || [],
      loadingMore: window.getValue(state, "mybookmarkitems.loadingMore"),
      anyMore: window.getValue(state, "mybookmarkitems.anyMore"),
    }),
    shallowEqual
  );
  useEffect(() => {
    myBookmarkItems();
  }, []);
  const columns = React.useMemo(
    () => [
      {
        title: "",
        key: "item",
        width: "10%",
        align: "center",
        onCell: () => {
          return {
            style: {
              whiteSpace: "nowrap",
              maxWidth: screens.xs ? "100%" : 250,
            },
          };
        },
        render: (row) => {
          return (
            <React.Fragment>
              <img
                alt={row.id}
                src={window.getValue(row, "item.image")}
                width={200}
                height={200}
                className="lazyload"
                style={{
                  border: "1px solid #99aab5",
                  borderRadius: 25,
                  padding: 20,
                  margin: 20,
                  objectFit: "contain",
                }}
                onError={(ev) => {
                  ev.target.src = defaultImg;
                  ev.target.className = "img-rpi-error";
                }}
              />
              <h3 className="bookmark-list-item-name">
                {window.getValue(row, "item.name")}
              </h3>
            </React.Fragment>
          );
        },
      },
      {
        title: "",
        key: "description",
        align: "center",
        width: screens.xs ? "100%" : "50%",
        onCell: () => {
          return {
            style: {
              maxWidth: screens.xs ? "100%" : 300,
            },
          };
        },
        render: (row) => {
          return (
            <h3
              style={
                screens.xs
                  ? { overflow: "hidden", height: 90, width: 300 }
                  : { overflow: "hidden", height: 80 }
              }
            >
              {window.getValue(row, "item.description")}
            </h3>
          );
        },
      },
      {
        title: "",
        key: "rent",
        width: "20%",
        align: "center",
        onCell: () => {
          return {
            style: {
              whiteSpace: "nowrap",
              maxWidth: screens.xs ? "100%" : 70,
            },
          };
        },
        render: (row) => {
          return window.getValue(row, "item.type") === postItemConstants.SELL ? (
            <Col>
              <Row align="center" justify="center">
                <b>Selling Price</b>
              </Row>
              <Row align="center" justify="center">
                <b>Rs.{window.getDisplayPrice(row, "item.selling_price")}/-</b>
              </Row>
            </Col>
          ) : (
            <Col>
              <Row align="center" justify="center">
                <b>Rent</b>
              </Row>
              <Row align="center" justify="center">
                <b>Day: Rs.{window.getDisplayPrice(row, "item.rent.day")}/-</b>
              </Row>
              <Row justify="center" align="center">
                <b>Week: Rs.{window.getDisplayPrice(row, "item.rent.week")}/-</b>
              </Row>
              <Row justify="center" align="center">
                <b>Month: Rs.{window.getDisplayPrice(row, "item.rent.month")}/-</b>
              </Row>
            </Col>
          );
        },
      },
      {
        title: "",
        width: "15%",
        onCell: () => {
          return {
            style: {
              whiteSpace: "nowrap",
              maxWidth: screens.xs ? "100%" : 100,
            },
          };
        },
        align: "center",
        render: (row) => {
          return <DeleteBookmarkItem row={row} isFromBookmark={true} />;
        },
      },
    ],
    [screens.xs]
  );
  return (
    <Skeleton loading={loading} active paragraph={{ rows: 15, width: 50 }}>
      <Table
        title={() => <div>Bookmarked Items</div>}
        className="my-bookmarks-items"
        size="middle"
        bordered
        columns={columns}
        dataSource={items}
        showHeader={false}
        pagination={false}
        rowKey={"id"}
        onRow={(record) => ({
          onClick: (e) => {
            history.push(`/item/${record.item.id}`);
          },
        })}
        footer={() =>
          anyMore ? (
            loadingMore ? (
              <Loader
                spinning={loadingMore}
                tip=""
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              />
            ) : (
              <Button
                type="primary"
                onClick={() => {
                  myBookmarkItems(items[items.length - 1].id);
                }}
                size="middle"
              >
                Load More
              </Button>
            )
          ) : (
            ""
          )
        }
      />
    </Skeleton>
  );
}
