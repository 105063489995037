import React from "react";
import { Collapse, Row, Col, Divider } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Fragment } from "react";

const { Panel } = Collapse;

export const remoteFAQData = [
  {
    title: "What is RentoCircle?",
    content: (
      <p>
        <b>RentoCircle</b> is an easy platform where people can rent or sell stuff. It brings the people who want to buy/borrow stuff on a simple and easy to use platform. <b>RentoCircle</b> simply connects
        people to get benefit from each other. Just give it a try!
      </p>
    ),
  },
  {
    title: "What kinds of things can I post on RentoCircle?",
    content: (
      <p>
        Pretty much anything, so long as it’s not illegal, inappropriate,
        perishable, unsanitary, or offensive to others. So don’t go posting
        anything that’s breathing (please don’t rent/sell yourself or your loved
        ones), or weapons of any kind. As a general rule of thumb: if it’s a
        physical and sanitary item, and you’re not hurting or offending anyone
        in the community by posting it, then you’re okay to post it. We’ll be
        deleting posts that don’t follow these rules. If you want answers for
        specific items, <Link to="/contact">contact us</Link> with the details
        and we’d be happy to help out.
      </p>
    ),
  },
  {
    title: "How do I put an item for rent onto RentoCircle?",
    content: (
      <p>
        Simply go to website, click on 'List your item' button, then click on 'Rent' button and you are set
        to list item on rent.
      </p>
    ),
  },
  {
    title:
      "How can I sell my item onto RentoCircle?",
    content: (
      <p>
        Simply go to website, click on 'List your item' in top right hand side, then click on 'Sell' button and fill the necessary details, images, pricing etc with describing about your item so that buyer will be interested in buying your item.
        While deciding price for item for sell, compare the existing item price with your item and decide wisely. After posting item for sell, if someone books your item, you will get message on your registered
        mobile number with the details about the buyer and you are good to sell the item to the buyer. That's how simple it is!
      </p>
    ),
  },
  {
    title: "How much time it takes to publish my item for sell/rent on RentoCircle?",
    content: (
      <p>
        It's pretty much quick as soon as you finish the posting item and it's successful, within few 5 mins your items is available on our platform. If there is any issue in posting you can contact us with the issue you are facing <Link to="/contact">here</Link> with the details
        and we’d be happy to help out.
      </p>
    ),
  },
  {
    title: "Can I edit or delete my posted item from RentoCircle?",
    content: (
      <p>
        Yes, you can edit or delete item from our platform. Simply click on the profile icon at top right hand side of the website and select 'My Items' option from menu. You will see the list of items that you have posted so far, find the item you wish to edit or delete and take action as per your need. If there is any issue in editing/deleting you can contact us <Link to="/contact">here</Link> with the details
        and we’d be happy to help out.
      </p>
    ),
  },
  {
    title: "I don't think that I have anything someone would want to rent?",
    content: (
      <p>
        That is impossible! You never know what people might need. Have a browse
        through the app and see what kind of stuff people are posting, and
        perhaps inspiration will strike.
      </p>
    ),
  },
  {
    title: "How do you decide on item pricing?",
    content: (
      <p>
        That’s up to you! We here at <b>RentoCircle</b> don’t choose the prices,
        only you do. Look through the platform and see the price of similar
        items, or, if nothing is available, try doing 5-15% of the item’s
        purchased price per day in case of rent or usability of items while putting for sell, depending on factors such as age, condition,
        and even sentimental value. Then after some time, see the response and
        alter the price based on demand.
      </p>
    ),
  },
  {
    title:
      "I’ve found a borrower that wants to rent my item. How do I go about giving it to them?",
    content: (
      <p>
        On the platform, as soon as your item is booked, you will get the mobile number of borrower who booked your item, then you and the borrower must arrange how the handover. However, we are currently working on a service that will help out
        with the delivery and return of items. We plan to integrate it into
        &nbsp;<b>RentoCircle</b> in the future.
      </p>
    ),
  },
  {
    title:
      "I’ve found a buyer that wants to buy my item. How do I go about giving it to them?",
    content: (
      <p>
        On the platform, as soon as your item is booked, you will get the mobile number of buyer who booked your item, then you and the buyer must arrange how the handover will be
        done. However, we are currently working on a service that will help out
        with the delivery of items. We plan to integrate it into
        &nbsp;<b>RentoCircle</b> in the future.
      </p>
    ),
  },
  {
    title: "My posting was deleted. What’s the deal?",
    content: (
      <p>
        If your posting was deleted, then that means it probably was an
        offensive, illegal, inappropriate, perishable, or unsanitary item. If
        you think we were mistaken, <Link to="/contact">contact us</Link> and
        we’ll sort it out!
      </p>
    ),
  },
  {
    title: "Strangers scare me. Is all this safe?",
    content: (
      <p>
        We try to make the <b>RentoCircle</b> community as safe as possible, but
        ultimately, <b>RentoCircle</b> is as safe as you want to make it. We
        have a KYC verification system to make sure the people on the platform
        are who they say they are, with ratings and reviews that show how they
        interact with other users. We never share any of your personal
        information. Basically, you’re in charge of everything! If you’re still
        scared, then you should talk to the person before buying or borrowing or lending
        an item from/to them. They could have the same fears about you! Trust your
        gut when deciding to met people. Be prepared when meeting face to face.
      </p>
    ),
  },
  {
    title: "How can I trust the lender/seller before borrowing/buying item from them?",
    content: (
      <p>
        We have KYC verification system to make sure the people on the platform
        are who they say they are, so when choosing item to borrow or buy, look for green verified symbol on item, it will convey that the
        lender or seller is KYC verified and you can trust him. But always trust your guts while meeting the lender/seller in person. 
      </p>
    ),
  },
  {
    title: "What do you hope to get out of RentoCircle?",
    content: (
      <p>
        We hope <b>RentoCircle</b> will bring communities closer together while
        helping out the environment by disrupting consumer culture. The less we
        buy, the less is produced, and the less waste gets thrown into the
        world. The more we share, the more people we interact with, the more our
        community grows, the happier we all can be!
      </p>
    ),
  },
  {
    title: "When does my rental period begin and end?",
    content: (
      <p>
        Your rental period begins when publisher/lender accepts your booking and
        lend you the item on that day and ends on the last day you booked item
        for, so if you want to maximize your time with the item, make sure to
        pick up the item earlier in the day. If for whatever reason you’re
        falling behind, just message the Lender and let them know.
      </p>
    ),
  },
  {
    title:
      "What if I buy defective item and realized it later? What should I do to avoid it?",
    content: (
      <p>
        First of all, when you go to meet seller, make sure you check everything that is there to know about the item before actually making any transaction
        Compare the photos that were posted on the website and confront the seller if those are not matched. Trust your guts before taking final
        decisions of buying item. If you find anything that is not be trusted about the seller or quality of item then contact us so that
        we will make sure to avoid such a sellers on our platform from posting. Send your details/concerns <Link to="/contact">here</Link>.
      </p>
    ),
  },
  {
    title:
      "What should I do if the item I’m borrowing is damaged or does not work?",
    content: (
      <p>
        We always recommend trying out the item before accepting it, but we
        understand that’s not always possible. If this happens to you, follow
        these two steps: 1) Message/call the person, letting them know the item
        was not functional or in the promised condition and arrange a time to
        return the item. 2) <Link to="/contact">Contact us</Link> with your
        concern and we will sort it out. If there is any dispute between you and
        the publisher/lender, we want to help resolve it.
      </p>
    ),
  },
  {
    title:
      "What happens if the publisher/lender tries to give me an item that’s different from their original listing?",
    content: (
      <p>
        You have two options here. If you don’t care, go for it, but if it’s not
        the item you want, then don’t accept it. In both cases,{" "}
        <Link to="/contact">contact us</Link> with the details and we’ll contact
        the publisher/lender to sort out why they’re renting out the wrong item.
      </p>
    ),
  },
  {
    title:
      "I loved an item that I borrowed and wish to buy it. Is this possible?",
    content: (
      <p>
        The publisher/lender may have the item up for sale as well as for rent,
        so simply contact the owner and ask your question
      </p>
    ),
  },
  {
    title: "How do I contact RentoCircle?",
    content: (
      <p>
        Simply go to the bottom of website and click on 'Contact' or simply click here <Link to="/contact">Contact us</Link>
        Share your query/doubts with us. We are ready to help!
      </p>
    ),
  },
];

export function FAQ() {
  return (
    <div
      className="faq-container"
      style={{
        padding: 20,
        border: "2px solid #e9e9e9",
      }}
    >
      <React.Fragment>
        <Row gutter={[16, 16]}>
          <Col>
            <h2
              style={{
                padding: "0px 20px",
              }}
            >
              <b>FAQ</b>
            </h2>
          </Col>
        </Row>
        <Collapse
          bordered={false}
          defaultActiveKey={"0"}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          className="site-collapse-custom-collapse"
        >
          {remoteFAQData.map((item, i) => {
            return (
              <Panel
                header={item.title}
                key={i}
                bordered={false}
                className="site-collapse-custom-panel"
              >
                {item.content}
                {i === remoteFAQData.length - 1 ? (
                  <Divider style={{ margin: "0px 10px" }} />
                ) : (
                  <Fragment />
                )}
              </Panel>
            );
          })}
        </Collapse>
      </React.Fragment>
    </div>
  );
}
