import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { fetch } from "./utility";
import { getValue, getDisplayPrice } from './utility/helper';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

window.fetch = fetch;
window.getValue = getValue;
window.getDisplayPrice = getDisplayPrice;
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
