import React, { Fragment } from "react";
import { Grid, Button, message } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import Loadable from "react-loadable";
import { Loader } from "../../common";
import HitsPerPageSelector from "./HitsPerPageSelector";
import { useDispatch } from "react-redux";
import { recentlyPublishedItemsActions } from "../actions";

const CustomStateResults = Loadable({
  loader: () => import("./CustomStateResults"),
  loading() {
    return <Loader spinning />;
  },
});

const CustomRefinementList = Loadable({
  loader: () => import("./CustomRefinementList"),
  loading() {
    return <Loader spinning />;
  },
});

const CustomClearRefinements = Loadable({
  loader: () => import("./CustomClearRefinements"),
  loading() {
    return <Loader spinning />;
  },
});
const { useBreakpoint } = Grid;
export default function Filter() {
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  return (
    /*<Collapse >
       <Panel header={<CustomStateResults />} key="1"> 
       */
    <div
      style={
        !screens.xs
          ? {
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }
          : {
              display: "flex",
              flexDirection: "column",
            }
      }
    >
      <CustomStateResults />
      <CustomRefinementList
        attribute="type"
        searchable={true}
        width={200}
        isMobile={screens.xs}
        placeholder={" SELL / RENT"}
      />
      <CustomRefinementList
        attribute="category.name"
        searchable={true}
        width={200}
        isMobile={screens.xs}
        placeholder={"CATEGORY"}
      />
      <CustomRefinementList
        attribute="service_to"
        searchable={true}
        width={200}
        isMobile={screens.xs}
        placeholder={"MEN / WOMEN "}
      />
      <HitsPerPageSelector isMobile={screens.xs} />
      <CustomClearRefinements isMobile={screens.xs} />
      {!screens.xs ? (
        <Button
          size="middle"
          // style={screens.xs ? { marginTop: 10, marginRight: 10 } : {}}
          icon={<ReloadOutlined style={{ fontSize: 18 }} />}
          onClick={() => {
            message.info("Refreshing");
            dispatch({
              type: recentlyPublishedItemsActions.SET_REFRESH,
            });
          }}
        >
          Refresh
        </Button>
      ) : (
        <Fragment></Fragment>
      )}
    </div>
    /* </Panel> 
    </Collapse>*/
  );
}
