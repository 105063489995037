import React, { useCallback } from "react";
import { Affix, Button } from "antd";
import { CloseCircleTwoTone } from "@ant-design/icons";
// import { ReactComponent as UV } from "./../../assets/images/4.svg";
import { ReactComponent as RC1 } from "./../../assets/images/rc1.svg";
import { ReactComponent as RC2 } from "./../../assets/images/rc2.svg";
import { ReactComponent as RC3 } from "./../../assets/images/rc3.svg";
import { ReactComponent as RC4 } from "./../../assets/images/rc4.svg";
const style = { fontSize: "180%" };
const borderStyle = { border: "5px solid #687a8f" };
export function HowItWorks(props) {
  const hideMe = useCallback(() => {
    window.scrollTo(0, 0);
    props.hide(false);
  }, [props]);
  return (
    <React.Fragment>
      <div
        style={{
          zIndex: 10000000,
          top: 0,
          left: 0,
          position: "absolute",
          width: "100vw",
          height: "100vh",
        }}
      >
        <Affix style={{ position: "fixed", top: "5%", right: "5%" }}>
          <CloseCircleTwoTone
            twoToneColor="#d73232"
            style={style}
            onClick={() => hideMe()}
          />
        </Affix>
        <div style={borderStyle}>
          <RC1 />
        </div>
        <div style={borderStyle}>
          <RC2 />
        </div>
        <div style={borderStyle}>
          <RC3 />
        </div>
        <div style={borderStyle}>
          <RC4 />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: "#d73232",
                border: "#d73232",
                color: "white",
                margin: "20px",
              }}
              shape="round"
              size={"large"}
              onClick={() => hideMe()}
            >
              Let's Start
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
