import React from "react";
import PhoneInput from "react-phone-input-2";

import Loadable from "react-loadable";
import { Form, Divider, Tooltip, Alert } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { Loader } from "../../common";

const LocationSearchInput = Loadable({
  loader: () => import("../../publish/components/LocationSearch"),
  render(loaded, props) {
    let LocationSearchInput = loaded.LocationSearchInput;
    return <LocationSearchInput {...props} />;
  },
  loading() {
    return <Loader spinning />;
  },
});

const formItemLayout = {
  labelCol: {
    xs: {
      span: 6,
      push: 3,
    },
    md: {
      push: 0,
      offset: 2,
      span: 8,
    },
    lg: {
      offset: 1,
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 4,
    },
    md: {
      span: 12,
    },
    lg: {
      span: 10,
      push: 1,
    },
  },
};

function SellItemStep3(props) {
  const [form] = Form.useForm();
  const { formData, mobileNumber } = useSelector((state) => ({
    formData: window.getValue(state, "publish.STEP_3_FORM_DATA"),
    mobileNumber:
      window.getValue(state, "profile") &&
      window.getValue(state, "profile.user") &&
      window.getValue(state, "profile.user.phoneNumber"),
  }));

  if (props.setFormReference) {
    props.setFormReference(form);
  }

  return (
    <Form
      form={form}
      colon={false}
      size="large"
      name="step3"
      style={{ padding: 4 }}
      initialValues={{ ...formData }}
      scrollToFirstError
      {...formItemLayout}
    >
      <Alert
        style={{ width: "fit-content", margin: "auto", marginBottom: 10 }}
        message="Note: Mobile number will be shared, once item is booked."
        type="info"
      />
      <Form.Item
        label={
          <React.Fragment>
            <span>PHONE NUMBER &nbsp;</span>
            <Tooltip
              placement="rightTop"
              title="Person who is interested in your product will reach out to you on this number. It will be shared once he will book the item."
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </React.Fragment>
        }
        required
        hasFeedback
        initialValue={formData.mobile_no || mobileNumber}
        name="mobile_no"
        rules={[
          {
            required: true,
            message: "Please enter your phone number.",
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              //console.log('phone', value);
              value = value.replace(/[^0-9]/g, "");
              if (
                value &&
                value.match(/^(\+?91|0)?[6789]\d{9}$/) &&
                value.match(/^(\+?91|0)?[6789]\d{9}$/)[1]
              ) {
                return Promise.resolve();
              }

              return Promise.reject("Please Enter valid Phone number");
            },
          }),
        ]}
      >
        <PhoneInput
          disableDropdown
          inputClass="ant-input ant-input-lg"
          country={"in"}
          value={formData.mobile_no}
        />
      </Form.Item>

      <Form.Item
        label={
          <React.Fragment>
            <span>LOCATION &nbsp;</span>
            <Tooltip
              placement="rightTop"
              title="From where the item can be picked it up."
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </React.Fragment>
        }
        required
        hasFeedback
        valuePropName="address_step3"
        name="pick_up_address"
        rules={[
          {
            required: true,
            message: "Please enter location to pick up item.",
          },
        ]}
      >
        <LocationSearchInput placeHolder="Search location..." />
      </Form.Item>

      <Divider />

      <Form.Item
        required
        label={
          <React.Fragment>
            <span>YOU ARE NOT A ROBOT &nbsp;</span>
            <Tooltip
              placement="rightTop"
              title="Captcha will help us better serve rental community"
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </React.Fragment>
        }
        hasFeedback
        name="captcha"
        rules={[
          {
            required: true,
            message: "Please verify Captcha",
          },
        ]}
      >
        <ReCAPTCHA sitekey="6LdEV-wUAAAAAMmMrziXprUarEsoSor9YORKfAZW" />
      </Form.Item>
    </Form>
  );
}
export default SellItemStep3;
