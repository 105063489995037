import React from "react";
import { Button } from "antd";

import { connectCurrentRefinements } from "react-instantsearch-dom";
import { useDispatch, useSelector } from "react-redux";
import { recentlyPublishedItemsActions } from "../actions";
const ClearRefinements = ({ items, refine, isMobile }) => {
  const dispatch = useDispatch();
  const hitsPerPage = useSelector((state) => window.getValue(state, "recentpublisheditems.hitsPerPage") || '8');
  return (
    <Button
      type="primary"
      onClick={() => {
        dispatch({
          type: recentlyPublishedItemsActions.SET_ITEMS_PER_PAGE,
          payload: '8' // set default value for hits per page
        })
        refine(items);
      }
      }
      disabled={!items.length && hitsPerPage === '8'}
      style={ isMobile ? { marginTop: 10, marginRight: 10 } : {}}
    >
      Reset Filters
    </Button>
  );
};
const CustomClearRefinements = connectCurrentRefinements(ClearRefinements);
export default CustomClearRefinements;
