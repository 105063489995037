import React from "react";
import { Row, Col, message, Tooltip } from "antd";
import PhoneInput from "react-phone-input-2";
import ReCAPTCHA from "react-google-recaptcha";
import { Form, Input, Button } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import firebase from "../../../firebase";

import { submitContactInfo } from "./service";
import { history } from "../../../utility";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { globalActions } from "../../landing";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 6,
    },
    md: {
      push: 0,
      offset: 2,
      span: 8,
    },
    lg: {
      offset: 1,
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 6,
    },
    md: {
      span: 9,
    },
    lg: {
      span: 9,
    },
  },
};

export function ContactUs() {
  const dispatch = useDispatch();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const onFinish = (values) => {
    setConfirmLoading(true);
    submitContactInfo({ ...values.contact }).then((response) => {
      if (window.getValue(response, "success")) {
        firebase.analytics().logEvent("contact_sent", {
          report: values.contact,
        });
        setConfirmLoading(false);
        message.success("Thank you. We'll get in touch with you soon!");
        history.push("/");
      } else {
        dispatch({
          type: globalActions.SET_ERROR,
          payload: "Failed to submit. Please try again!",
        });
      }
    });
  };

  return (
    <div
      className="contact-us"
      style={{
        padding: 20,
        border: "2px solid #e9e9e9",
      }}
    >
      <React.Fragment>
        <Row gutter={[16, 16]}>
          <Col style={{ margin: 20 }}>
            <h2>
              <b>Contact Us</b>
            </h2>
          </Col>
        </Row>
        <Form
          style={{ padding: 4 }}
          name="step1"
          scrollToFirstError
          {...formItemLayout}
          onFinish={onFinish}
          size="large"
        >
          <Form.Item
            name={["contact", "name"]}
            label="Name"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["contact", "email"]}
            label="Email"
            rules={[
              { type: "email", required: true, message: "Email is required" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["contact", "mobileNumber"]}
            label="Mobile"
            rules={[
              {
                required: false,
                message: "Mobile is required",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  // console.log("phone", value);
                  if (!(value && value !== "")) {
                    return Promise.resolve();
                  }
                  value = value.replace(/[^0-9]/g, "");
                  if (
                    value &&
                    value.match(/^(\+?91|0)?[6789]\d{9}$/) &&
                    value.match(/^(\+?91|0)?[6789]\d{9}$/)[1]
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Please Enter valid Phone number");
                },
              }),
            ]}
          >
            <PhoneInput
              disableDropdown
              inputClass="ant-input ant-input-lg"
              country={"in"}
            />
          </Form.Item>
          <Form.Item name={["contact", "introduction"]} label="Introduction">
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            required
            label={
              <React.Fragment>
                <span>YOU ARE NOT A ROBOT &nbsp;</span>
                <Tooltip
                  placement="rightTop"
                  title="Captcha will help us better serve rental community"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </React.Fragment>
            }
            hasFeedback
            name="captcha"
            rules={[
              {
                required: true,
                message: "Please verify Captcha",
              },
            ]}
          >
            <ReCAPTCHA sitekey="6LdEV-wUAAAAAMmMrziXprUarEsoSor9YORKfAZW" />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 16, offset: 7 }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: 25 }}
              loading={confirmLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </React.Fragment>
    </div>
  );
}
