import React from "react";
import { geolocated } from "react-geolocated";
import { AimOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../common";
import { Button, message, Space, Tooltip, Grid } from "antd";
import { useEffect } from "react";
import { recentlyPublishedItemsActions } from "../actions";
const { useBreakpoint } = Grid;

function LocationPickerNew(props) {
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const cityName = useSelector((state) =>
    window.getValue(state, "recentpublisheditems.latlng.city")
  );
  useEffect(() => {
    if (props.setPickerLocation) {
      if (props.isGeolocationAvailable && props.coords) {
        props.setPickerLocation({
          lat: props.coords.latitude,
          lng: props.coords.longitude,
          city: "Current location",
        });
      } else {
        props.setPickerLocation({
          lat: 1,
          lng: 1,
          city: "Location not set",
        });
      }
    }
  }, [props.coords, props.isGeolocationAvailable]);
  return !props.isGeolocationAvailable ? (
    <Space direction="horizontal">
      <AimOutlined style={{ fontSize: 25 }} />
      <div>Browser Not Supported</div>
    </Space>
  ) : !props.isGeolocationEnabled ? (
    <Space direction="horizontal">
      <AimOutlined style={{ fontSize: 25 }} />
      <div>
        Your current Location not Enabled.
        <br />
        Please check browser settings
      </div>
    </Space>
  ) : props.coords ? (
    <Space direction="horizontal">
      <Tooltip title="Your location is enabled" color={"blue"}>
        <AimOutlined style={{ fontSize: 25 }} />
      </Tooltip>
      {screens.xs ? (
        <Button
          size="large"
          disabled={
            !props.isGeolocationAvailable || !props.isGeolocationEnabled
          }
          onClick={() => {
            if (cityName === "Current Location") {
              dispatch({
                type: recentlyPublishedItemsActions.SET_SEARCH_LAT_LNG,
                payload: {
                  lat: 1,
                  lng: 1,
                  city: "Set Location",
                },
              });
              localStorage.setItem(
                "search-city",
                JSON.stringify({
                  lat: 1,
                  lng: 1,
                  city: "Set Location",
                })
              );
              message.info("Your location settings has been reset!");
            } else {
              dispatch({
                type: recentlyPublishedItemsActions.SET_SEARCH_LAT_LNG,
                payload: {
                  lat: props.coords.latitude,
                  lng: props.coords.longitude,
                  city: "Current Location",
                },
              });
              localStorage.setItem(
                "search-city",
                JSON.stringify({
                  lat: props.coords.latitude,
                  lng: props.coords.longitude,
                  city: "Current Location",
                })
              );
              message.info("Your current location has been set!");
            }
          }}
        >
          {cityName === "Current Location"
            ? "Remove Set Location"
            : "Set Current Location"}
        </Button>
      ) : cityName === "Current location" ? (
        "Remove your location"
      ) : (
        "Set current location"
      )}
    </Space>
  ) : (
    <Loader tip="Getting location..." size="medium" />
  );
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(LocationPickerNew);
