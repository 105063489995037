import React from "react";
import { Space, Card, Grid } from "antd";
import { Meta } from "antd/lib/list/Item";
const { useBreakpoint } = Grid;
export function ChooseTypeToPostItem(props) {
  const screens = useBreakpoint();
  return (
    <div style={{ padding: 20 }}>
      <Space direction={screens.xs ? "vertical" : "horizontal"} size="small">
        <Card
          hoverable
          onClick={() => {
            props.setSelected("Rent");
          }}
          style={{ width: 240 }}
        >
          <Meta title="RENT" />
        </Card>
        <Card
          hoverable
          style={{ width: 240 }}
          onClick={() => {
            props.setSelected("Sell");
          }}
        >
          <Meta title="SELL" />
        </Card>
      </Space>
    </div>
  );
}
