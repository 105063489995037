import Cookies from "js-cookie";
import { profileActions } from "./../actions";

//console.log("LOGGED", Cookies.get("loggedIn"));
const initialState = {
  loggedIn: Cookies.get("loggedIn"),
  user: "",
  displayName: Cookies.get("displayName") || ""
};

export const profile = (state = initialState, action) => {
  switch (action.type) {
    case profileActions.LOGIN_REQUEST:
      return {
        ...state,
        loginRequest: true
      };
    case profileActions.LOGIN_SUCCESS:
      return {
        ...state,
        loginRequest: false,
        loggedIn: true,
        user: action.payload,
        displayName: action.displayName
      };
    case profileActions.UPDATE_PROFILE_IMAGE:
    return {
      ...state,
      user: {
        ...state.user,
        photoURL: action.payload,
      }
    }
    case profileActions.LOGOUT_REQUEST:
      return {
        ...state,
        logoutRequest: true
      };
    case profileActions.LOGOUT_SUCCESS:
      return {
        ...state,
        logoutRequest: false,
        loggedIn: false,
        user: null
      };
    default:
      return state;
  }
};
