export const stringConstant = {
  APP_NAME: "RentoCircle",
  MOBILE_NAV_OPTIONS: {
    LIST_YOUR_ITEM: "List your item",
    LOGIN_SIGNUP: "Login / Signup",
    MY_PUBLISHED_ITEM: "My Published Item",
    BOOKMARKED_ITEMS: "Bookmarked Items",
    VERIFY_KYC: "Verify KYC",
    ORDERS: "Orders",
    MANAGE_ADDRESS: "Manage Address",
    // SETTINGS: "Settings",
    LOGOUT: "Logout",
  },
};

export const postItemConstants = {
  RENT: 'Rent',
  SELL: 'Sell',
  ALL: 'All',
}

// export const colors = ["#f50", "#2db7f5", "#87d068", "#108ee9", "#f50"];
// export const colors = [

//   "#08457e",
//   "#a52606",
//   "#111e6c",
//   "#0038ff",
//   "#44437f",
// ];
export const colors = [
  "#48d80d",
  "#0205ab",
  "#391282",
  "#003121",
  "#151110",
  "#2a2a2a",
  "#0c0a37",
];
